import { Outlet } from 'react-router-dom'
import { FC } from 'react'
import UnloggedLayout from 'layout/UnloggedLayout'

const ErrorLayout: FC = () => {
    return (
        <div
            className={'h-full w-full'}
            style={{
                height: '100%',
                background:
                    'radial-gradient(116.48% 209.06% at 80.47% 81.35%,#dff7ff 0,hsla(0,0%,100%,0) 100%),#fff',
            }}>
            <UnloggedLayout />
            <main
                className={'h-full w-full flex items-center justify-center'}
                style={{
                    height: '80%',
                }}>
                <Outlet />
            </main>
        </div>
    )
}

export default ErrorLayout
