import { FC } from 'react'
import { TButtonProps } from './types'
import { Loader } from '../../index'

type TButtonWithLoaderProps = TButtonProps & {
    loading: boolean
    loadingText?: string
}

export const ButtonWithLoader: FC<TButtonWithLoaderProps> = ({
    children,
    loading,
    onClick,
    loadingText = 'Makáme na tom...',
    className,
    disabled,
}) => {
    return (
        <button
            type='button'
            onClick={onClick}
            disabled={disabled}
            className={`inline-flex
                items-center justify-center w-full rounded-md border border-transparent
                 px-6 py-3 text-sm font-light text-white hover:shadow-md hover:shadow-borderShadow/10 duration-300 ${
                     className ?? 'bg-lightPurple hover:bg-darkPurple'
                 } ${disabled && 'opacity-60'}`}>
            {loading && <Loader />}
            {loading ? loadingText : children}
        </button>
    )
}
