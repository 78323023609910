import React, { FC } from 'react'

const QuestionFour: FC = () => {
    return (
        <div className='space-y-4'>
            <p>
                <b>Služba VistaCredit.cz je určena pouze pro drobné podnikatele (OSVČ)</b>, mající{' '}
                <b>platné</b>
                živnostenské oprávnění. <br />
                Pokud není Vaše oprávnění platné a je např. pozastavené nebo jej vůbec nevlastníte a
                tedy <b>nejste podnikatel, nemůžeme Vaši žádost přijmout</b>. Proto ani náš systém
                není schopen načíst data z rejstříku živnostenského oprávnění a tím podání Vaší
                žádosti systém ukončí.
            </p>
            <p>
                Můžete nám také poslat dotaz přes <b>Kontaktní formulář</b> výběrem volby :{' '}
                <b>Vlastní dotaz, námět, prosba, pochvala, kritika</b>.
            </p>

            {/*<img src={Question1_2} className='max-w-xl my-3' alt='question3 image' />*/}
        </div>
    )
}

export default QuestionFour
