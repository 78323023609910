import { Transition } from '@headlessui/react'
import { FC, Fragment, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { routerCatalog } from 'routes/hooks/useSecureRouting'

interface IActionNotificationProps {
    message: string
    title: string
    actionHandler: () => void
}

const ActionNotification: FC<IActionNotificationProps> = ({ message, title, actionHandler }) => {
    const [show, setShow] = useState(true)
    const location = useLocation()

    useEffect(() => {
        if (location.pathname === routerCatalog.dashboard) {
            setShow(true)
        }
    }, [location])

    return (
        <>
            {/* Global notification live region, render this permanently at the end of the document */}
            <div
                aria-live='assertive'
                className='pointer-events-none absolute inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-10'>
                <div className='flex w-full flex-col items-center space-y-4 sm:items-end'>
                    {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                    <Transition
                        show={show}
                        as={Fragment}
                        enter='transform ease-out duration-300 transition'
                        enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
                        enterTo='translate-y-0 opacity-100 sm:translate-x-0'
                        leave='transition ease-in duration-100'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'>
                        <div className='pointer-events-auto flex w-full max-w-md rounded-lg bg-red-200 shadow-lg ring-1 ring-black ring-opacity-5'>
                            <div className='w-0 flex-1 p-4'>
                                <div className='flex items-start'>
                                    {/*<div className='flex-shrink-0 pt-0.5'>*/}
                                    {/*    <img*/}
                                    {/*        className='h-10 w-10 rounded-full'*/}
                                    {/*        src='https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80'*/}
                                    {/*        alt=''*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                    <div className='ml-3 w-0 flex-1'>
                                        <p className='text-sm font-medium'>{title}</p>
                                        <p className='mt-1 text-sm'>{message}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex border-l border-red-300'>
                                <button
                                    type='button'
                                    className='flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-sm font-medium text-red-600 hover:text-lightPurple focus:outline-none focus:ring-2 focus:ring-indigo-500'
                                    onClick={() => {
                                        actionHandler()
                                        setShow(false)
                                    }}>
                                    Vložit <br /> dokumenty
                                </button>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </>
    )
}

export default ActionNotification
