import { SEPAPaymentData } from 'pages/dashboard/modals/payment/components/QRcode'

export const generateQRPaymentData = (
    type: 'deferral' | 'loan',
    amount: number,
    birthNumber?: string,
    contractNumber?: string,
): SEPAPaymentData => {
    if (type === 'deferral') {
        return {
            ACC: 'CZ5220100000002100806238',
            AM: `${amount}` ?? '0',
            CC: 'CZK',
            RF: `${birthNumber}`,
            'X-VS': `5${contractNumber}` ?? '',
            PT: 'IP',
            MSG: `QR - uhrada - odklad ${contractNumber}` ?? '',
        }
    } else {
        return {
            ACC: 'CZ5220100000002100806238',
            AM: `${amount}` ?? '0',
            CC: 'CZK',
            RF: `${birthNumber}`,
            'X-VS': `${contractNumber}` ?? '',
            PT: 'IP',
            MSG: `QR - uhrada - uver ${contractNumber}` ?? '',
        }
    }
}
