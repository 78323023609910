import React, { FC } from 'react'

interface ITableHeaderProps {
    cells: string[]
    className?: string
}

const TableHeader: FC<ITableHeaderProps> = ({ cells, className }) => {
    return (
        <thead className={className ?? 'bg-gray-50'}>
            <tr>
                {cells.map((cell, index) => (
                    <th
                        key={cell}
                        scope='col'
                        className={`py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 ${
                            index === cells.length - 1 && 'text-right right-3 relative'
                        } ${
                            index > 0 && index !== cells.length - 1 && 'text-center'
                        } ${className}`}>
                        {cell}
                    </th>
                ))}
            </tr>
        </thead>
    )
}

export default TableHeader
