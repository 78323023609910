import { useEffect } from 'react'
import TheoLoading from 'assets/images/TheoLoading'
import { Pattern } from 'assets/pattern/Pattern'
import { useApiCall, useAuthApi } from 'api'
import { useNavigate } from 'react-router'
import { routerCatalog } from 'routes/hooks/useSecureRouting'
import { useDispatch } from 'react-redux'
import { serviceApi } from 'api/serviceApi'
import { resetState } from 'store/stateReducer'
import Gleap from 'gleap'

const Logout = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { authLogout } = useAuthApi()
    const { apiCall } = useApiCall()

    useEffect(() => {
        (async () => await apiCall(authLogout))()

        Gleap.close()
        Gleap.showFeedbackButton(false)

        setTimeout(() => {
            dispatch(serviceApi.util.resetApiState())
            dispatch(resetState())
            navigate(routerCatalog.login)
        }, 3000)
    }, [])

    return (
        <div className='container mx-auto h-full w-full grid align-center justify-center content-center overflow-x-hidden '>
            <Pattern />
            <div className={'z-10'}>
                <TheoLoading />
                <h2 className='text-2xl font-semibold text-center font-Quicksand text-gray-900 sm:text-3xl sm:tracking-tight mb-8'>
                    Malý moment, právě Vás bezpečně odhlašujeme z klientského prostředí.
                </h2>
            </div>
        </div>
    )
}

export default Logout
