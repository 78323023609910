import { combineReducers, configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import {
    FLUSH,
    PAUSE,
    PERSIST,
    persistReducer,
    persistStore,
    PURGE,
    REGISTER,
    REHYDRATE,
} from 'redux-persist'
import appSlice from 'store/stateReducer'
import { TypedUseSelectorHook, useSelector } from 'react-redux'
import { serviceApi } from 'api/serviceApi'

const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(
    persistConfig,
    combineReducers({
        [serviceApi.reducerPath]: serviceApi.reducer,
        app: appSlice.reducer,
    }),
)

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(serviceApi.middleware),
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof persistedReducer>
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
