// handle all logic for upload document
// handle file upload, file upload progress, button disabling policy, and error handling

import { ChangeEvent, useEffect, useState } from 'react'
import { TFileList, TUploadingSteps } from 'pages/profile/hooks/types'
import { CustomerPapers } from 'api/customers/types'
import {
    useGetCustomerPapersQuery,
    useUploadCustomerPapersMutation,
} from 'api/services/customer/serviceCustomer'
import { useCustomersApi } from 'api'

const initialList: TUploadingSteps[] = [
    {
        id: 1,
        type: 0,
        title: 'Nahrajte sken přední strany Vašeho OP',
        progressTitle: 'Sken přední strany OP',
        classTitle: 'text-left',
        uploaded: false,
        width: '10%',
    },
    {
        id: 2,
        type: 0,
        title: 'Nahrajte sken zadní strany Vašeho OP',
        progressTitle: 'Sken zadní strany OP',
        classTitle: 'text-center',
        uploaded: false,
        width: '50%',
    },
    {
        id: 3,
        type: 1,
        title: 'Nahrajte sken výpisu z b.ú.',
        progressTitle: 'Sken výpisu z b.ú.',
        classTitle: 'text-right',
        uploaded: false,
        width: '90%',
    },
]

export const useUploadDocumentLogicLayer = (customerPapers?: CustomerPapers[]) => {
    const [uploadCustomerPapers] = useUploadCustomerPapersMutation()
    const customerPapersQuery = useGetCustomerPapersQuery()
    const { customerPapersPost } = useCustomersApi()
    const [files, setFiles] = useState<TFileList[]>([])
    const [progress, setProgress] = useState({ step: 1, width: '10%' })
    const [uploadingSteps, setUploadingSteps] = useState(initialList)

    const moveThroughUploadStepsHandler = () => {
        setProgress((current) => {
            return {
                step: current.step + 1,
                width: uploadingSteps.find((step) => step.id === current.step + 1)?.width ?? '10%',
            }
        })
    }

    const handleDisableButtonLogic = (step: number): boolean => {
        switch (step) {
            case 1:
                return !uploadingSteps.find((step) => step.id === 1)!.uploaded
            case 2:
                return !uploadingSteps.find((step) => step.id === 2)!.uploaded
            case 3:
                return !uploadingSteps.find((step) => step.id === 3)!.uploaded
            default:
                return false
        }
    }

    const uploadFileToServer = async (type: number, file: File) => {
        const formData = new FormData()
        formData.append('type', `${type}`)
        formData.append('file', file)

        await customerPapersPost(formData)
        customerPapersQuery.refetch()
    }

    const fileUploadHandler = (e: ChangeEvent<HTMLInputElement>, type: number) => {
        setFiles((current) => [
            ...current,
            {
                type,
                file: e.target.files![0],
            },
        ])

        setUploadingSteps((current) => [
            ...current.map((step) => {
                if (step.id === progress.step) {
                    return { ...step, uploaded: true }
                }
                return step
            }),
        ])

        uploadFileToServer(type, e.target.files![0])
    }

    const finishUploadingDocuments = (setOpen: (value: boolean) => void) => {
        setOpen(false)
        setProgress({ step: 1, width: '10%' })
        setUploadingSteps(initialList)
    }

    useEffect(() => {
        if (customerPapers?.some((paper) => paper.type === 0) && customerPapers?.length === 1) {
            setProgress({ step: 2, width: '50%' })
        } else if (
            customerPapers?.some((paper) => paper.type === 0) &&
            customerPapers?.length === 2
        )
            setProgress({ step: 3, width: '90%' })
    }, [])

    return {
        files,
        progress,
        uploadingSteps,
        moveThroughUploadStepsHandler,
        handleDisableButtonLogic,
        fileUploadHandler,
        finishUploadingDocuments,
    }
}
