import React, { useEffect } from 'react'
import { PageWrapLayout } from 'layout/components'
import { classNames } from 'utils'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import QuestionOne from 'pages/faq/components/QuestionOne'
import QuestionTwo from 'pages/faq/components/QuestionTwo'
import QuestionThree from 'pages/faq/components/QuestionThree'
import QuestionFour from 'pages/faq/components/QuestionFour'
import QuestionFive from 'pages/faq/components/QuestionFive'
import QuestionSix from 'pages/faq/components/QuestionSix'
import QuestionSeven from 'pages/faq/components/QuestionSeven'
import QuestionEight from 'pages/faq/components/QuestionEight'
import QuestionNine from 'pages/faq/components/QuestionNine'
import QuestionTen from 'pages/faq/components/QuestionTen'
import QuestionEleven from 'pages/faq/components/QuestionEleven'
import QuestionTwelve from 'pages/faq/components/QuestionTwelve'
import QuestionThreeten from 'pages/faq/components/QuestionThreeten'
import QuestionFourteen from 'pages/faq/components/QuestionFourteen'
import QuestionFifteen from 'pages/faq/components/QuestionFifteen'
import QuestionSixteen from 'pages/faq/components/QuestionSixteen'
import QuestionSeventeen from 'pages/faq/components/QuestionSeventeen'
import QuestionEighteen from 'pages/faq/components/QuestionEighteen'
import QuestionNineteen from 'pages/faq/components/QuestionNineteen'
import QuestionTwenty from 'pages/faq/components/QuestionTwenty'
import { Link } from 'react-router-dom'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import QuestionTwentyOne from 'pages/faq/components/QuestionTwentyOne'

export const faqs = [
    {
        question: 'Jaké služby VistaCredit.cz nabízí ?',
        slug: 'jake-sluzby-vistacredit-cz-nabizi',
        answer: <QuestionOne />,
    },
    {
        question: 'Pro koho jsou naše úvěry určeny?',
        slug: 'pro-koho-jsou-nase-uvery-urceny',
        answer: <QuestionTwo />,
    },
    {
        question: 'Jaké jsou ceny poskytovaných úvěrů ?',
        slug: 'jake-jsou-ceny-poskytovanych-uveru',
        answer: <QuestionThree />,
    },
    {
        question: 'Nemohu pokračovat v registraci na webu a v odeslání žádosti !',
        slug: 'nemohu-pokracovat-v-registraci-na-webu-a-v-odeslani-zadosti',
        answer: <QuestionFour />,
    },
    {
        question: 'Co je služba RENT ?',
        slug: 'co-je-sluzba-rent',
        answer: <QuestionFive />,
    },
    {
        question: 'Kdy budu moci čerpat vyšší úvěrový rámec ?',
        slug: 'kyd-budu-moci-cerpat-vyssi-uverovy-ramec',
        answer: <QuestionSix />,
    },
    {
        question: 'Co znamená, že nebyla ověřena Identita klienta ?',
        slug: 'co-znamena-ze-nebyla-overena-identita-klienta',
        answer: <QuestionSeven />,
    },
    {
        question: 'Proč mi byla žádost o úvěr zamítnuta (zamítnuto Robotem)',
        slug: 'proc-mi-byla-zadost-o-uver-zamitnuta',
        answer: <QuestionEight />,
    },
    {
        question: 'Odeslal jsem první žádost, co mám dělat dále?',
        slug: 'odeslal-jsem-prvni-zadost-co-mam-delat-dale',
        answer: <QuestionNine />,
    },
    {
        question: 'Mám schválenou žádost - jak a kde mám podepsat Smlouvy ??',
        slug: 'mam-schvalenou-zadost-jak-a-kde-mam-podepsat-smlouvy',
        answer: <QuestionTen />,
    },
    {
        question: 'Jak podepsat dokumenty el. podpisem SIGNI.COM ?',
        slug: 'jak-podepsat-dokumenty-el-podpisem-signi-com',
        answer: <QuestionTwentyOne />,
    },
    {
        question: 'Kde najdu všechny své dokumenty a dokumenty k úvěru?',
        slug: 'kde-najdu-vsechny-sve-dokumenty-a-dokumenty-k-uveru',
        answer: <QuestionEleven />,
    },
    {
        question: 'Jak a kde změním své osobní dokumenty ?',
        slug: 'jak-a-kde-zmenim-sve-osobni-dokumenty',
        answer: <QuestionTwelve />,
    },
    {
        question: 'Jak a kde změním dobu splatnosti mého aktivního úvěru ?',
        slug: 'jak-a-kde-zmenim-dobu-splatnosti-meho-aktivniho-uveru',
        answer: <QuestionThreeten />,
    },
    {
        question: 'Jak uhradím poplatek za Odložení splatnosti mého úvěru ?',
        slug: 'jak-uhradim-poplatek-za-odlozeni-splatnosti-meho-uveru',
        answer: <QuestionFourteen />,
    },
    {
        question: 'Jak změním ostatní osobní údaje (telefon - email, atd.) ?',
        slug: 'jak-zmenim-ostatni-osobni-udaje',
        answer: <QuestionFifteen />,
    },
    {
        question: 'Jak si změním heslo ?',
        slug: 'jak-si-zmenim-heslo',
        answer: <QuestionSixteen />,
    },
    {
        question: 'Uhradil(a) jsem platbu se špatným variabilním symbolem !',
        slug: 'uhradil-jsem-platbu-se-spatnym-variabilnim-symbolem',
        answer: <QuestionSeventeen />,
    },
    {
        question: 'Změnil(a) jsem svou banku !',
        slug: 'zmenil-jsem-svou-banku',
        answer: <QuestionEighteen />,
    },
    {
        question: 'Mám nasbírané body a nebojím se je použít !',
        slug: 'mam-nasbirane-body-a-nebojim-se-je-pouzit',
        answer: <QuestionNineteen />,
    },
    {
        question: 'Co mám dělat, pokud nemohu splatit úvěr ?',
        slug: 'co-mam-delat-pokud-nemohu-splatit-uver',
        answer: <QuestionTwenty />,
    },
]
const Faq = () => {
    useEffect(() => {
        const element = document.getElementById('section-1')
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' })
        }
    }, [])

    return (
        <PageWrapLayout title={'POMOC - Nejčastější dotazy'}>
            <div className='bg-white rounded-xl border border-borderGray'>
                <div className='mx-auto max-w-7xl py-12 pt-4 px-4 sm:py-16 sm:pt-4 sm:px-6 lg:px-8'>
                    <div className='mx-auto max-w-8xl divide-y-2 divide-gray-200'>
                        <dl className='mt-2 space-y-6 divide-y divide-gray-200'>
                            {faqs.map((faq) => (
                                <div key={faq.question} className='pt-6'>
                                    <dt className='text-lg'>
                                        <div className='flex w-full items-start justify-between text-left text-gray-400'>
                                            <Link
                                                to={`/pomoc/${faq.slug}`}
                                                id={`${faq.slug}`}
                                                className={`flex items-center gap-x-2 font-medium font-Quicksand hover:text-lightPurple text-base lg:text-xl`}>
                                                <span>
                                                    <QuestionMarkCircleIcon
                                                        className={'h-6 w-6 mt-0.5'}
                                                    />
                                                </span>
                                                <span>{faq.question}</span>
                                            </Link>
                                            <span className='ml-6 flex h-7 items-center'>
                                                <ChevronDownIcon
                                                    className={classNames(
                                                        '-rotate-90',
                                                        'h-6 w-6 transform',
                                                    )}
                                                    aria-hidden='true'
                                                />
                                            </span>
                                        </div>
                                    </dt>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>
            </div>
        </PageWrapLayout>
    )
}

export default Faq
