import { useState } from 'react'
import { PageWrapLayout } from 'layout/components'
import TableSkeleton from 'pages/loans/components/TableSkeleton'
import TableHeader from 'pages/loans/components/TableHeader'
import TableBody from 'pages/loans/components/TableBody'
import Table from 'pages/loans/components/Table'
import TableMobile from 'pages/loans/components/TableMobile'
import DetailModal from 'pages/loans/modals/DetailModal'
import { Loan } from 'api/loans/types'
import Pagination from 'layout/components/pagination/Pagination'
import { useGetLoanDetailMutation, useGetLoansListQuery } from 'api/services/loan/serviceLoan'

const Loans = () => {
    const [openDetail, setOpenDetail] = useState(false)
    const [list, setList] = useState<Loan[] | []>([])

    const [getLoanDetail, loanDetail] = useGetLoanDetailMutation()
    const loansListQuery = useGetLoansListQuery()

    const handleOpenDetail = async (id: number) => {
        await getLoanDetail(id)
        setOpenDetail(true)
    }

    if (!loansListQuery?.data) {
        return <TableSkeleton />
    }

    const cells = [
        'ID ÚVĚRU',
        'TYP',
        'DATUM ŽÁDOSTI',
        'JISTINA',
        'SPLATNOST ÚVĚRU',
        'PLATBY',
        'STAV ÚVĚRU',
    ]

    return (
        <PageWrapLayout>
            <div className='sr-only md:not-sr-only'>
                <Table noStyle>
                    <TableHeader cells={cells} className={'border-none rounded-none shadow-none'} />
                    <TableBody data={list} openDetail={handleOpenDetail} />
                </Table>
                <Pagination
                    setList={setList}
                    perPage={7}
                    itemsList={loansListQuery?.data.results}
                    count={loansListQuery?.data.count - 1}
                />
            </div>
            <div className='md:sr-only'>
                <TableMobile data={list} openDetail={handleOpenDetail} />
                <Pagination
                    setList={setList}
                    perPage={7}
                    itemsList={loansListQuery?.data.results}
                    count={loansListQuery?.data.count - 1}
                />
            </div>

            {openDetail && (
                <DetailModal
                    open={openDetail}
                    setOpen={() => setOpenDetail(true)}
                    loan={loanDetail.data}
                    closeModal={() => setOpenDetail(false)}
                />
            )}
        </PageWrapLayout>
    )
}

export default Loans
