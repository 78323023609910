import React, { FC } from 'react'
import { formatCurrency } from 'utils'
import Table from 'pages/loans/components/Table'
import TableHeader from 'pages/loans/components/TableHeader'
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline'
import { TLoanDetail } from 'api/services/loan/types'

interface IPaymentsTabProps {
    loan?: TLoanDetail
    cells: string[]
}

const PaymentsTab: FC<IPaymentsTabProps> = ({ loan, cells }) => {
    if (!loan) return null

    if ([...loan.payments, ...loan.deferral_payments].length === 0) {
        return (
            <div className='mb-8'>
                <h1 className='text-lg uppercase text-center text-gray-600 font-medium font-Quicksand text-gray-900 mb-4'>
                    Zatím neevidujeme žádné platby k úvěru
                </h1>
            </div>
        )
    }

    return (
        <div className='mb-8'>
            <h1 className='text-lg uppercase text-center text-gray-600 font-medium font-Quicksand text-gray-900 mb-4'>
                Přehled všech plateb k mému úvěru
            </h1>
            <Table noStyle>
                <TableHeader cells={cells} className={'border-none rounded-none shadow-none'} />
                <tbody className='divide-y divide-gray-200 bg-white'>
                    {[...loan.payments, ...loan.deferral_payments].map((item) =>
                        item.order_items.map((orderItem) => (
                            <tr key={item.doc_url}>
                                <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                                    {loan.contract_number}
                                </td>
                                <td className='whitespace-nowrap flex justify-center px-3 py-4 text-sm text-center text-gray-500'>
                                    {orderItem.item}
                                </td>
                                <td className='whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500'>
                                    {/*{getDate(orderItem?.items[0]?.created)}*/}
                                </td>
                                <td className='whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500'>
                                    {formatCurrency(orderItem.amount, 'CZK')}
                                </td>
                                <td className='whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500'>
                                    {item.source}
                                </td>
                                <td className='whitespace-nowrap px-3 py-4 text-sm text-right text-lightPurple hover:text-lightBlue'>
                                    <a
                                        rel='noreferrer'
                                        href={item.doc_url}
                                        target='_blank'
                                        className='flex justify-end'>
                                        <DocumentArrowDownIcon className='w-5 h-5 mr-1' />
                                        <span>PDF</span>
                                    </a>
                                </td>
                            </tr>
                        )),
                    )}
                </tbody>
            </Table>
        </div>
    )
}

export default PaymentsTab
