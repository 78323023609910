import React, { FC } from 'react'
import { TButtonProps } from './types'

export const ButtonPurple: FC<TButtonProps> = ({
    children,
    style,
    className,
    disabled,
    ...rest
}) => {
    return (
        <button
            {...rest}
            type='button'
            disabled={disabled}
            style={style}
            className={`inline-flex
            items-center justify-center rounded-md border border-transparent
             bg-lightPurple px-6 py-3 text-sm font-light text-white uppercase hover:shadow-md hover:bg-darkPurple duration-300 ${
                 disabled && 'opacity-50'
             }`}>
            {children}
        </button>
    )
}
