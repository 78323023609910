import React, { FC } from 'react'
import { PaperClipIcon } from '@heroicons/react/24/solid'

interface IPDFPreviewProps {
    url: string
    height?: string
}

const PDFPreview: FC<IPDFPreviewProps> = ({ url, height }) => {
    return (
        <>
            <div className='sr-only sm:not-sr-only sm:no-sr-only mt-4 flex justify-center items-center'>
                <object
                    data={url}
                    type='application/pdf'
                    width='95%'
                    style={{ border: 'none' }}
                    height={height ?? '340px'}>
                    <iframe src={url} frameBorder='0' scrolling='auto' width='100%' height='100%' />
                </object>
            </div>
            <div className='not-sr-only sm:sr-only flex flex-1 items-start justify-center text-gray-600 hover:underline hover:text-lightPurple my-8'>
                <PaperClipIcon
                    className='h-5 w-5 relative top-0.5 flex-shrink-0'
                    aria-hidden='true'
                />
                <a rel='noreferrer' href={url} target='_blank' className='ml-2 font-light'>
                    Zobrazit detail smlouvy v PDF
                </a>
            </div>
        </>
    )
}

export default PDFPreview
