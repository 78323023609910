import { serviceApi } from 'api/serviceApi'
import { ICustomerExpiration, ICustomerPapers, TCustomer } from 'api/services/customer/types'

const customerApi = serviceApi.injectEndpoints({
    endpoints: (build) => ({
        getCustomer: build.query<TCustomer, void>({
            query: () => 'customers/me/',
            providesTags: ['Customer'],
        }),
        getCustomerPapers: build.query<ICustomerPapers[], void>({
            query: () => 'customer-papers/',
        }),
        getCustomerIdExpiration: build.query<ICustomerExpiration, void>({
            query: () => 'customer-id-expiration/',
        }),
        uploadCustomerPapers: build.mutation<ICustomerPapers[], any>({
            query: (payload) => {
                return {
                    url: 'customer-papers/',
                    method: 'POST',
                    body: payload,
                }
            },
        }),
        sendEmail: build.mutation<unknown, any>({
            query: (payload) => {
                return {
                    url: 'support-email/',
                    method: 'POST',
                    body: payload,
                }
            },
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetCustomerIdExpirationQuery,
    useGetCustomerPapersQuery,
    useGetCustomerQuery,
    useUploadCustomerPapersMutation,
    useSendEmailMutation,
} = customerApi
