import React, { FC } from 'react'
import Table from 'pages/loans/components/Table'
import TableHeader from 'pages/loans/components/TableHeader'
import { getDate } from 'utils/date'
import { ContractsListResponse } from 'api/contracts/types'
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline'
import { TLoanDetail } from 'api/services/loan/types'

interface IContractsProps {
    cells: string[]
    loan?: TLoanDetail
    contracts: {
        loanContracts: ContractsListResponse | null
        deferralContracts: ContractsListResponse | null
    } | null
}

const ContractsTab: FC<IContractsProps> = ({ cells, loan, contracts }) => {
    if (!loan) return null

    const results = [
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        // eslint-disable-next-line no-unsafe-optional-chaining
        ...contracts?.loanContracts?.results?.filter(
            (contract) => contract.number === loan.contract_number,
        ),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        // eslint-disable-next-line no-unsafe-optional-chaining
        ...contracts?.deferralContracts?.results?.filter(
            (contract) => contract.loan_public_id === loan.contract_number,
        ),
    ]

    return (
        <div className='sm:my-12'>
            <div className='mt-6 mb-4 sm:my-6 sm:mt-12'>
                <h1 className='text-lg uppercase text-center text-gray-600 font-medium font-Quicksand text-gray-900 mb-4'>
                    Přehled všech smluv k mému úvěru
                </h1>
                <Table noStyle>
                    <TableHeader cells={cells} className={'border-none rounded-none shadow-none'} />
                    <tbody className='divide-y divide-gray-200 bg-white'>
                        {results.map((contract) => (
                            <tr key={contract.id}>
                                <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                                    {contract.type_text}
                                </td>
                                <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm text-center font-medium text-gray-900 sm:pl-6'>
                                    {getDate(contract.signed)}
                                </td>
                                <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm text-center font-medium text-gray-900 sm:pl-6'>
                                    {getDate(contract.created)}
                                </td>
                                <td className='whitespace-nowrap px-3 py-4 text-sm text-right text-lightPurple hover:text-lightBlue'>
                                    <a
                                        rel='noreferrer'
                                        href={contract.url}
                                        target='_blank'
                                        className='flex justify-end'>
                                        <DocumentArrowDownIcon className='w-5 h-5 mr-1' />
                                        <span>PDF</span>
                                    </a>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

export default ContractsTab
