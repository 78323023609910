import React from 'react'

interface IPatternProps {
    className?: string
    style?: any
}

export const Pattern = ({ className = '', style }: IPatternProps) => (
    <svg
        width='868'
        height='913'
        viewBox='0 0 868 913'
        fill='none'
        className={`z-0 ${className}`}
        style={
            style ?? {
                position: 'fixed',
                width: '100%',
                height: '100%',
                maxHeight: '100vh',
                right: '-20%',
                top: 0,
                zIndex: 0,
                opacity: 0.5,
            }
        }
        xmlns='http://www.w3.org/2000/svg'>
        <path
            opacity='0.2'
            d='M324.191 218.573L423.784 222.913L318.576 318.103L324.191 218.573Z'
            fill='#00B8F5'
        />
        <path
            opacity='0.05'
            d='M204.931 456.128L218.177 554.932L105.966 468.107L204.931 456.128Z'
            fill='#00B8F5'
        />
        <path
            opacity='0.2'
            d='M755.239 751.438L847.975 788.013L717.48 843.698L755.239 751.438Z'
            fill='#00B8F5'
        />
        <path
            opacity='0.5'
            d='M506.95 282.668L532.656 337.438L451.856 307.671L506.95 282.668Z'
            fill='#00B8F5'
        />
        <path
            opacity='0.5'
            d='M377.602 453.676L434.621 473.907L356.642 510.431L377.602 453.676Z'
            fill='#00B8F5'
        />
        <path
            opacity='0.5'
            d='M570.637 648.035L615.75 688.35L529.747 692.627L570.637 648.035Z'
            fill='#00B8F5'
        />
        <path
            opacity='0.5'
            d='M83.9326 116.064L29.0749 90.5472L110.15 61.5375L83.9326 116.064Z'
            fill='#00B8F5'
        />
        <path
            opacity='0.1'
            d='M396.53 525.595L427.09 366.842L554.878 558.186L396.53 525.595Z'
            fill='#00B8F5'
        />
        <path
            opacity='0.05'
            d='M270.237 680.038L418.047 745.525L202.862 826.996L270.237 680.038Z'
            fill='#00B8F5'
        />
        <path
            opacity='0.05'
            d='M200.673 55.1644L177.036 215.094L41.0597 29.4799L200.673 55.1644Z'
            fill='#00B8F5'
        />
    </svg>
)
