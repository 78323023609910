import { Navigation } from 'layout/components/navigation/Navigation'
import { Outlet } from 'react-router-dom'
import { Pattern } from 'assets/pattern/Pattern'
import { SidebarNav } from 'layout/components/navigation/components'
import React, { FC, useEffect } from 'react'
import { useAppSelector } from 'store/store'
import Loading from 'pages/login/Loading'
import MobileNav from 'layout/components/mobileNav/MobileNav'
import { useNavigate } from 'react-router'
import { routerCatalog } from 'routes/hooks/useSecureRouting'
import ActionNotification from 'layout/components/notifications/ActionNotification'
import { useRules } from 'hooks/useRules'
import Notification from 'layout/components/notifications/Notification'
import Gleap from 'gleap'

const Layout: FC = () => {
    const notification = useAppSelector((state) => state.app.notification)
    const token = useAppSelector((state) => state.app.token)
    const navigate = useNavigate()
    const { pointsQuery, customerQuery, activeLoanQuery } = useRules()

    useEffect(() => {
        if (token !== '') {
            Gleap.showFeedbackButton(true)
        }
    }, [token])

    useEffect(() => {
        if (customerQuery.data && !Gleap.isUserIdentified()) {
            Gleap.identify(customerQuery.data.birth_number, {
                name: `${customerQuery.data.first_name}  ${customerQuery.data.last_name}`,
                email: customerQuery.data.email,
                phone: customerQuery.data.phone,
                companyName: customerQuery.data.business.ico,
                companyId: `${activeLoanQuery.data?.id}`,
                plan: activeLoanQuery.data?.loan_type ?? 'Nemá aktívní úvěr',
                value: activeLoanQuery.data?.amount,
            })
        }
    }, [customerQuery.data])

    if (!customerQuery?.data || !pointsQuery?.data) return <Loading withText={false} />

    return (
        <div
            id={'root-layout'}
            className={`2xl:container relative mx-auto flex h-full flex-col font-Mulish font-bold bg-appBackground`}>
            {/* Alert section */}
            {/*<Alert type={'error'} title={'nieco'} />*/}

            {/* Background pattern */}
            <Pattern />

            {/* Top nav*/}
            <Navigation customer={customerQuery?.data} points={pointsQuery?.data} />

            {!customerQuery?.data?.papers_uploaded && (
                <ActionNotification
                    title='Ještě nebyla ověřena Vaše identita'
                    message={'Prosím vložte požadované dokumenty!'}
                    actionHandler={() => navigate(routerCatalog.profile)}
                />
            )}

            {/* Bottom section */}
            <div className='mt-3 flex min-h-0 flex-1 overflow-hidden'>
                {/* Narrow sidebar*/}
                <SidebarNav />

                {/* Main area */}
                <main className='min-w-0 flex-1 lg:flex bg-transparent z-0 rounded-tl-2xl'>
                    <section
                        aria-labelledby='primary-heading'
                        className='flex h-full min-w-0 flex-1 flex-col overflow-y-auto overflow-x-hidden z-10'>
                        <Outlet />
                    </section>
                </main>
            </div>

            {notification && <Notification notification={notification} />}

            {/* Mobile navigation */}
            <MobileNav customer={customerQuery?.data} points={pointsQuery?.data} />
        </div>
    )
}
export default Layout
