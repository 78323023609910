import { FC } from 'react'
import { PageWrapLayout } from 'layout/components'
import LoanSkeleton from 'pages/dashboard/components/LoanSkeleton'

const LoanCalcSkeleton: FC = () => {
    return (
        <PageWrapLayout>
            <div className='grid grid-cols-1 gap-x-6 gap-y-4 xl:grid-cols-10 bg-white rounded-lg border border-borderGray p-8'>
                <div className='col-span-1 xl:col-span-6'>
                    <LoanSkeleton />
                </div>

                <div className='col-span-1 mt-4 sm:mt-0 xl:col-span-4'>
                    <LoanSkeleton />
                </div>
            </div>
        </PageWrapLayout>
    )
}

export default LoanCalcSkeleton
