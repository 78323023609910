import { FC, Fragment, useEffect, useState } from 'react'
import { Transition } from '@headlessui/react'
import {
    CheckCircleIcon,
    ExclamationCircleIcon,
    ExclamationTriangleIcon,
} from '@heroicons/react/24/outline'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { useDispatch } from 'react-redux'
import { setNotification } from 'store/stateReducer'
import { TNotification } from 'store/types'

interface INotificationProps {
    notification: TNotification
}

const Notification: FC<INotificationProps> = ({ notification }) => {
    const [show, setShow] = useState(true)
    const [progress, setProgress] = useState(100)
    const dispatch = useDispatch()

    useEffect(() => {
        const loadingTimeout = setTimeout(() => {
            if (progress <= 0) {
                dispatch(setNotification(null))
                return
            }
            setProgress(progress - 1)
        }, 3500 / 100)

        return () => {
            clearTimeout(loadingTimeout)
            // dispatch(setNotification(null))
        }
    }, [progress])

    return (
        <>
            {/* Global notification live region, render this permanently at the end of the document */}
            <div
                aria-live='assertive'
                className='pointer-events-none absolute z-50 top-0 right-0 inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6'>
                <div className='flex w-full flex-col items-center space-y-4 sm:items-end'>
                    {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                    <Transition
                        show={show}
                        as={Fragment}
                        enter='transform ease-out duration-300 transition'
                        enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
                        enterTo='translate-y-0 opacity-100 sm:translate-x-0'
                        leave='transition ease-in duration-100'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'>
                        <div
                            className={`pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5  bg-white`}>
                            <div className='p-4'>
                                <div className='flex items-start'>
                                    <div className='flex-shrink-0'>
                                        {notification.type === 'success' && (
                                            <CheckCircleIcon
                                                className='h-6 w-6 text-green-400'
                                                aria-hidden='true'
                                            />
                                        )}
                                        {notification.type === 'error' && (
                                            <ExclamationCircleIcon
                                                className='h-6 w-6 text-red-400'
                                                aria-hidden='true'
                                            />
                                        )}
                                        {notification.type === 'info' && (
                                            <ExclamationTriangleIcon
                                                className='h-6 w-6 text-orange-400'
                                                aria-hidden='true'
                                            />
                                        )}
                                    </div>
                                    <div className='ml-3 w-0 flex-1 pt-0.5'>
                                        <p className='text-sm font-medium text-gray-900'>
                                            {notification.title}
                                        </p>
                                        <p className='mt-1 text-sm text-gray-500'>
                                            {notification.message}
                                        </p>
                                    </div>

                                    <div className='ml-4 flex flex-shrink-0'>
                                        <button
                                            type='button'
                                            className='inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                                            onClick={() => {
                                                setShow(false)
                                                dispatch(setNotification(null))
                                            }}>
                                            <span className='sr-only'>Close</span>
                                            <XMarkIcon className='h-5 w-5' aria-hidden='true' />
                                        </button>
                                    </div>
                                </div>
                                <div className='w-full mt-3 bg-gray-200 rounded-full h-1 dark:bg-gray-700'>
                                    <div
                                        className='bg-purple-600 h-1 rounded-full dark:bg-purple-500'
                                        style={{ width: `${progress}%` }}></div>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </>
    )
}

export default Notification
