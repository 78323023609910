import { serviceApi } from 'api/serviceApi'
import { IPoint, IPointsList } from 'api/services/utils/points/types'

const pointsApi = serviceApi.injectEndpoints({
    endpoints: (build) => ({
        getPointsList: build.query<IPointsList, void>({
            query: () => `points/`,
        }),
        getPointDetail: build.query<IPoint, number>({
            query: (id) => `points/${id}/`,
        }),
    }),
    overrideExisting: false,
})

export const { useGetPointsListQuery, useGetPointDetailQuery } = pointsApi
