import { FC } from 'react'
import { Nav, NavLogo } from './components'
import { Customer } from 'api/customers/types'
import { PointsListResponse } from 'api/points/types'
import { ArrowRightOnRectangleIcon, InformationCircleIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router'
import { routerCatalog } from 'routes/hooks/useSecureRouting'

interface INavigationProps {
    customer: Customer
    points: PointsListResponse
}

export const Navigation: FC<INavigationProps> = ({ customer, points }) => {
    const navigate = useNavigate()

    const logout = async () => {
        navigate(routerCatalog.logout)
    }

    return (
        <header className='bg-white mb-0 rounded-bl-xl rounded-br-xl border border-borderGray shadow-md md:border-0 md:shadow-none md:rounded-0 md:bg-transparent relative flex h-24 flex-shrink-0 items-center md:mb-3'>
            <div className='md:sr-only ml-4 cursor-pointer hover:text-lightBlue'>
                <InformationCircleIcon className='h-6 w-6' />
            </div>
            {/* Logo area */}
            <NavLogo />
            <div className='md:sr-only mr-4 cursor-pointer hover:text-lightBlue' onClick={logout}>
                <ArrowRightOnRectangleIcon className='h6 w-6' />
            </div>

            {/* Desktop nav area */}
            <Nav customer={customer} points={points} />
        </header>
    )
}
