import React, { FC } from 'react'
import MainStats from 'pages/loans/modals/components/MainStats'
import Table from 'pages/loans/components/Table'
import TableHeader from 'pages/loans/components/TableHeader'
import { formatCurrency, getLoanStatusColor } from 'utils'
import { getDate } from 'utils/date'
import { Badge } from '@vistacredit/lib-ui'
import { TLoanDetail } from 'api/services/loan/types'

interface IInfoTabProps {
    loan?: TLoanDetail
    cells: string[]
}

const InfoTab: FC<IInfoTabProps> = ({ loan, cells }) => {
    if (!loan) return null

    return (
        <div className='sm:my-12'>
            <MainStats loan={loan} />
            <div className='mt-6 mb-4 sm:my-6 sm:mt-12'>
                <h1 className='text-lg uppercase text-center text-gray-600 font-medium font-Quicksand text-gray-900 mb-4'>
                    Další podrobnosti o mém úvěru
                </h1>
                <Table noStyle>
                    <TableHeader cells={cells} className={'border-none rounded-none shadow-none'} />
                    <tbody className='divide-y divide-gray-200 bg-white'>
                        <tr key={loan.id}>
                            <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                                {loan.contract_number}
                            </td>
                            <td
                                className={`whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500 ${
                                    loan.type === 0 ? 'text-lightPurple' : 'text-lightBlue'
                                }`}>
                                {loan.type_text}
                            </td>
                            <td className='whitespace-nowrap flex justify-center px-3 py-4 text-sm text-center text-gray-500'>
                                {formatCurrency(loan.fee, 'CZK')}
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500'>
                                {getDate(loan.created)}
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500'>
                                {getDate(loan.due)}
                            </td>

                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right'>
                                <Badge
                                    type={getLoanStatusColor(loan.status)}
                                    text={loan.status_text}
                                />
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

export default InfoTab
