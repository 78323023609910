import { FC } from 'react'
import TheoLoading from 'assets/images/TheoLoading'
import { Pattern } from 'assets/pattern/Pattern'

interface ILoadingProps {
    withText?: boolean
}

const Loading: FC<ILoadingProps> = ({ withText = true }) => {
    return (
        <div className='container mx-auto h-full w-full grid align-center justify-center content-center overflow-x-hidden '>
            <Pattern />
            <div className={'z-10'}>
                <TheoLoading />
                {withText && (
                    <h2 className='text-2xl font-semibold text-center font-Quicksand text-gray-900 sm:text-3xl sm:tracking-tight mb-8'>
                        Malý moment, právě Vás přihlašujeme do klientského účtu.
                    </h2>
                )}
            </div>
        </div>
    )
}

export default Loading
