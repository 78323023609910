import { FC, forwardRef } from 'react'

interface IInputWithIconProps {
    Icon: any
    placeholder: string
    label?: string
    name?: string
    type: string
}

export const InputWithIcon: FC<IInputWithIconProps> = forwardRef<any, IInputWithIconProps>(
    (props: IInputWithIconProps, ref) => {
        const { Icon, placeholder, label, type, ...rest } = props
        return (
            <>
                {label && (
                    <label
                        htmlFor='input-group-1'
                        className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>
                        {label}
                    </label>
                )}
                <div className='relative mb-6'>
                    <div className='absolute inset-y-0 -left-2 flex items-center pl-3 pointer-events-none'>
                        <Icon className={'h-6 w-6 text-lightGray'} />
                    </div>
                    <input
                        ref={ref}
                        type={type}
                        className='bg-white border-b border-borderGray text-lightGray font-light text-sm focus:outline-none focus:border-b-lightPurple block w-full pl-10 p-2.5  '
                        placeholder={placeholder}
                        {...rest}
                    />
                </div>
            </>
        )
    },
)

InputWithIcon.displayName = 'InputWithIcon'
