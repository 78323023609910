import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from 'store/store'
import { Customer } from 'api/customers/types'

export const serviceApi = createApi({
    reducerPath: 'appSlice',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).app.token

            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('Authorization', `Token ${token}`)
            }
            headers.set('Accept', 'application/json')
            headers.set('Content-Type', 'application/json')
        },
    }),
    tagTypes: [
        'Loan',
        'Deferral',
        'Documents',
        'Customer',
        'ContractsToSign',
        'OrdersToPay',
        'LoanList',
        'PaymentList',
    ],
    endpoints: (builder) => ({}),
})
