import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { FC, useEffect } from 'react'
import { useGetCustomerQuery } from 'api/services/customer/serviceCustomer'
import { BadgePapers } from '@vistacredit/lib-ui'
import { classNames } from 'utils'
import { UserCategory } from 'utils/customerCategory'
import { getDate, isDateOlderThanToday } from 'utils/date'

interface IHeaderTabsProps {
    activeTab: string
    chooseLoanType: (type: string) => void
}

const HeaderTabs: FC<IHeaderTabsProps> = ({ activeTab, chooseLoanType }) => {
    const customerQuery = useGetCustomerQuery()
    const tabs = ['fix', 'rent']

    useEffect(() => {
        customerQuery.refetch()
    }, [])

    return (
        <ul className='flex flex-col text-center text-gray-500 relative'>
            <div
                className={classNames(
                    'sm:absolute mb-3 inline-block sm:top-2',
                    activeTab === 'rent' ? 'sm:left-[17rem]' : 'sm:left-64',
                )}>
                {customerQuery?.data?.rent_active &&
                customerQuery?.data?.category === UserCategory.Business ? (
                    <BadgePapers
                        type={'status-greenLight'}
                        text={<span>Službu RENT máte plně aktivní</span>}
                    />
                ) : (
                    <BadgePapers
                        type={'status-redLight'}
                        text={
                            <span>Službu RENT nemáte aktivní a nebo byla dočasně pozastavena</span>
                        }
                    />
                )}
            </div>
            <div
                className={classNames(
                    'sm:absolute mb-3 inline-block sm:top-2',
                    activeTab === 'rent' ? 'sm:left-[30rem]' : 'sm:left-[29rem]',
                )}>
                {activeTab === 'rent' &&
                    customerQuery?.data?.rent_activation_due &&
                    !isDateOlderThanToday(customerQuery?.data?.rent_activation_due) && (
                        <BadgePapers
                            type={'status_orangeLight'}
                            text={
                                <span>
                                    Platnost vyprší:{' '}
                                    {getDate(customerQuery?.data?.rent_activation_due)}
                                </span>
                            }
                        />
                    )}
            </div>
            <div className={'flex'}>
                {tabs.map((tab) => (
                    <li
                        key={tab}
                        className={`flex justify-center py-4 px-8 cursor-pointer uppercase z-10 -mb-1 text-lightBlue
                            ${
                                tab === activeTab &&
                                'bg-white border-borderGray border-t border-l border-r rounded-tl-lg rounded-tr-lg text-lightPurple'
                            } ${
                            tab === 'rent' &&
                            (!customerQuery?.data?.rent_active ||
                                customerQuery?.data?.category !== UserCategory.Business) &&
                            'pointer-events-none opacity-70'
                        }`}
                        onClick={() => chooseLoanType(tab)}>
                        <div className='flex items-center'>
                            <span>{tab}</span>
                            <InformationCircleIcon
                                className='h-5 w-5 ml-2 text-lightGray'
                                title={'FIX info'}
                            />
                        </div>
                    </li>
                ))}
            </div>
        </ul>
    )
}

export default HeaderTabs
