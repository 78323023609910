import { FC, ReactNode } from 'react'

type TBadgeBoxProps = {
    children: ReactNode | ReactNode[]
    className?: string
}

export const BadgeBox: FC<TBadgeBoxProps> = ({ children, className = '' }) => {
    return (
        <div
            className={`inline-flex relative
                items-start rounded-md border border-transparent
                bg-white px-4 py-4 text-sm font-light text-black border-borderGray ${className}`}>
            {children}
        </div>
    )
}
