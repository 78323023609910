import React, { FC } from 'react'
import { PageWrapLayout } from 'layout/components'

const TableSkeleton: FC = () => {
    return (
        <PageWrapLayout>
            <div className='flex flex-col'>
                <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                    <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
                        <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg bg-white '>
                            <table className='min-w-full divide-y divide-borderGray'>
                                <thead className='bg-gray-50'>
                                    <tr className='text-center'>
                                        <th
                                            scope='col'
                                            className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'>
                                            ID ÚVĚRU
                                        </th>
                                        <th
                                            scope='col'
                                            className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                                            TYP
                                        </th>
                                        <th
                                            scope='col'
                                            className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                                            DATUM ŽÁDOST
                                        </th>
                                        <th
                                            scope='col'
                                            className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                                            JISTINA
                                        </th>
                                        <th
                                            scope='col'
                                            className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                                            SPLATNOST ÚVĚRU
                                        </th>
                                        <th
                                            scope='col'
                                            className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                                            PLATBY
                                        </th>
                                        <th
                                            scope='col'
                                            className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-center'>
                                            STAV ÚVĚRU
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='divide-y divide-gray-200 bg-white'></tbody>
                            </table>
                            <div className='p-4 space-y-4 rounded divide-y divide-borderGray shadow animate-pulse md:p-6 w-full'>
                                <div className='flex justify-between items-center'>
                                    <div>
                                        <div className='h-2.5 bg-borderGray rounded-full  w-24 mb-2.5'></div>
                                        <div className='w-32 h-2 bg-gray-200 rounded-full '></div>
                                    </div>
                                    <div className='h-2.5 bg-borderGray rounded-full  w-12'></div>
                                </div>
                                <div className='flex justify-between items-center pt-4'>
                                    <div>
                                        <div className='h-2.5 bg-borderGray rounded-full  w-24 mb-2.5'></div>
                                        <div className='w-32 h-2 bg-gray-200 rounded-full '></div>
                                    </div>
                                    <div className='h-2.5 bg-borderGray rounded-full  w-12'></div>
                                </div>
                                <div className='flex justify-between items-center pt-4'>
                                    <div>
                                        <div className='h-2.5 bg-borderGray rounded-full  w-24 mb-2.5'></div>
                                        <div className='w-32 h-2 bg-gray-200 rounded-full '></div>
                                    </div>
                                    <div className='h-2.5 bg-borderGray rounded-full  w-12'></div>
                                </div>
                                <div className='flex justify-between items-center pt-4'>
                                    <div>
                                        <div className='h-2.5 bg-borderGray rounded-full  w-24 mb-2.5'></div>
                                        <div className='w-32 h-2 bg-gray-200 rounded-full '></div>
                                    </div>
                                    <div className='h-2.5 bg-borderGray rounded-full  w-12'></div>
                                </div>
                                <div className='flex justify-between items-center pt-4'>
                                    <div>
                                        <div className='h-2.5 bg-borderGray rounded-full  w-24 mb-2.5'></div>
                                        <div className='w-32 h-2 bg-gray-200 rounded-full '></div>
                                    </div>
                                    <div className='h-2.5 bg-borderGray rounded-full  w-12'></div>
                                </div>
                                <span className='sr-only'>Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageWrapLayout>
    )
}

export default TableSkeleton
