import { FC } from 'react'

const LoanSkeleton: FC = () => {
    return (
        <>
            <div role='status' className='w-auto mb-4 animate-pulse'>
                <div className='h-2.5 bg-borderGray rounded-full  w-48 mb-4'></div>
                <div className='h-2 bg-borderGray rounded-full  max-w-[360px] mb-2.5'></div>
                <div className='h-2 bg-borderGray rounded-full  mb-2.5'></div>
                <div className='h-2 bg-borderGray rounded-full  max-w-[330px] mb-2.5'></div>
                <div className='h-2 bg-borderGray rounded-full  max-w-[300px] mb-2.5'></div>
                <div className='h-2 bg-borderGray rounded-full  max-w-[360px]'></div>
                <span className='sr-only'>Loading...</span>
            </div>
            <div role='status' className='w-auto animate-pulse'>
                <div className='h-2.5 bg-borderGray rounded-full  w-48 mb-4'></div>
                <div className='h-2 bg-borderGray rounded-full  max-w-[360px] mb-2.5'></div>
                <div className='h-2 bg-borderGray rounded-full  mb-2.5'></div>
                <div className='h-2 bg-borderGray rounded-full  max-w-[330px] mb-2.5'></div>
                <div className='h-2 bg-borderGray rounded-full  max-w-[300px] mb-2.5'></div>
                <div className='h-2 bg-borderGray rounded-full  max-w-[360px]'></div>
                <span className='sr-only'>Loading...</span>
            </div>
        </>
    )
}

export default LoanSkeleton
