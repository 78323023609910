import { FC, useState } from 'react'
import { PageWrapLayout } from 'layout/components'
import { getDate } from 'utils/date'
import Table from 'pages/loans/components/Table'
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline'
import Pagination from 'layout/components/pagination/Pagination'
import TableSkeleton from 'pages/loans/components/TableSkeleton'
import { Document } from 'api/documents/types'
import { useGetContractsListQuery } from 'api/services/customer/serviceContracts'
import { EContractsType } from 'api/contracts/types'

const Documents: FC = () => {
    const contractsQuery = useGetContractsListQuery(EContractsType.Contracts)
    const contractsPersonalQuery = useGetContractsListQuery(EContractsType.Personal)
    const [list, setList] = useState<Document[] | []>([])
    const cells = ['NÁZEV', 'DATUM AKCE', 'PDF']

    if (!contractsQuery?.data || !contractsPersonalQuery?.data) {
        return <TableSkeleton />
    }

    const contracts = contractsQuery?.data.results.concat(contractsPersonalQuery?.data.results)

    return (
        <PageWrapLayout title='Osobní dokumenty'>
            <Table noStyle>
                <thead className={'w-full'}>
                    <tr className={''}>
                        {cells.map((cell, index) => (
                            <th
                                key={cell}
                                scope='col'
                                className={`py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 ${
                                    index === cells.length - 1 && 'text-right right-3 relative'
                                } ${index > 0 && index !== cells.length - 1 && 'text-center'} `}>
                                {cell}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className='divide-y divide-gray-200 bg-white'>
                    {list.map((document) => (
                        <tr key={document.id} className=''>
                            <td className='whitespace-nowrap p-4 text-sm font-medium text-gray-900 sm:pl-6'>
                                {document.type_text}
                            </td>
                            <td className='whitespace-nowrap p-4 text-sm text-center text-gray-500'>
                                {getDate(document.created)}
                            </td>
                            <td className='whitespace-nowrap p-4 text-sm text-right text-gray-500'>
                                <a
                                    rel='noreferrer'
                                    href={document.url}
                                    target='_blank'
                                    className='flex justify-end text-lightPurple hover:text-lightBlue'>
                                    <DocumentArrowDownIcon className='w-5 h-5 mr-1' />
                                    <span>PDF</span>
                                </a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Pagination
                setList={setList}
                perPage={7}
                itemsList={contracts}
                count={contractsQuery?.data.count}
            />
        </PageWrapLayout>
    )
}

export default Documents
