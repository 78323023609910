import { FC } from 'react'
import { PaperClipIcon } from '@heroicons/react/24/solid'
import { BadgePapers } from '@vistacredit/lib-ui/src/components/badges/BadgePapers'
import ProfileUploadDocuments from 'pages/profile/components/profileUploadDocuments/ProfileUploadDocuments'
import { getExpirationText } from 'utils'
import { ICustomerExpiration, ICustomerPapers, TCustomer } from 'api/services/customer/types'
import { useGetActiveLoanQuery } from 'api/services/loan/serviceLoan'
import { LoanStatus } from 'utils/loanStatus'

interface IProfileUploadedDocumentsProps {
    data: ICustomerPapers[]
    customer: TCustomer
    expiration?: ICustomerExpiration
    onOpen: () => void
}

const ProfileUploadedDocuments: FC<IProfileUploadedDocumentsProps> = ({
    data,
    customer,
    expiration,
    onOpen,
}) => {
    const activeLoanQuery = useGetActiveLoanQuery()

    return (
        <section aria-labelledby='applicant-information-title'>
            <div className='bg-white border border-borderGray rounded-lg'>
                <dl className='px-4 py-5 sm:px-6'>
                    <div className='flex flex-wrap justify-between'>
                        <h2
                            id='applicant-information-title'
                            className='text-lg font-medium leading-6 text-gray-900'>
                            Osobní doklady
                        </h2>
                        {customer.papers_uploaded &&
                            customer.papers_checked &&
                            expiration?.expiration_date && (
                                <div className='mt-3 sm:mt-0 text-textGreen'>
                                    <BadgePapers
                                        type={'status-greenLight'}
                                        text={getExpirationText(
                                            customer,
                                            expiration.expiration_date,
                                        )}
                                    />
                                </div>
                            )}

                        {customer.papers_uploaded && !customer.papers_checked && (
                            <div className='mt-3'>
                                <BadgePapers
                                    type={'status_orangeLight'}
                                    text={
                                        <span>
                                            Děkujeme za vložení dokladů, jakmile budou
                                            administrátorem schváleny, budeme pokračovat v posouzení
                                            žádosti. Pokud nemáte žádost odeslanou, budete moci ji
                                            odeslat po schválení dokladů administrátorem.
                                        </span>
                                    }
                                />
                            </div>
                        )}
                    </div>
                    {data.length > 0 && (
                        <div className='mt-6 flow-root'>
                            <dl className='grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2'>
                                <div className='col-span-2 sm:col-span-2'>
                                    <h3
                                        id='applicant-information-title'
                                        className='text-base font-medium mb-3 leading-6 text-gray-900'>
                                        Občanský průkaz
                                    </h3>
                                    <dd className='mt-1 text-sm text-gray-900'>
                                        <ul
                                            role='list'
                                            className='divide-y divide-gray-200 rounded-md border border-gray-200'>
                                            {data
                                                .filter((paper) => paper.type === 0)
                                                .map((paper) => (
                                                    <li
                                                        key={paper.id}
                                                        className='flex sm:items-center flex-wrap justify-start flex-col sm:flex-row sm:justify-between py-3 pl-3 pr-4 text-sm'>
                                                        <div className='flex w-0 flex-1 items-center'>
                                                            <PaperClipIcon
                                                                className='h-5 w-5 flex-shrink-0 text-gray-400'
                                                                aria-hidden='true'
                                                            />
                                                            <a
                                                                rel='noopener noreferrer'
                                                                href={paper.file}
                                                                target='_blank'
                                                                className='font-medium text-blue-600 hover:text-blue-500'>
                                                                <span className='ml-2 w-0 flex-1 truncate'>
                                                                    {paper.name}
                                                                </span>
                                                            </a>
                                                        </div>
                                                        <div className=' mt-2 sm:mt-0 flex-shrink-0 py-2'>
                                                            <BadgePapers
                                                                type={
                                                                    paper.status === 'SUCCESS'
                                                                        ? 'status-greenLight'
                                                                        : 'status_orangeLight'
                                                                }
                                                                text={
                                                                    paper.status === 'SUCCESS'
                                                                        ? 'Potvrzeno operátorem'
                                                                        : 'Čeká na ověření'
                                                                }
                                                            />
                                                        </div>
                                                    </li>
                                                ))}
                                        </ul>
                                    </dd>
                                </div>
                                <div className='col-span-2 sm:col-span-2 mt-2'>
                                    <h3
                                        id='applicant-information-title'
                                        className='text-base font-medium mb-3 leading-6 text-gray-900'>
                                        Výpis z bankovního účtu
                                    </h3>
                                    <dd className='mt-1 text-sm text-gray-900'>
                                        <ul
                                            role='list'
                                            className='divide-y divide-gray-200 rounded-md border border-gray-200'>
                                            {data
                                                .filter((paper) => paper.type === 1)
                                                .map((paper) => (
                                                    <li
                                                        key={paper.id}
                                                        className='flex sm:items-center flex-wrap justify-start flex-col sm:flex-row sm:justify-between py-3 pl-3 pr-4 text-sm'>
                                                        <div className='flex w-0 flex-1 items-center'>
                                                            <PaperClipIcon
                                                                className='h-5 w-5 flex-shrink-0 text-gray-400'
                                                                aria-hidden='true'
                                                            />
                                                            <a
                                                                rel='noopener noreferrer'
                                                                href={paper.file}
                                                                target='_blank'
                                                                className='font-medium text-blue-600 hover:text-blue-500'>
                                                                <span className='ml-2 w-0 flex-1 truncate'>
                                                                    {paper.name}
                                                                </span>
                                                            </a>
                                                        </div>
                                                        <div className='flex-shrink-0 mt-2 sm:mt-0 py-2'>
                                                            <BadgePapers
                                                                type={
                                                                    paper.status === 'SUCCESS'
                                                                        ? 'status-greenLight'
                                                                        : 'status_orangeLight'
                                                                }
                                                                text={
                                                                    paper.status === 'SUCCESS'
                                                                        ? 'Potvrzeno operátorem'
                                                                        : 'Čeká na ověření'
                                                                }
                                                            />
                                                        </div>
                                                    </li>
                                                ))}
                                        </ul>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    )}

                    {customer.can_upload_papers &&
                        ((activeLoanQuery.data?.status &&
                            [LoanStatus.PreApproved, LoanStatus.Processed, LoanStatus.New].includes(
                                activeLoanQuery.data?.status,
                            )) ||
                            !activeLoanQuery.data?.id) && (
                            <ProfileUploadDocuments onOpen={onOpen} customerPapers={data} />
                        )}
                </dl>
            </div>
        </section>
    )
}

export default ProfileUploadedDocuments
