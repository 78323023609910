import { FC, Fragment } from 'react'
import { PageWrapLayout } from 'layout/components'
import { CheckIcon, MinusIcon } from '@heroicons/react/24/outline'

const tiers = [
    {
        name: 'Start',
        color: 'text-lightBlue',
        description: 'začínající klient, který si bude u Visty budovat historii a důvěru',
    },
    {
        name: 'Plus',
        color: 'text-status-orange',
        description: 'už se trochu známe, ale ještě si dáme trochu času',
    },
    {
        name: 'Business',
        color: 'text-lightPurple',
        description: 'velice si Vás vážíme a nabízíme Vám maximum našich služeb',
    },
]

const sections = [
    {
        name: 'Výše, poplatek a splatnost',
        features: [
            {
                name: 'Nabízená výše úvěru',
                tiers: {
                    Start: 'až 4.000 Kč',
                    Plus: 'až 6.000 Kč',
                    Business: 'až 8.000 Kč',
                },
            },
            {
                name: 'Poplatek za úvěr',
                tiers: {
                    Start: 'dle kalkulátoru',
                    Plus: 'dle kalkulátoru',
                    Business: 'dle kalkulátoru',
                },
            },
            {
                name: 'Úvěr ZDARMA i opakovaně',
                tiers: {
                    Start: '3.000 Kč',
                    Plus: '3.000 Kč',
                    Business: '3.000 Kč',
                },
            },
            {
                name: 'Min / max doba splatnosti',
                tiers: {
                    Start: '7 / 70 dnů',
                    Plus: '7 / 70 dnů',
                    Business: '7 / 70 dnů',
                },
            },
            {
                name: 'Úhrada před splatnosti ZDARMA',
                tiers: {
                    Start: true,
                    Plus: true,
                    Business: true,
                },
            },
            {
                name: 'Odložení splatnosti',
                tiers: {
                    Start: true,
                    Plus: true,
                    Business: true,
                },
            },
            {
                name: 'Min / max doba odložení',
                tiers: {
                    Start: '7 / 35 dnů',
                    Plus: '7 / 35 dnů',
                    Business: '7 / 35 dnů',
                },
            },
        ],
    },
    {
        name: 'Body',
        features: [
            {
                name: 'Získání bodů za splacení',
                tiers: {
                    Start: true,
                    Plus: true,
                    Business: true,
                },
            },
            {
                name: 'Čerpání bodů',
                tiers: {
                    Start: false,
                    Plus: false,
                    Business: true,
                },
            },
        ],
    },
    {
        name: 'Služby navíc',
        features: [
            {
                name: 'Automatické schvalování',
                tiers: {
                    Start: false,
                    Plus: false,
                    Business: true,
                },
            },
            {
                name: 'Aktivace služby RENT',
                tiers: {
                    Start: false,
                    Plus: false,
                    Business: true,
                },
            },
            {
                name: 'Individuální úvěr',
                tiers: {
                    Start: 'až 6.000 Kč',
                    Plus: 'až 8.000 Kč',
                    Business: 'až 25.000 Kč',
                },
            },
        ],
    },
    {
        name: 'Vista RENT - pronájem hotovosti',
        features: [
            {
                name: 'Nabízená výše úvěru',
                tiers: {
                    Start: false,
                    Plus: false,
                    Business: 'až 8.000 Kč',
                },
            },
            {
                name: 'Poplatek za úvěr',
                tiers: {
                    Start: false,
                    Plus: false,
                    Business: false,
                },
            },
            {
                name: 'Min / max doba splatnosti',
                tiers: {
                    Start: false,
                    Plus: false,
                    Business: '7 / 70 dnů',
                },
            },
            {
                name: 'Doba pronájmu ZDARMA',
                tiers: {
                    Start: false,
                    Plus: false,
                    Business: '14 dnů o všech výší úvěru',
                },
            },
            {
                name: 'Maximálni doba pronájmu',
                tiers: {
                    Start: false,
                    Plus: false,
                    Business: 'neomezeně',
                },
            },
            {
                name: 'Získání bodů za splacení',
                tiers: {
                    Start: false,
                    Plus: false,
                    Business: false,
                },
            },
            {
                name: 'Čerpání bodů',
                tiers: {
                    Start: false,
                    Plus: false,
                    Business: false,
                },
            },
            {
                name: 'Automatické schvalování',
                tiers: {
                    Start: false,
                    Plus: false,
                    Business: true,
                },
            },
            {
                name: 'Individuál úvěr',
                tiers: {
                    Start: false,
                    Plus: false,
                    Business: 'až 25.000 Kč',
                },
            },
            {
                name: 'Cena aktivace služby',
                tiers: {
                    Start: false,
                    Plus: false,
                    Business: '1.000 Kč / rok',
                },
            },
        ],
    },
]

const Pricing: FC = () => {
    return (
        <PageWrapLayout>
            <div className='mx-auto max-w-2xl bg-white py-16 sm:py-12 sm:px-6 lg:max-w-7xl lg:px-8 rounded-lg'>
                {/* xs to lg */}
                <div className='space-y-24 lg:hidden'>
                    {tiers.map((tier) => (
                        <section key={tier.name}>
                            <div className='mb-8 px-6 sm:px-4'>
                                <h2
                                    className={`text-lg font-medium leading-6 uppercase ${
                                        tier.color ?? 'text-gray-900'
                                    }`}>
                                    {tier.name}
                                </h2>
                                <p className='mt-4 text-sm text-gray-500'>{tier.description}</p>
                            </div>

                            {sections.map((section) => (
                                <table key={section.name} className='w-full'>
                                    <caption className='border-t border-gray-200 bg-gray-50 py-3 px-6 text-left text-sm font-medium text-gray-900 sm:px-4'>
                                        {section.name}
                                    </caption>
                                    {/*<thead>*/}
                                    {/*<tr>*/}
                                    {/*    <th className='sr-only' scope='col'>*/}
                                    {/*        Feature a*/}
                                    {/*    </th>*/}
                                    {/*    <th className='sr-only' scope='col'>*/}
                                    {/*        Included*/}
                                    {/*    </th>*/}
                                    {/*</tr>*/}
                                    {/*</thead>*/}
                                    <tbody className='divide-y divide-gray-200'>
                                        {section.features.map((feature: any) => (
                                            <tr
                                                key={feature.name}
                                                className='border-t border-gray-200'>
                                                <th
                                                    className='py-5 px-6 text-left text-sm font-normal text-gray-500 sm:px-4'
                                                    scope='row'>
                                                    {feature.name}
                                                </th>
                                                <td className='py-5 pr-6 sm:pr-4'>
                                                    {typeof feature.tiers[tier.name] ===
                                                    'string' ? (
                                                        <span className='block text-right text-sm text-gray-700'>
                                                            {feature.tiers[tier.name]}
                                                        </span>
                                                    ) : (
                                                        <>
                                                            {feature.tiers[tier.name] === true ? (
                                                                <CheckIcon
                                                                    className='ml-auto h-5 w-5 text-green-500'
                                                                    aria-hidden='true'
                                                                />
                                                            ) : (
                                                                <MinusIcon
                                                                    className='ml-auto h-5 w-5 text-gray-400'
                                                                    aria-hidden='true'
                                                                />
                                                            )}
                                                            <span className='sr-only'>
                                                                {feature.tiers[tier.name] === true
                                                                    ? 'Yes'
                                                                    : 'No'}
                                                            </span>
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ))}
                        </section>
                    ))}
                </div>

                {/* lg+ */}
                <div className='hidden lg:block'>
                    <table className='h-px w-full table-fixed'>
                        {/*<caption className='sr-only'>Pricing plan comparison</caption>*/}
                        <thead>
                            <tr>
                                <th
                                    className='pb-4 pl-6 pr-6 text-left text-sm font-medium text-gray-900'
                                    scope='col'>
                                    {/*<span className='sr-only'>Feature by</span>*/}
                                    <span>Vista FIX</span>
                                </th>
                                {tiers.map((tier) => (
                                    <th
                                        key={tier.name}
                                        className={`w-1/4 px-6 pb-4 text-left text-lg font-medium leading-6 uppercase ${
                                            tier.color ?? 'text-gray-900'
                                        }`}
                                        scope='col'>
                                        {tier.name}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className='divide-y divide-gray-200 border-t border-gray-200'>
                            <tr>
                                <th
                                    className='py-6 pl-6 pr-6 text-left align-top text-sm font-medium text-gray-900'
                                    scope='row'>
                                    Běžný mikro-úvěr za jasně dané podmínky a fixní poplatky.
                                </th>
                                {tiers.map((tier) => (
                                    <td key={tier.name} className='h-full py-8 pt-1 px-6 align-top'>
                                        <div className='flex h-full flex-col justify-between'>
                                            <div>
                                                <p className='mt-4 text-sm text-gray-500'>
                                                    {tier.description}
                                                </p>
                                            </div>
                                        </div>
                                    </td>
                                ))}
                            </tr>
                            {sections.map((section) => (
                                <Fragment key={section.name}>
                                    <tr>
                                        <th
                                            className='bg-gray-50 py-3 pl-6 text-left text-sm font-medium text-gray-900'
                                            colSpan={4}
                                            scope='colgroup'>
                                            {section.name}
                                        </th>
                                    </tr>
                                    {section.features.map((feature: any) => (
                                        <tr key={feature.name}>
                                            <th
                                                className='py-5 pl-6 pr-6 text-left text-sm font-normal text-gray-500'
                                                scope='row'>
                                                {feature.name}
                                            </th>
                                            {tiers.map((tier) => (
                                                <td
                                                    key={tier.name}
                                                    className='py-5 px-6 text-center'>
                                                    {typeof feature.tiers[tier.name] ===
                                                    'string' ? (
                                                        <span className='block text-sm text-gray-700'>
                                                            {feature.tiers[tier.name]}
                                                        </span>
                                                    ) : (
                                                        <>
                                                            {feature.tiers[tier.name] === true ? (
                                                                <CheckIcon
                                                                    className='h-5 w-5 text-green-500 mx-auto'
                                                                    aria-hidden='true'
                                                                />
                                                            ) : (
                                                                <MinusIcon
                                                                    className='h-5 w-5 text-gray-400 mx-auto'
                                                                    aria-hidden='true'
                                                                />
                                                            )}

                                                            <span className='sr-only'>
                                                                {feature.tiers[tier.name] === true
                                                                    ? 'Included'
                                                                    : 'Not included'}{' '}
                                                                in {tier.name}
                                                            </span>
                                                        </>
                                                    )}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </PageWrapLayout>
    )
}

export default Pricing
