import React, { FC } from 'react'

const QuestionSeventeen: FC = () => {
    return (
        <div className='space-y-4'>
            <p>Nevadí ! Stává se to běžně !</p>

            <p>
                Administrátoři úvěrů jsou vyškoleni k tomu, aby dohledali plátce částky a spárovat
                platbu ke správnému úvěru. Pro jistotu nám můžete napsat na{' '}
                <a href='mailto:admin@vistacredit.cz' className='underline text-lightBlue'>
                    admin@vistacredit.cz
                </a>{' '}
                a ujistit se, že platba byla připsána jak měla.
            </p>
            <p>
                Můžete nám také poslat dotaz přes <b>Kontaktní formulář</b> výběrem volby :{' '}
                <b>Vlastní dotaz, námět, prosba, pochvala, kritika</b>.
            </p>

            {/*<img src={Question1_2} className='max-w-xl my-3' alt='question3 image' />*/}
        </div>
    )
}

export default QuestionSeventeen
