import { FC } from 'react'

import { classNames } from 'utils'

interface INavigationProps {
    tabs: any
    setTab: (id: number) => void
}

const Navigation: FC<INavigationProps> = ({ tabs, setTab }) => {
    return (
        <div className='sm:mb-10'>
            <div className='sm:hidden mb-6'>
                <label htmlFor='tabs' className='sr-only'>
                    Menu
                </label>
                {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                <select
                    id='tabs'
                    name='tabs'
                    className='block w-full rounded-md bg-gray-100 border border-gray-200 p-2.5 focus:border-lightPurple focus:ring-lightPurple'
                    defaultValue={tabs.find((tab: any) => tab.current)?.name}
                    onChange={(event: any) => setTab(event.target.value)}>
                    {tabs.map((tab: any) => (
                        <option key={tab.name} value={tab.id}>
                            {tab.name}
                        </option>
                    ))}
                </select>
            </div>
            <div className='hidden sm:block'>
                <div className='border-b border-gray-200'>
                    <nav className='-mb-px flex justify-center space-x-8' aria-label='Tabs'>
                        {tabs.map((tab: any) => (
                            <div
                                key={tab.name}
                                onClick={() => setTab(tab.id)}
                                className={classNames(
                                    tab.current
                                        ? 'border-indigo-500 text-indigo-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                    'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm cursor-pointer',
                                )}
                                aria-current={tab.current ? 'page' : undefined}>
                                <tab.icon
                                    className={classNames(
                                        tab.current
                                            ? 'text-indigo-500'
                                            : 'text-gray-400 group-hover:text-gray-500',
                                        '-ml-0.5 mr-2 h-5 w-5',
                                    )}
                                    aria-hidden='true'
                                />
                                <span>{tab.name}</span>
                            </div>
                        ))}
                    </nav>
                </div>
            </div>
        </div>
    )
}

export default Navigation
