import React, { useEffect } from 'react'
import { Slider } from '@mui/material'
import './style/index.scss'
import { roundSliderValue } from 'layout/components/slider/utils/roundSliderValue'
import { checkInaccessibleDaysRange } from 'layout/components/slider/utils/checkInaccessibleDaysRange'

interface CalculationSliderProps {
    min: number
    max: number
    days?: boolean
    type?: 'fix' | 'rent' | 'deferral'
    marks: Array<any>
    defaultValue: number | number[]
    setSliderValue: React.Dispatch<React.SetStateAction<number | number[]>>
}

const CalculationSlider = (props: CalculationSliderProps) => {
    const [value, setValue] = React.useState<number | number[]>(props.defaultValue)

    const handleSliderChange = (event: any, newValue: number | number[]) => {
        const roundedValue = roundSliderValue(+newValue, props.type !== undefined)

        if (checkInaccessibleDaysRange(+newValue, props.days, props.type)) {
            setValue(newValue)
            return
        }

        setValue(newValue)
        props.setSliderValue(roundedValue)
    }

    useEffect(() => {
        setValue(props.defaultValue)
    }, [props.defaultValue])

    return (
        <div id={'SliderRoot'} className='sm:p-10'>
            <Slider
                value={typeof value === 'number' ? value : 0}
                onChange={handleSliderChange}
                min={props.min}
                step={1}
                max={props.max}
                defaultValue={props.defaultValue}
                aria-labelledby='input-slider'
                marks={props.marks}
            />

            <div className='flex sm:mt-2 justify-between w-full text-base '>
                <div>{props.min}</div>
                <div>{props.max}</div>
            </div>
        </div>
    )
}

export default CalculationSlider
