import { serviceApi } from 'api/serviceApi'
import { ICreateLoanData, ILoansList, TLoanDetail, TLoanResponse } from 'api/services/loan/types'

const loanApi = serviceApi.injectEndpoints({
    endpoints: (build) => ({
        getLoansList: build.query<ILoansList, void>({
            query: () => 'loans/',
            providesTags: ['LoanList'],
        }),
        getActiveLoan: build.query<TLoanResponse, void>({
            query: () => 'loans/active/',
            providesTags: ['Loan'],
        }),
        getLoanDetail: build.mutation<TLoanDetail, number>({
            query: (id) => `loans/${id}/`,
        }),
        createLoan: build.mutation<TLoanResponse, ICreateLoanData>({
            query: (payload) => {
                return {
                    url: 'loans/',
                    method: 'POST',
                    body: payload,
                }
            },
            invalidatesTags: ['Loan', 'LoanList'],
        }),
        sendPaymentInfo: build.mutation<any, number>({
            query: (id) => {
                return {
                    url: `loans/${id}/send_payment_info/`,
                    method: 'POST',
                }
            },
        }),
    }),
    overrideExisting: false,
})

export const {
    useCreateLoanMutation,
    useGetActiveLoanQuery,
    useGetLoanDetailMutation,
    useGetLoansListQuery,
    useSendPaymentInfoMutation,
} = loanApi
