import React, { useState } from 'react'
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/solid'
import { PageWrapLayout } from 'layout/components'
import { Pattern } from 'assets/pattern/Pattern'
import { useForm } from 'react-hook-form'
import Select from 'pages/contact/components/Select'
import {
    ArrowTrendingDownIcon,
    BugAntIcon,
    BuildingOffice2Icon,
    ChatBubbleBottomCenterIcon,
    ClockIcon,
    FaceFrownIcon,
    GiftIcon,
    IdentificationIcon,
    LightBulbIcon,
    MagnifyingGlassIcon,
    PhoneArrowDownLeftIcon,
} from '@heroicons/react/24/outline'
import MessagePreview from 'pages/contact/utils/MessagePreview'
import { ButtonCommon } from '@vistacredit/lib-ui'
import { useCustomersApi } from 'api'
import { setNotification } from 'store/stateReducer'
import { useDispatch } from 'react-redux'

const questions = [
    { id: 1, name: 'Změna adresy', icon: BuildingOffice2Icon },
    { id: 2, name: 'Dotaz na posouzení žádosti', icon: MagnifyingGlassIcon },
    { id: 3, name: 'Změna osobního dokladu', icon: IdentificationIcon },
    { id: 4, name: 'Mám od vás nepřijatý hovor', icon: PhoneArrowDownLeftIcon },
    { id: 5, name: 'Potřebuji prodloužit dobu splatnosti', icon: ClockIcon },
    { id: 6, name: 'Dostal jsem se do velkých problémů', icon: ArrowTrendingDownIcon },
    { id: 7, name: 'Požádal jsem o insolvenci', icon: FaceFrownIcon },
    { id: 8, name: 'Našel jsem chybu', icon: BugAntIcon },
    { id: 9, name: 'Chtěl bych vás pochválit', icon: GiftIcon },
    { id: 10, name: 'Mám nápad - návrh na zlepšeí', icon: LightBulbIcon },
    {
        id: 11,
        name: 'Vlastní dotaz, prosba, námět, pochvala, kritika...',
        icon: ChatBubbleBottomCenterIcon,
    },
]

const Contact = () => {
    const [selected, setSelected] = useState(questions[0])
    const { sendEmail } = useCustomersApi()
    const dispatch = useDispatch()
    const form = useForm()

    const concatMessage = (data: any) => {
        if (data?.phone?.length > 0) {
            return `${data.message} ${data.phone}`
        } else if (data?.city?.length > 0) {
            return `${data.message} ${data.street} ${data.houseNumber}, ${data.city} ${data.postalCode}`
        } else {
            return data.message
        }
    }

    const onSubmit = async (data: any) => {
        await sendEmail(concatMessage(data))
            .then((res) => {
                dispatch(
                    setNotification({
                        type: 'success',
                        title: 'Odesláno',
                        message:
                            'Děkujeme, vaši zprávu jsme přijali a za malý okamžik se ji budeme věnovat.',
                    }),
                )
                form.reset()
            })
            .catch((err) => {
                dispatch(
                    setNotification({
                        type: 'error',
                        title: 'Odesláni zlyhalo...',
                        message:
                            'Omlouváme se, ale někde nastal problém a vaše zpráva nebyla odeslána',
                    }),
                )
            })
    }

    return (
        <PageWrapLayout>
            <div className='relative bg-white shadow-xl rounded-xl mt-6'>
                <h2 className='sr-only'>Kontaktujte nás</h2>

                <div className='grid grid-cols-1 lg:grid-cols-3'>
                    {/* Contact information */}
                    <div className='relative overflow-hidden bg-gradient-to-r from-lightBlue to-darkPurple py-10 px-6 sm:px-10 xl:p-12 rounded-xl'>
                        <Pattern
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                height: '100%',
                                width: '100%',
                                zIndex: 0,
                            }}
                        />
                        <div
                            className='pointer-events-none absolute inset-0 sm:hidden'
                            aria-hidden='true'>
                            <svg
                                className='absolute inset-0 h-full w-full'
                                width={343}
                                height={388}
                                viewBox='0 0 343 388'
                                fill='none'
                                preserveAspectRatio='xMidYMid slice'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    d='M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z'
                                    fill='url(#linear1)'
                                    fillOpacity='.1'
                                />
                                <defs>
                                    <linearGradient
                                        id='linear1'
                                        x1='254.553'
                                        y1='107.554'
                                        x2='961.66'
                                        y2='814.66'
                                        gradientUnits='userSpaceOnUse'>
                                        <stop stopColor='#fff' />
                                        <stop offset={1} stopColor='#fff' stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                        <div
                            className='pointer-events-none absolute top-0 right-0 bottom-0 hidden w-1/2 sm:block lg:hidden'
                            aria-hidden='true'>
                            <svg
                                className='absolute inset-0 h-full w-full'
                                width={359}
                                height={339}
                                viewBox='0 0 359 339'
                                fill='none'
                                preserveAspectRatio='xMidYMid slice'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    d='M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z'
                                    fill='url(#linear2)'
                                    fillOpacity='.1'
                                />
                                <defs>
                                    <linearGradient
                                        id='linear2'
                                        x1='192.553'
                                        y1='28.553'
                                        x2='899.66'
                                        y2='735.66'
                                        gradientUnits='userSpaceOnUse'>
                                        <stop stopColor='#fff' />
                                        <stop offset={1} stopColor='#fff' stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                        <div
                            className='pointer-events-none absolute top-0 right-0 bottom-0 hidden w-1/2 lg:block'
                            aria-hidden='true'>
                            <svg
                                className='absolute inset-0 h-full w-full'
                                width={160}
                                height={678}
                                viewBox='0 0 160 678'
                                fill='none'
                                preserveAspectRatio='xMidYMid slice'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    d='M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z'
                                    fill='url(#linear3)'
                                    fillOpacity='.1'
                                />
                                <defs>
                                    <linearGradient
                                        id='linear3'
                                        x1='192.553'
                                        y1='325.553'
                                        x2='899.66'
                                        y2='1032.66'
                                        gradientUnits='userSpaceOnUse'>
                                        <stop stopColor='#fff' />
                                        <stop offset={1} stopColor='#fff' stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>

                        <h3 className='text-lg font-medium text-white relative z-10'>
                            Kontaktní informace
                        </h3>
                        <p className='mt-6 max-w-3xl text-base text-white relative z-10'>
                            VistaCredit s.r.o. <br /> Žitná 556/13d, Prostějov <br /> IČO: 041 14
                            060
                        </p>
                        <dl className='mt-8 space-y-6 relative z-10'>
                            <dt>
                                <span className='sr-only'>Phone number</span>
                            </dt>
                            <dd className='flex text-base text-white hover:text-indigo-100'>
                                <PhoneIcon className='h-6 w-6 flex-shrink-0' aria-hidden='true' />
                                <a href='tel:+420587407707' className='ml-3'>
                                    +420 587 407 707
                                </a>
                            </dd>
                            <dt>
                                <span className='sr-only'>Email</span>
                            </dt>
                            <dd className='flex text-base text-white hover:text-indigo-100'>
                                <EnvelopeIcon
                                    className='h-6 w-6 flex-shrink-0'
                                    aria-hidden='true'
                                />
                                <a href='mailto:theo@vistacredit.cz' className='ml-3'>
                                    theo@vistacredit.cz
                                </a>
                            </dd>
                        </dl>
                        <ul role='list' className='mt-8 flex space-x-12 relative z-10'>
                            <li>
                                <a
                                    className='text-white hover:text-indigo-100 flex'
                                    href='https://www.facebook.com/VistaCredit.cz'
                                    target='_blank'
                                    rel='noreferrer'>
                                    <svg
                                        width={24}
                                        height={24}
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                        className='h-6 w-6'
                                        aria-hidden='true'>
                                        <path
                                            d='M22.258 1H2.242C1.556 1 1 1.556 1 2.242v20.016c0 .686.556 1.242 1.242 1.242h10.776v-8.713h-2.932V11.39h2.932V8.887c0-2.906 1.775-4.489 4.367-4.489 1.242 0 2.31.093 2.62.134v3.037l-1.797.001c-1.41 0-1.683.67-1.683 1.653v2.168h3.362l-.438 3.396h-2.924V23.5h5.733c.686 0 1.242-.556 1.242-1.242V2.242C23.5 1.556 22.944 1 22.258 1'
                                            fill='currentColor'
                                        />
                                    </svg>
                                    <span className='ml-3'>Facebook</span>
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* Contact form */}
                    <div className='py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12'>
                        <h3 className='text-lg font-medium text-gray-900'>Pošlete nám dotaz</h3>
                        <form
                            onSubmit={form.handleSubmit(onSubmit)}
                            className='mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8'>
                            <div className='sm:col-span-2'>
                                <Select
                                    selected={selected}
                                    setSelected={setSelected}
                                    questions={questions}
                                    reset={form.reset}
                                />
                            </div>
                            <div className='sm:col-span-2'>
                                <MessagePreview id={selected.id} register={form.register} />
                            </div>

                            <div className='sm:col-span-2 sm:flex sm:justify-end'>
                                <ButtonCommon
                                    type='submit'
                                    className='mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-lightPurple px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'>
                                    Odeslat
                                </ButtonCommon>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </PageWrapLayout>
    )
}
export default Contact
