import { FC } from 'react'
import ModalTitle from 'layout/components/modal/shared/ModalTitle'
import ModalWrapper from 'layout/components/modal/ModalWrapper'
import ModalFooter from 'layout/components/modal/shared/ModalFooter'
import { usePaymentsApi } from 'api/payments/usePaymentsApi'
import { useDispatch } from 'react-redux'
import { setNotification } from 'store/stateReducer'
import { CheckIcon } from '@heroicons/react/24/outline'

interface IRentModalProps {
    open: boolean
    setOpen: (value: boolean) => void
    onClose: () => void
}

const RentModal: FC<IRentModalProps> = ({ open, setOpen, onClose }) => {
    const { paymentsRent } = usePaymentsApi()
    const dispatch = useDispatch()

    const tiers = [
        {
            name: 'VistaRENT',
            id: 'tier-hobby',
            href: '#',
            priceMonthly: '1 000 Kč',
            description:
                'Klikněte na tlačítko AKTIVOVAT a pomocí platební brány uhraďte cenu služby 1000 Kč. Služba se aktivuje na dobu 1 roku. Po uplynutí doby je možné službu reaktivovat',
            features: [
                'až 8.000 Kč s poplatkem za poskytnutí 0 Kč',
                'kredit na neomezeně dlouhou dobu',
                'platíte pouze za dobu užívání',
            ],
        },
    ]

    const handleActivation = async () => {
        await paymentsRent()
            .then((res) => {
                window.location.href = res.url

                setTimeout(() => {
                    onClose()
                }, 3000)
                // dispatch(
                //     setNotification({
                //         type: 'success',
                //         title: 'Hurááá',
                //         message: 'Služba RENT byla aktivována',
                //     }),
                // )
                // getCustomer.refetch()
            })
            .catch((err) => {
                dispatch(
                    setNotification({
                        type: 'error',
                        title: 'Někde se stala chyba!',
                        message: 'Službu RENT se nepodařilo aktivovat',
                    }),
                )
                onClose()
            })
    }

    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <ModalTitle title={'Aktivace služby'} />

            <div className='mx-auto'>
                {tiers.map((tier) => (
                    <div key={tier.id} className='flex flex-col justify-between sm:p-6 sm:pt-4'>
                        <div>
                            {/*<h3*/}
                            {/*    id={tier.id}*/}
                            {/*    className='text-base font-semibold font-Quicksand leading-7 text-lightPurple'>*/}
                            {/*    {tier.name}*/}
                            {/*</h3>*/}
                            <h3 className='text-base font-semibold leading-7 text-lightPurple'>
                                Co aktivací získáte?
                            </h3>
                            <ul
                                role='list'
                                className='mt-3 space-y-4 text-sm leading-6 text-gray-600'>
                                {tier.features.map((feature) => (
                                    <li key={feature} className='flex gap-x-3'>
                                        <CheckIcon
                                            className='h-6 w-5 flex-none text-lightPurple'
                                            aria-hidden='true'
                                        />
                                        {feature}
                                    </li>
                                ))}
                            </ul>
                            {/*<div className='mt-6 flex justify-center sm:justify-start items-baseline gap-x-2 '>*/}
                            {/*    <span className='text-5xl font-bold tracking-tight font-Quicksand text-gray-900 '>*/}
                            {/*        {tier.priceMonthly}*/}
                            {/*    </span>*/}
                            {/*    <span className='text-base font-semibold leading-7 font-Quicksand text-gray-600'>*/}
                            {/*        /rok*/}
                            {/*    </span>*/}
                            {/*</div>*/}
                            <h3 className='mt-6 mb-2 text-base font-semibold leading-7 text-lightPurple'>
                                Jak službu aktivovat?
                            </h3>
                            <p className='text-base leading-7 text-gray-600'>{tier.description}</p>
                            <p className='text-sm text-gray-600 mt-2 mb-0'>
                                * ke každému úvěru bude vždy přičten administrativní náklad na
                                splacení úvěru 150 Kč.
                            </p>
                        </div>
                    </div>
                ))}
            </div>

            <ModalFooter onClose={onClose} onClick={handleActivation} loading title={'Aktivovat'} />
        </ModalWrapper>
    )
}

export default RentModal
