import React, { FC } from 'react'
import { useAppSelector } from 'store/store'
import { Pattern } from 'assets/pattern/Pattern'
import UnloggedLayout from 'layout/UnloggedLayout'
import LoginFormWrap from 'pages/login/components/LoginFormWrap'
import Notification from 'layout/components/notifications/Notification'
import { ButtonCommon, InputWithIcon } from '@vistacredit/lib-ui'
import { EnvelopeIcon } from '@heroicons/react/24/outline'
import { useForm } from 'react-hook-form'
import { useResetPasswordMutation } from 'api/services/auth/serviceAuth'
import { useDispatch } from 'react-redux'
import { setNotification } from 'store/stateReducer'
import { useNavigate } from 'react-router'

const ForgotPassword: FC = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const notification = useAppSelector((state) => state.app.notification)
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()

    const [resetPassword] = useResetPasswordMutation()

    const handleForgotPassword = async (data: { email: string } | any) => {
        try {
            await resetPassword({ email: data.email })
            dispatch(
                setNotification({
                    type: 'success',
                    title: 'To se povedlo!',
                    message: 'Na e-mail sme Vám odeslali nové heslo.',
                }),
            )
            navigate('/prihlaseni')
        } catch (err) {
            dispatch(
                setNotification({
                    type: 'error',
                    title: 'Chyba!',
                    message: 'Něco se pokazilo...',
                }),
            )
        }
    }

    return (
        <div
            className={'h-full w-full bg-appBackground'}
            style={{
                height: '100%',
                background:
                    'radial-gradient(116.48% 209.06% at 80.47% 81.35%,#dff7ff 0,hsla(0,0%,100%,0) 100%),#fff',
            }}>
            <div className={'2xl:container relative mx-auto h-full w-full overflow-x-hidden'}>
                {/* Background pattern */}
                <Pattern className={'hidden md:block'} />

                {/* Navigation */}
                <UnloggedLayout />

                {/* Content - login form*/}
                <LoginFormWrap title={'Služba pro zapomnětlíky... '}>
                    <form
                        onSubmit={handleSubmit(handleForgotPassword)}
                        className='space-y-6 pb-6 px-8 md:space-y-8 md:px-2 md md:w-96 mx-auto'>
                        <div>
                            <div className='mt-1'>
                                <InputWithIcon
                                    Icon={EnvelopeIcon}
                                    placeholder={'Přihlasovací e-mail'}
                                    type={'text'}
                                    {...register('email')}
                                />
                            </div>
                        </div>

                        {/*<div className='text-sm text-center'>*/}
                        {/*    <Link*/}
                        {/*        to={routerCatalog.login}*/}
                        {/*        className='font-medium underline cursor-pointer text-lightBlue hover:text-lightPurple'>*/}
                        {/*        Zpět na přihlášení*/}
                        {/*    </Link>*/}
                        {/*</div>*/}

                        <div>
                            <ButtonCommon
                                type={'submit'}
                                onClick={handleSubmit(handleForgotPassword)}
                                className='flex w-full justify-center py-4 text-md font-normal uppercase bg-lightPurple hover:bg-darkPurple'>
                                Generovat nové heslo a poslat na email
                            </ButtonCommon>
                        </div>
                    </form>
                </LoginFormWrap>
            </div>
            {notification && <Notification notification={notification} />}
        </div>
    )
}

export default ForgotPassword
