import { useEffect, useMemo, useState } from 'react'
import { PriceList, PriceListItem } from 'api/priceLists/types'

interface UseCalculateLoan {
    calc_rmpsn: (type: string) => string
    resetPrices: (type: 'fix' | 'rent') => void
    amount: number
    loanPrices: number
    deferralPrice: number
    totalPrice: number
}

const useCalculateLoan = (
    rangePrice: number | number[],
    rangeDays: number | number[],
    priceList: PriceList[],
    preSet: number,
): UseCalculateLoan => {
    const [amount, setAmount] = useState<number>(0)
    const [loanPrices, setLoanPrices] = useState<number>(0)
    const [deferralPrice, setDeferralPrice] = useState<number>(0)
    const [totalPrice, setTotalPrice] = useState<number>(0)
    const defaultValues = { amount: 0, days: 0 }

    const getLoanPrice = (
        priceItem: PriceListItem[],
        rangeDays: number | number[],
    ): PriceListItem => {
        if (rangeDays > preSet) {
            return priceItem.find((price) => price.days === preSet) || defaultValues
        }
        return priceItem?.find((price) => price.days === rangeDays) || defaultValues
    }

    const getLoanDeferralPrice = (priceItem: PriceListItem[], rangeDays: number | number[]) => {
        if (rangeDays <= preSet) {
            return defaultValues
        }

        return (
            priceItem.find((priceItem) => priceItem.days === +rangeDays - preSet) || defaultValues
        )
    }

    const getLoanTotalPrice = (
        deferredPrice: PriceListItem,
        loanPrice: PriceListItem,
        rangePrice: any,
    ) => {
        if (deferredPrice.amount !== 0) {
            return loanPrice.amount + rangePrice + deferredPrice.amount
        }

        return loanPrice.amount + rangePrice
    }

    const calc_rpsn = useMemo(
        () =>
            (amount: number, fee: number, duration: number): string => {
                return ((((amount + fee) / amount) ** (1 / (duration / 365.0)) - 1) * 100).toFixed(
                    0,
                )
            },
        [rangePrice, rangeDays],
    )

    const calc_mpsn = useMemo(
        () =>
            (amount: number, fee: number, duration: number): string => {
                return ((((amount + fee) / amount) ** (1 / (duration / 30.0)) - 1) * 100).toFixed(0)
            },
        [rangePrice, rangeDays],
    )

    const calc_rmpsn = useMemo(
        () =>
            (type: string): string => {
                return type === 'rpsn'
                    ? calc_rpsn(Number(rangePrice), loanPrices + deferralPrice, Number(rangeDays))
                    : calc_mpsn(Number(rangePrice), loanPrices + deferralPrice, Number(rangeDays))
            },
        [loanPrices, deferralPrice],
    )

    const getPriceItem = () => {
        return (
            priceList.find((price) => price.amount === rangePrice) || {
                amount: 3000,
                loan_prices: [],
                deferral_prices: [],
            }
        )
    }

    const setPrices = (priceItem: PriceList) => {
        if (priceItem.amount > 8000) {
            setLoanPrices(0)
            setTotalPrice(0)
            setDeferralPrice(0)
        } else {
            const loanPrice = getLoanPrice(priceItem.loan_prices, rangeDays)
            const deferredPrice = getLoanDeferralPrice(
                priceItem.deferral_prices,
                preSet === 14 ? +rangeDays - 21 : rangeDays,
            )
            const totalPrice = getLoanTotalPrice(deferredPrice, loanPrice, rangePrice)

            setAmount(priceItem.amount)

            setLoanPrices(loanPrice.amount)
            setDeferralPrice(deferredPrice.amount)
            setTotalPrice(preSet === 14 ? totalPrice + 150 : totalPrice)
        }
    }

    const resetPrices = (type: 'fix' | 'rent'): void => {
        if (type === 'fix') {
            const priceItem = getPriceItem()
            setPrices(priceItem as PriceList)
        } else {
            setLoanPrices(0)
            setDeferralPrice(0)
            setTotalPrice(0)
        }
    }

    useEffect(() => {
        setPrices(getPriceItem() as PriceList)
    }, [rangePrice, rangeDays])

    return {
        calc_rmpsn,
        resetPrices,
        amount,
        loanPrices,
        deferralPrice,
        totalPrice,
    }
}

export default useCalculateLoan
