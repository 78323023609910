import { ButtonPurple } from '@vistacredit/lib-ui'
import { PlusIcon } from '@heroicons/react/24/solid'
import { FC } from 'react'
import { CustomerPapers } from 'api/customers/types'

interface IProfileUploadDocumentsProps {
    onOpen: (open: boolean) => void
    customerPapers: CustomerPapers[]
}

const ProfileUploadDocuments: FC<IProfileUploadDocumentsProps> = ({ onOpen, customerPapers }) => {
    return (
        <div className='border-t border-gray-200 px-4 py-5 sm:px-6'>
            <dl className='grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2'>
                <div className='sm:col-span-2'>
                    <div className='text-center'>
                        <svg
                            className='mx-auto h-12 w-12 text-gray-400'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                            aria-hidden='true'>
                            <path
                                vectorEffect='non-scaling-stroke'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth={2}
                                d='M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z'
                            />
                        </svg>
                        {customerPapers.length === 0 && (
                            <h3 className='mt-2 text-sm font-medium text-gray-900'>
                                Nebyly nalezeny žádné osobní doklady
                            </h3>
                        )}
                        <p className='mt-1 text-sm text-gray-500'>
                            Nahrajte sken OP a vypis z účtu
                        </p>
                        <div className='mt-6'>
                            <ButtonPurple onClick={onOpen}>
                                <PlusIcon className='-ml-1 mr-2 h-5 w-5' aria-hidden='true' />
                                Nahrát doklady
                            </ButtonPurple>
                        </div>
                    </div>
                </div>
            </dl>
        </div>
    )
}

export default ProfileUploadDocuments
