import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAuthApi } from 'api'
import { PageWrapLayout } from 'layout/components'
import { useDispatch } from 'react-redux'
import { setNotification } from 'store/stateReducer'

const Settings: FC = () => {
    const [error, setError] = useState<boolean>(false)
    const { authPasswordChange } = useAuthApi()
    const form = useForm()
    const dispatch = useDispatch()

    const handleSubmitPasswordChange = async (data: any) => {
        if (data.password !== data.password_repeat || data.password.length < 8) {
            setError(true)
            return
        }

        await authPasswordChange({
            password: data.password,
            password_check: data.password_repeat,
        })
            .then((res) => {
                dispatch(
                    setNotification({
                        type: 'success',
                        title: 'Huráá',
                        message: 'Heslo bylo úspěšně změněno',
                    }),
                )
            })
            .catch((err) => {
                dispatch(
                    setNotification({
                        type: 'error',
                        title: 'A jeje',
                        message: 'Heslo se nepodařilo změnit',
                    }),
                )
            })
        form.reset()
    }

    return (
        <PageWrapLayout title={'Nastavení'}>
            <div className='h-full w-full'>
                <main className='mx-auto w-full pb-10 py-4 lg:px-8'>
                    <div className='lg:grid lg:grid-cols-12 lg:gap-x-5'>
                        {/* Payment details */}
                        <div className='space-y-6 w-full sm:px-6 lg:col-span-12 lg:px-0'>
                            <section aria-labelledby='payment-details-heading w-full'>
                                <form onSubmit={form.handleSubmit(handleSubmitPasswordChange)}>
                                    <div className='shadow sm:overflow-hidden sm:rounded-md w-full'>
                                        <div className='bg-white py-6 px-4 sm:p-6'>
                                            <div>
                                                <h2
                                                    id='payment-details-heading'
                                                    className='text-lg font-medium leading-6 text-gray-900'>
                                                    Změna hesla
                                                </h2>
                                            </div>

                                            <div className='mt-3 grid grid-cols-4 gap-6'>
                                                <div className='col-span-4 sm:col-span-2'>
                                                    <label
                                                        htmlFor='first-name'
                                                        className='block text-sm font-medium text-gray-700'>
                                                        Nové heslo
                                                    </label>
                                                    <input
                                                        type='password'
                                                        {...form.register('password')}
                                                        required
                                                        id='first-name'
                                                        autoComplete='cc-given-name'
                                                        className='mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring-gray-900 sm:text-sm'
                                                    />
                                                </div>

                                                <div className='col-span-4 sm:col-span-2'>
                                                    <label
                                                        htmlFor='last-name'
                                                        className='block text-sm font-medium text-gray-700'>
                                                        Zopakujte nové heslo
                                                    </label>
                                                    <input
                                                        type='password'
                                                        {...form.register('password_repeat')}
                                                        id=' last-name'
                                                        required
                                                        autoComplete=' cc-family-name'
                                                        className=' mt-1 block w-full rounded-md border border-gray-300 py-2
													px-3 shadow-sm focus:border-gray-900 focus:outline-none
													focus:ring-gray-900 sm:text-sm'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='bg-gray-50 px-4 py-3 text-right sm:px-6'>
                                            <button
                                                type='submit'
                                                className='inline-flex justify-center rounded-md border border-transparent
													bg-lightPurple py-2 px-4 text-sm font-medium text-white shadow-sm
													hover:bg-darkPurple'>
                                                Uložit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </section>
                        </div>
                    </div>
                </main>
            </div>
        </PageWrapLayout>
    )
}

export default Settings
