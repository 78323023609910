import React, { FC } from 'react'

interface IMessagePreviewProps {
    id: number
    register: any
}

const MessagePreview: FC<IMessagePreviewProps> = ({ id, register }) => {
    switch (id) {
        case 1:
            return (
                <div>
                    <h1 className='text-lg  text-left font-medium font-Quicksand'>
                        Náhled zprávy:{' '}
                    </h1>
                    <p className='mb-6'>
                        Dobrý den, potřeboval bych změnit adresu pro doručování pošty. Moje nová
                        adresa je:
                    </p>
                    <input
                        type='hidden'
                        value={
                            'Dobrý den, potřeboval bych změnit adresu pro doručování pošty. Moje nová adresa je:'
                        }
                        {...register('message')}
                    />
                    <div className='mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8'>
                        <div>
                            <div className='flex justify-between'>
                                <label
                                    htmlFor='phone'
                                    className='block text-sm font-medium text-gray-900'>
                                    Ulice
                                </label>
                            </div>
                            <div className='mt-1'>
                                <input
                                    type='text'
                                    id='phone'
                                    required
                                    {...register('street')}
                                    autoComplete='tel'
                                    className='block w-full rounded-md border border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500'
                                    aria-describedby='phone-optional'
                                />
                            </div>
                        </div>
                        <div>
                            <div className='flex justify-between'>
                                <label
                                    htmlFor='phone'
                                    className='block text-sm font-medium text-gray-900'>
                                    Číslo popisné
                                </label>
                            </div>
                            <div className='mt-1'>
                                <input
                                    type='text'
                                    {...register('houseNumber')}
                                    id='phone'
                                    required
                                    autoComplete='tel'
                                    className='block w-full rounded-md border border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500'
                                    aria-describedby='phone-optional'
                                />
                            </div>
                        </div>
                        <div>
                            <div className='flex justify-between'>
                                <label
                                    htmlFor='phone'
                                    className='block text-sm font-medium text-gray-900'>
                                    Město
                                </label>
                            </div>
                            <div className='mt-1'>
                                <input
                                    type='text'
                                    {...register('city')}
                                    id='phone'
                                    required
                                    autoComplete='tel'
                                    className='block w-full rounded-md border border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500'
                                    aria-describedby='phone-optional'
                                />
                            </div>
                        </div>
                        <div>
                            <div className='flex justify-between'>
                                <label
                                    htmlFor='phone'
                                    className='block text-sm font-medium text-gray-900'>
                                    PSČ
                                </label>
                            </div>
                            <div className='mt-1'>
                                <input
                                    type='text'
                                    {...register('postalCode')}
                                    id='phone'
                                    required
                                    autoComplete='tel'
                                    className='block w-full rounded-md border border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500'
                                    aria-describedby='phone-optional'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )
        case 2:
            return (
                <div>
                    <h1 className='text-lg  text-left font-medium font-Quicksand'>
                        Náhled zprávy:{' '}
                    </h1>
                    <p className='mb-6'>
                        Dobrý den, chci se zeptat, zda je ještě něco potřeba k tomu, aby jste mohli
                        rozhodnout moji žádost o úvěr?
                    </p>
                    <input
                        type='hidden'
                        value={
                            'Dobrý den, chci se zeptat, zda je ještě něco potřeba k tomu, aby jste mohli rozhodnout moji žádost o úvěr?'
                        }
                        {...register('message')}
                    />
                </div>
            )
        case 3:
            return (
                <div>
                    <h1 className='text-lg  text-left font-medium font-Quicksand'>
                        Náhled zprávy:{' '}
                    </h1>
                    <p className='mb-6'>
                        Dobrý den, potřebuji změnit doklad a nevím jak na to ?.. Poradíte mi prosím,
                        případně mi můžete zavolat na číslo:
                    </p>
                    <input
                        type='hidden'
                        value={
                            'Dobrý den, potřebuji změnit doklad a nevím jak na to ?.. Poradíte mi prosím, případně mi můžete zavolat na číslo:'
                        }
                        {...register('message')}
                    />
                    <div className='mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8'>
                        <div>
                            <div className='flex justify-between'>
                                <label
                                    htmlFor='phone'
                                    className='block text-sm font-medium text-gray-900'>
                                    Telefonní číslo
                                </label>
                            </div>
                            <div className='mt-1'>
                                <input
                                    type='tel'
                                    {...register('phone')}
                                    pattern='^(\+?420)?(2[0-9]{2}|3[0-9]{2}|4[0-9]{2}|5[0-9]{2}|72[0-9]|73[0-9]|77[0-9]|60[1-8]|56[0-9]|70[2-5]|79[0-9])[0-9]{3}[0-9]{3}$'
                                    id='phone'
                                    required
                                    autoComplete='tel'
                                    className='block w-full rounded-md border border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500'
                                    aria-describedby='phone-optional'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )

        case 4:
            return (
                <div>
                    <h1 className='text-lg  text-left font-medium font-Quicksand'>
                        Náhled zprávy:{' '}
                    </h1>
                    <p className='mb-6'>
                        Dobrý den, na mém telefonu vidím, že jste mi volali z čísla 587 407 707.
                        Omlouvám se, ale nebyl jsem k zastižení. Můžete mi prosím zavolat na číslo:
                    </p>
                    <input
                        type='hidden'
                        value={
                            'Dobrý den, na mém telefonu vidím, že jste mi volali z čísla 587 407 707. Omlouvám se, ale nebyl jsem k zastižení. Můžete mi prosím zavolat na číslo:'
                        }
                        {...register('message')}
                    />
                    <div className='mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8'>
                        <div>
                            <div className='flex justify-between'>
                                <label
                                    htmlFor='phone'
                                    className='block text-sm font-medium text-gray-900'>
                                    Telefonní číslo
                                </label>
                            </div>
                            <div className='mt-1'>
                                <input
                                    type='tel'
                                    {...register('phone')}
                                    pattern='^(\+?420)?(2[0-9]{2}|3[0-9]{2}|4[0-9]{2}|5[0-9]{2}|72[0-9]|73[0-9]|77[0-9]|60[1-8]|56[0-9]|70[2-5]|79[0-9])[0-9]{3}[0-9]{3}$'
                                    id='phone'
                                    required
                                    autoComplete='tel'
                                    className='block w-full rounded-md border border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500'
                                    aria-describedby='phone-optional'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )
        case 5:
            return (
                <div>
                    <h1 className='text-lg  text-left font-medium font-Quicksand'>
                        Náhled zprávy:{' '}
                    </h1>
                    <p className='mb-6'>
                        Dobrý den, blíží se termín splatnosti a já ještě nemám všechny finance,
                        abych vám je mohl vrátit. Potřeboval bych odložit splatnost. Jak to mohu
                        udělat u úvěru?
                    </p>
                    <input
                        type='hidden'
                        value={
                            'Dobrý den, blíží se termín splatnosti a já ještě nemám všechny finance, abych vám je mohl vrátit. Potřeboval bych odložit splatnost. Jak to mohu udělat u úvěru?'
                        }
                        {...register('message')}
                    />
                </div>
            )
        case 6:
            return (
                <div>
                    <h1 className='text-lg  text-left font-medium font-Quicksand'>
                        Náhled zprávy:{' '}
                    </h1>
                    <p className='mb-6'>
                        Dobrý den, mám velké finanční problémy a nevím co dál. Nechci, aby jste můj
                        úvěr vymáhali a tím se i zvyšovala celková částka ke splacení. Můžete mě
                        prosím kontaktovat na číslo:
                    </p>
                    <input
                        type='hidden'
                        value={
                            'Dobrý den, mám velké finanční problémy a nevím co dál. Nechci, aby jste můj úvěr vymáhali a tím se i zvyšovala celková částka ke splacení. Můžete mě prosím kontaktovat na číslo:'
                        }
                        {...register('message')}
                    />
                    <div className='mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8'>
                        <div>
                            <div className='flex justify-between'>
                                <label
                                    htmlFor='phone'
                                    className='block text-sm font-medium text-gray-900'>
                                    Telefonní číslo
                                </label>
                            </div>
                            <div className='mt-1'>
                                <input
                                    type='tel'
                                    {...register('phone')}
                                    pattern='^(\+?420)?(2[0-9]{2}|3[0-9]{2}|4[0-9]{2}|5[0-9]{2}|72[0-9]|73[0-9]|77[0-9]|60[1-8]|56[0-9]|70[2-5]|79[0-9])[0-9]{3}[0-9]{3}$'
                                    id='phone'
                                    required
                                    autoComplete='tel'
                                    className='block w-full rounded-md border border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500'
                                    aria-describedby='phone-optional'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )
        case 7:
            return (
                <div>
                    <h1 className='text-lg  text-left font-medium font-Quicksand'>
                        Náhled zprávy:{' '}
                    </h1>
                    <p className='mb-6'>
                        Dobrý den, chci vás informovat, že jsem požádal o oddlužení (insolvenci).
                        Uznávám pohledávku v plné výši.
                    </p>
                    <input
                        type='hidden'
                        value={
                            'Dobrý den, chci vás informovat, že jsem požádal o oddlužení (insolvenci). Uznávám pohledávku v plné výši.'
                        }
                        {...register('message')}
                    />
                </div>
            )
        case 8:
            return (
                <div>
                    <h1 className='text-lg  text-left font-medium font-Quicksand'>
                        Náhled zprávy:{' '}
                    </h1>
                    <p className='mb-6'>
                        Dobrý den, poslušně hlásím, že jsem našel chybu. Jedná se o to, že:
                    </p>
                    <input
                        type='hidden'
                        value={
                            'Dobrý den, poslušně hlásím, že jsem našel chybu. Jedná se o to, že:'
                        }
                        {...register('message')}
                    />
                    <div className='sm:col-span-2'>
                        <div className='flex justify-between'>
                            <label
                                htmlFor='message'
                                className='block text-sm font-medium text-gray-900'>
                                Zpráva
                            </label>
                            <span id='message-max' className='text-sm text-gray-500'>
                                Max. 500 znaků
                            </span>
                        </div>
                        <div className='mt-1'>
                            <textarea
                                id='message'
                                {...register('text-message')}
                                rows={4}
                                required
                                className='block w-full rounded-md border border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500'
                                aria-describedby='message-max'
                                defaultValue={''}
                            />
                        </div>
                    </div>
                </div>
            )
        case 9:
            return (
                <div>
                    <h1 className='text-lg  text-left font-medium font-Quicksand'>
                        Náhled zprávy:{' '}
                    </h1>
                    <p className='mb-6'>
                        Dobrý den, nedělám to běžně, ale musím vás pochválit. Velice se mi Vaše
                        služby líbí a jsem s nimi spokojen. Rozhodně doporučím Vistu mým známým a
                        kamarádům.
                    </p>
                    <input
                        type='hidden'
                        value={
                            'Dobrý den, nedělám to běžně, ale musím vás pochválit. Velice se mi Vaše služby líbí a jsem s nimi spokojen. Rozhodně doporučím Vistu mým známým a kamarádům.'
                        }
                        {...register('message')}
                    />
                </div>
            )
        case 10:
            return (
                <div>
                    <h1 className='text-lg  text-left font-medium font-Quicksand'>
                        Náhled zprávy:{' '}
                    </h1>
                    <p className='mb-6'>
                        Dobrý den, napadla mě myšlenka a myslím, že byste ji mohli realizovat a tím
                        zlepšit vaše služby. Nápad spočívá v tom, že:
                    </p>
                    <input
                        type='hidden'
                        value={
                            'Dobrý den, napadla mě myšlenka a myslím, že byste ji mohli realizovat a tím zlepšit vaše služby. Nápad spočívá v tom, že:'
                        }
                        {...register('message')}
                    />
                    <div className='sm:col-span-2'>
                        <div className='flex justify-between'>
                            <label
                                htmlFor='message'
                                className='block text-sm font-medium text-gray-900'>
                                Zpráva
                            </label>
                            <span id='message-max' className='text-sm text-gray-500'>
                                Max. 500 znaků
                            </span>
                        </div>
                        <div className='mt-1'>
                            <textarea
                                id='message'
                                {...register('text-message')}
                                rows={4}
                                required
                                className='block w-full rounded-md border border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500'
                                aria-describedby='message-max'
                                defaultValue={''}
                            />
                        </div>
                    </div>
                </div>
            )
        default:
            return (
                <div>
                    {/*<h1 className='text-lg  text-left font-medium font-Quicksand'>*/}
                    {/*    Náhled zprávy:{' '}*/}
                    {/*</h1>*/}
                    {/*<p className='mb-6'>*/}
                    {/*    Dobrý den, napadla mě myšlenka a myslím, že byste ji mohli realizovat a tím zlepšit vaše služby. Nápad spočívá v tom, že:*/}
                    {/*</p>*/}
                    <div className='sm:col-span-2'>
                        <div className='flex justify-between'>
                            <label
                                htmlFor='message'
                                className='block text-sm font-medium text-gray-900'>
                                Zpráva
                            </label>
                            <span id='message-max' className='text-sm text-gray-500'>
                                Max. 500 znaků
                            </span>
                        </div>
                        <div className='mt-1'>
                            <textarea
                                id='message'
                                {...register('text-message')}
                                rows={4}
                                required
                                className='block w-full rounded-md border border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500'
                                aria-describedby='message-max'
                                defaultValue={''}
                            />
                        </div>
                    </div>
                </div>
            )
    }
}

export default MessagePreview
