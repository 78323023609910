import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { Provider } from 'react-redux'
import { persistor, store } from 'store/store'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter } from 'react-router-dom'
import { RoutesCatalog as Routes } from 'routes/Routes'
import { ApiProvider } from '@reduxjs/toolkit/dist/query/react'
import { serviceApi } from 'api/serviceApi'
import Wrapper from 'routes/components/ScrollToTop'
import Gleap from 'gleap'

Gleap.initialize('deWnbTB56G85d7NcP9rOtBlvpFFPZVEa')
Gleap.setLanguage('cs')

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <ApiProvider api={serviceApi}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter>
                        <Wrapper>
                            <Routes />
                        </Wrapper>
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        </ApiProvider>
    </React.StrictMode>,
)
