import React, { FC, useState } from 'react'
import ModalTitle from 'layout/components/modal/shared/ModalTitle'
import PDFPreview from 'pages/dashboard/modals/signContract/components/PDFPreview'
import ModalWrapper from 'layout/components/modal/ModalWrapper'
import { ButtonCommon } from '@vistacredit/lib-ui'

interface IAmendmentModalProps {
    open?: boolean
    setOpen: (value: boolean) => void
    handleSignAmendment: () => void
    file: string
}

const AmendmentModal: FC<IAmendmentModalProps> = ({ open, handleSignAmendment, file }) => {
    const [disabled, setDisabled] = useState(false)

    return (
        <ModalWrapper open={open ?? false} setOpen={() => null} size={'sm:max-w-5xl'}>
            <div className='mb-0'>
                <ModalTitle title='Uzavírání smluv distančním způsobem' />
            </div>

            <PDFPreview url={file ?? ''} height={'500px'} />

            <div className='mt-4 max-w-xl mx-auto'>
                <ButtonCommon onClick={handleSignAmendment} disabled={disabled}>
                    Poučení rozumím a stiskem tlačítka ho přijímám
                </ButtonCommon>
            </div>
        </ModalWrapper>
    )
}

export default AmendmentModal
