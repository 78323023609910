import React, { FC } from 'react'
import Question13_3 from 'assets/images/question13_3.png'
import { Link } from 'react-router-dom'

const QuestionFourteen: FC = () => {
    return (
        <div className='space-y-4'>
            <p>Je několik způsobů, které klientům nabízíme.</p>
            <img src={Question13_3} className='w-fit lg:max-w-xl my-3' alt='question3 image' />

            <p>
                <b>1. Platba kartou:</b>
            </p>
            <ul className=' space-y-3 pl-10 list-disc '>
                <li>
                    Jedná se o nejrychlejší, nejpohodlnější a nejpřesnější úhradu jakéhokoliv
                    poplatku u naší služby VistaCredit.cz. Nemůže dojít k chybě v platebních údajích
                    a my platbu účtujeme obratem. V případě úhrady celého úvěru tak můžete ihned
                    žádat o nový úvěr.
                </li>
            </ul>
            <p>
                <b>2. Úhrada QR kódem (bezhotovostní převod):</b>
            </p>
            <ul className=' space-y-3 pl-10 list-disc '>
                <li>
                    rovněž rychlá, bezpečná a pohodlná platba, obzvláště v dnešní době online
                    převodů, kdy převod z jakékoliv banky do naší je otázkou sekund.
                </li>
            </ul>
            <p>
                <b>3. Hotovostní úhrada na přepážce banky, případě poštovní složenka:</b>
            </p>
            <ul className=' space-y-3 pl-10 list-disc '>
                <li>
                    můžeme Vám poslat platební údaje k úvěru a vy pouze navštívíte osobně svou
                    banku, případně poštovní přepážku a uhradíte úvěr tímto způsobem, což ovšem z
                    pohledu chybovosti v zadávání platební údajů <b>nedoporučujeme</b>.
                </li>
            </ul>
            <p>
                <b>4. Úhradu můžete provést i za použití nasbíraných bodů. </b> Jak postupovat v
                tomto případě je přehledně popsané{' '}
                <Link
                    className={'hover:text-lightBlue hover:underline'}
                    to='/pomoc/mam-nasbirane-body-a-nebojim-se-je-pouzit'>
                    <b>ZDE</b>
                </Link>
                .
            </p>

            <p>
                Všemi těmito způsoby( kromě úhradou za body) lze uhradit jak Poplatek za odložení,
                tak i splatit celý úvěr, případně i částečnou úhradu úvěru.
            </p>
        </div>
    )
}

export default QuestionFourteen
