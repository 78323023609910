import { FC } from 'react'
import ModalWrapper from 'layout/components/modal/ModalWrapper'
import ModalTitle from 'layout/components/modal/shared/ModalTitle'
import ModalFooter from 'layout/components/modal/shared/ModalFooter'
import { useUploadDocumentLogicLayer } from 'pages/profile/hooks/useUploadDocumentLogicLayer'
import UploadInput from 'pages/profile/modals/components/UploadInput'
import UploadedFilePreview from 'pages/profile/modals/components/UploadedFilePreview'
import { ButtonCommon } from '@vistacredit/lib-ui'
import { CustomerPapers } from 'api/customers/types'
import {
    useGetCustomerPapersQuery,
    useGetCustomerQuery,
} from 'api/services/customer/serviceCustomer'

interface IDocumentsUploadModalProps {
    customerPapers: CustomerPapers[]
    open: boolean
    setOpen: (value: boolean) => void
}

const DocumentsUploadModal: FC<IDocumentsUploadModalProps> = ({
    open,
    setOpen,
    customerPapers,
}) => {
    const {
        progress,
        uploadingSteps,
        files,
        fileUploadHandler,
        moveThroughUploadStepsHandler,
        handleDisableButtonLogic,
        finishUploadingDocuments,
    } = useUploadDocumentLogicLayer(customerPapers)

    const customerPapersQuery = useGetCustomerPapersQuery()
    const customerQuery = useGetCustomerQuery()

    const handleCloseUploadModal = async () => {
        try {
            await customerPapersQuery.refetch()
            await customerQuery.refetch()
            setOpen(false)
        } catch (err) {
            console.error('err close upload modal')
        }
    }

    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <ModalTitle title={'Nahrávaní dokumentů'} />

            <div className='mb-6'>
                <div className='mt-6' aria-hidden='true'>
                    <div className='overflow-hidden rounded-full bg-gray-200'>
                        <div
                            className='h-2 rounded-full bg-lightPurple'
                            style={{ width: progress.width }}
                        />
                    </div>
                    <div className='mt-4 hidden grid-cols-6 text-sm font-medium text-gray-600 sm:grid'>
                        {uploadingSteps.map((step) => (
                            <div
                                key={step.id}
                                className={`col-span-2 ${
                                    step.id === progress.step && 'text-lightPurple'
                                } ${step.classTitle}`}>
                                {step.progressTitle}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className='sm:grid sm:grid-cols-3 text-center sm:items-center sm:gap-4 border-t border-b sm:border-gray-200 py-6'>
                <h4 className='sm:col-span-3 mb-4 sm-mb-0'>
                    {uploadingSteps.find((step) => step.id === progress.step)?.title}
                </h4>
                <div className='mt-1 sm:col-span-3 sm:mt-0'>
                    {progress &&
                    uploadingSteps.find((step) => step.id === progress.step)?.uploaded ? (
                        <UploadedFilePreview file={files[progress.step - 1]?.file} />
                    ) : (
                        <UploadInput
                            fileUploadHandler={fileUploadHandler}
                            type={uploadingSteps.find((step) => step.id === progress.step)!.type}
                        />
                    )}
                </div>
            </div>

            {uploadingSteps.find((step) => step.id === 3)?.uploaded ? (
                <ButtonCommon onClick={() => finishUploadingDocuments(setOpen)}>
                    Dokončit
                </ButtonCommon>
            ) : (
                <ModalFooter
                    title={'Pokračuj'}
                    disabled={handleDisableButtonLogic(progress.step)}
                    onClick={moveThroughUploadStepsHandler}
                    onClose={handleCloseUploadModal}
                />
            )}
        </ModalWrapper>
    )
}

export default DocumentsUploadModal
