import React from 'react'
import {
    ArchiveBoxIcon,
    InboxIcon,
    CircleStackIcon,
    QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline'
import { NavLink } from 'react-router-dom'

export const SidebarNav = () => {
    const sidebarNavigation = [
        { name: 'Aktiví úvěr', href: 'aktivni-uver', icon: InboxIcon, current: true },
        { name: 'Moje úvěry', href: 'moje-uvery', icon: ArchiveBoxIcon, current: false },
        { name: 'Bonusy', href: 'bonusy', icon: CircleStackIcon, current: false },
    ]

    return (
        <nav
            aria-label='Sidebar'
            className='hidden md:block md:flex-shrink-0 md:overflow-y-auto md:bg-transparent p-3 pl-4'>
            <div className='relative flex w-64 flex-col border border-borderGray border-t-0'>
                {sidebarNavigation.map((item) => (
                    <NavLink
                        key={item.name}
                        to={item.href}
                        className={({ isActive }) => `
                        bg-white border-t border-borderGray font-light flex-shrink-0 inline-flex items-center justify-start h-20 px-21 py-3 w-full ${
                            isActive
                                ? 'text-lightBlue hover:text-white hover:bg-lightBlue'
                                : ' hover:text-white hover:bg-lightBlue'
                        }`}>
                        <item.icon className='h-6 w-6 ml-3.5 mr-2.5' aria-hidden='true' />
                        <span className=''>{item.name}</span>
                    </NavLink>
                ))}
                <div
                    className={`
                        bg-bgGray cursor-pointer border-t border-borderGray font-light flex-shrink-0 inline-flex items-center justify-start h-20 px-21 py-3 w-full hover:text-white hover:bg-lightBlue`}>
                    <QuestionMarkCircleIcon className='h-6 w-6 ml-3.5 mr-2.5' aria-hidden='true' />
                    <span>Spustit nápovědu</span>
                </div>
            </div>
        </nav>
    )
}
