import React, { FC, ReactNode } from 'react'
import TheoLogin from 'assets/images/TheoLogin'

interface ILoginFromWrapProps {
    children: ReactNode | ReactNode[]
    title?: string
}

const LoginFormWrap: FC<ILoginFromWrapProps> = ({ children, title }) => {
    return (
        <div
            className='grid content-center h-full mt-8 sm:mx-auto sm:w-full sm:max-w-xl font-light font-Quicksand'
            style={{ maxHeight: '80%' }}>
            <div className='py-8 px-4 shadow-xl rounded-xl sm:px-10 bg-white relative z-10 border border-borderGray/50'>
                <div className='sm:mx-auto sm:w-full sm:max-w-md mb-8'>
                    <TheoLogin className={'mx-auto w-auto'} />
                    <h2 className='mt-6 mb-6 text-center text-3xl font-normal tracking-tight font-Quicksand text-lightPurple'>
                        {title ?? 'Přihlášení do účtu klienta'}
                    </h2>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default LoginFormWrap
