import {FC} from 'react'
import {Logo} from '@vistacredit/lib-ui'
import {Link} from 'react-router-dom'

export const NavLogo: FC = () => (
	<div className='mx-auto md:mx-0 inset-y-0 left-0 md:static md:flex-shrink-0'>
		<Link to='/'>
			<Logo/>
		</Link>
	</div>
)
