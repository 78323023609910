import React, { FC } from 'react'
import Question13_1 from 'assets/images/question13_1.png'
import Question13_2 from 'assets/images/question13_2.png'
import Question13_3 from 'assets/images/question13_3.png'
import Question13_4 from 'assets/images/question13_4.png'

const QuestionThreeten: FC = () => {
    return (
        <div className='space-y-4'>
            <p>
                Změnit dobu splatnosti úvěru je možné přímo ve Vašem osobním účtu.{' '}
                <b>Doba splatnosti lze prodloužit opakovaně</b> a to až o dobu 35 dnů. Cena za tuto
                službu je uvedena přímo ve změnové kalkulačce.
            </p>
            <p>Postup:</p>

            <ul className=' space-y-3 pl-10 list-disc '>
                <li>
                    <div className='flex items-center'>
                        <span>
                            přihlaste se do Vašeho účtu a v záložce Aktivní úvěr klikněte na
                            tlačítko
                        </span>
                        <img
                            src={Question13_1}
                            className='w-fit lg:max-w-xl h-12'
                            alt='question3 image'
                        />
                    </div>
                </li>
                <li>
                    <div>
                        <span>zobrazí se nabídka na výběr délky doby odložení</span>
                        <img
                            src={Question13_2}
                            className='w-fit lg:max-w-xl my-3'
                            alt='question3 image'
                        />
                    </div>
                </li>
                <li>
                    <div>
                        <span>
                            v dalším kroku si vyberte, jako formou si přejete uhradit poplatek za
                            tuto službu
                        </span>
                        <img
                            src={Question13_3}
                            className='w-fit lg:max-w-xl my-3'
                            alt='question3 image'
                        />
                    </div>
                </li>
                <li>
                    <div>
                        <span>
                            pokud se rozhodneme neuhradit poplatek ihned, bude žádost o odložení
                            splatnosti zobrazena pod oknem s aktivním úvěrem. Úhradu je nutné
                            provést do 5 dnů od požádání.
                        </span>
                        <img
                            src={Question13_4}
                            className='w-fit lg:max-w-xl my-3'
                            alt='question3 image'
                        />
                    </div>
                </li>
            </ul>

            <p>
                Můžete nám také poslat dotaz přes <b>Kontaktní formulář</b> výběrem volby :{' '}
                <b>Vlastní dotaz, námět, prosba, pochvala, kritika</b>.
            </p>

            {/*<img src={Question1_2} className='max-w-xl my-3' alt='question3 image' />*/}
        </div>
    )
}

export default QuestionThreeten
