import React, { FC } from 'react'
import Question10_1 from 'assets/images/question10_1.png'
import Question_21_1 from 'assets/images/question_21_1.png'
import Question_21_2 from 'assets/images/question_21_2.png'
import Question_21_3 from 'assets/images/question_21_3.png'
import Question_21_4 from 'assets/images/question_21_4.png'

const QuestionTwentyOne: FC = () => {
    return (
        <div className='space-y-4'>
            <p>
                <b>Opět velice jednoduše :-) </b>
            </p>
            <ul className=' space-y-3 pl-10 list-disc '>
                <li>
                    <p>
                        klikněte na banner, kterým Vás pošleme do aplikace signi.com + Vám příjde
                        email, aby jste podepsal(a) potřebné dokumenty.
                    </p>

                    <img
                        src={Question10_1}
                        className='w-fit lg:max-w-xl my-3'
                        alt='question3 image'
                    />
                </li>
                <li>
                    aplikace SIGNI.com Vás uvítá a zobrazí Vám, jaký dokument od Vás vyžadujeme k
                    podpisu
                </li>
                <li>celý dokument Vám zobrazíme k náhledu (pročtení)</li>
                <li>
                    <p>
                        pokud s ním souhlasíte, ve spodní části najdete podpisová pole. Vy
                        podepisujete pole <b>Za klienta</b>. Podpisové pole <b>Za věřitele</b>{' '}
                        prosím nechte na nás.
                    </p>
                    <img
                        src={Question_21_1}
                        className='w-fit lg:max-w-xl my-3'
                        alt='question3 image'
                    />
                </li>
                <li>
                    <p>
                        <b>Klikněte na pole Podepsat</b> - budete vyzváni k vložení 4 místného
                        podpisového kódu, který Vám pošleme ihned na mobilní telefon. SMS bude
                        doručena přímo od <b>odesílatele zprávy - Signi</b>.
                    </p>
                    <img
                        src={Question_21_2}
                        className='w-fit lg:max-w-xl my-3'
                        alt='question3 image'
                    />
                </li>
                <li>
                    <p>
                        po vložení 4 místného podpisového kódu se zobrazí důležité okno a to je
                        Výběr místa a vzhledu Vašeho podpisu.
                    </p>
                    <img
                        src={Question_21_3}
                        className='w-fit lg:max-w-xl my-3'
                        alt='question3 image'
                    />
                </li>

                <li>
                    <b>doplňte název obce</b>, kde podepisujete dokument
                </li>
                <li>datum podpisu nelze změnit</li>
                <li>
                    do pole Jméno <b>vložte Vaše Jméno a Příjmení</b>
                </li>
                <li>v poli Písmo si můžete ještě vyladit vzhled podpisu - pokud chcete</li>
                <li>
                    až budete mít vše, <b>kliknete na pole Vložit podpis - a máte hotovo !</b>
                </li>
            </ul>

            <p>
                Správně a platně{' '}
                <b>podepsaný dokument bude obsahovat el. podpis a otisk Vašeho jména</b> a místa
                podpisu.
            </p>

            <img src={Question_21_4} className='w-fit lg:max-w-xl my-3' alt='question3 image' />

            <p>
                <b>Po Vašem podpisu jsme na řadě my</b>. Jakmile dokument také podepíšeme, náš
                systém Vám automaticky odešle finanční prostředky na Váš bankovní účet.
            </p>
        </div>
    )
}

export default QuestionTwentyOne
