import { FC } from 'react'
import { Link } from 'react-router-dom'
import TheoRent from 'assets/images/TheoRent'
import { routerCatalog } from 'routes/hooks/useSecureRouting'

const RentBanner: FC = () => {
    return (
        <div className='col-span-12 sm:col-span-12 md:col-span-8 xl:col-span-5 border border-borderGray bg-white rounded-lg p-6 relative overflow-hidden'>
            <h2 className='text-3xl font-medium font-Quicksand leading-normal text-gray-900'>
                Víte, co znamená <span className={'text-lightPurple'}>Vista FIX</span> nebo
                <span className={'text-lightPurple'}> RENT</span>?
            </h2>

            <div className='flex flex-col'>
                <div className='mt-8 z-10'>
                    <p className='text-md font-medium text-gray-500 mb-4 md:mb-10'>
                        Zorientujte se v našich skupinách, sbírejte body a získejte služby navíc.
                    </p>
                    <Link
                        to={`/${routerCatalog.pricing}`}
                        className='text-lightBlue underline font-light'>
                        Více informací
                    </Link>
                </div>
                <div className='flex justify-end w-100 md:h-20 lg:-mt-12 lg:h-10 p-0 z-0'>
                    <TheoRent
                        className={' bottom-0 -mb-6 -mr-6 md:mb-0 md:mr-0 md:absolute right-0 p-0'}
                    />
                </div>
            </div>
        </div>
    )
}

export default RentBanner
