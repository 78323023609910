import { FC, ReactNode } from 'react'

interface ITableProps {
    children: ReactNode | ReactNode[]
    noStyle?: boolean
}

const Table: FC<ITableProps> = ({ children, noStyle }) => {
    return (
        <div className='flex flex-col'>
            <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
                    <div
                        className={`overflow-hidden  ${
                            noStyle ? '' : 'shadow md:rounded-lg ring-1 ring-black ring-opacity-5'
                        }`}>
                        <table className='min-w-full divide-y divide-borderGray w-full'>
                            {children}
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Table
