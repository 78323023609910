import { FC, useEffect, useState } from 'react'
import { PageWrapLayout } from 'layout/components'
import { getDate } from 'utils/date'
import { formatCurrency } from 'utils'
import Table from 'pages/loans/components/Table'
import { Point } from 'api/points/types'
import Pagination from 'layout/components/pagination/Pagination'
import { useGetPointsListQuery } from 'api/services/utils/points/servicePoints'

const Bonuses: FC = () => {
    const pointsQuery = useGetPointsListQuery()
    const sevenDaysAgo: Date = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
    const stats = [
        {
            name: 'Získané odměny celkem',
            stat: `${pointsQuery?.data?.results.reduce((a, b) => a + b.amount, 0)} bodů`,
            color: 'text-green-500',
        },
        {
            name: 'Získané odměny za posledních 7 dnů',
            stat: `${
                pointsQuery?.data?.results
                    .filter((bonus) => new Date(bonus.created) >= sevenDaysAgo)
                    .reduce((a, b) => a + b.amount, 0) ?? 0
            } bodů`,
            color: 'text-lightBlue',
        },
        {
            name: 'Získané odměny v hodnotě celkem',
            stat: `${
                pointsQuery?.data?.results &&
                formatCurrency(
                    pointsQuery?.data?.results.reduce((a, b) => a + b.amount, 0) * 5,
                    'CZK',
                )
            }`,
            color: 'text-lightPurple',
        },
    ]

    const cells = ['ID', 'DATUM AKCE', 'ZÍSKANÉ BODY', 'ÚKON']
    const [list, setList] = useState<Point[] | []>([])

    useEffect(() => {
        pointsQuery.refetch()
    }, [])

    if (!pointsQuery?.data) return null

    return (
        <PageWrapLayout title={'Bonusy'}>
            <div>
                {/*<h3 className='text-lg font-medium leading-6 text-gray-900'>*/}
                {/*    Za posledních 30 dnů*/}
                {/*</h3>*/}
                <dl className='mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3 font-Quicksand'>
                    {stats.map((item) => (
                        <div
                            key={item.name}
                            className='overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6'>
                            <dt className='truncate text-sm font-medium text-gray-500'>
                                {item.name}
                            </dt>
                            <dd
                                className={`mt-1 text-3xl font-semibold tracking-tight ${
                                    item.color ?? 'text-gray-900'
                                }`}>
                                {item.stat}
                            </dd>
                        </div>
                    ))}
                </dl>
            </div>

            <div className='mt-10'>
                <h3 className='text-lg font-medium leading-6 text-gray-900'>
                    Přehled všech bonusů
                </h3>
                <Table noStyle>
                    <thead className={'w-full'}>
                        <tr className={''}>
                            {cells.map((cell, index) => (
                                <th
                                    key={cell}
                                    scope='col'
                                    className={`py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 ${
                                        index === cells.length - 1 && 'text-right right-3 relative'
                                    } ${
                                        index > 0 && index !== cells.length - 1 && 'text-center'
                                    } `}>
                                    {cell}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className='divide-y divide-gray-200 bg-white'>
                        {list.map((result: Point) => (
                            <tr key={result.created} className=''>
                                <td className='whitespace-nowrap p-4 text-sm font-medium text-gray-900 sm:pl-6'>
                                    {result.contract_id}
                                </td>
                                <td className='whitespace-nowrap p-4 text-sm text-center text-gray-500'>
                                    {getDate(result.created)}
                                </td>
                                <td className='whitespace-nowrap p-4 text-sm text-center text-gray-500'>
                                    {result.amount} bodů
                                </td>
                                <td className='whitespace-nowrap p-4 text-sm text-right text-gray-500'>
                                    {result.manner_text}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>

            <Pagination
                setList={setList}
                perPage={5}
                itemsList={pointsQuery?.data?.results}
                count={pointsQuery?.data.count}
            />
        </PageWrapLayout>
    )
}

export default Bonuses
