import { FC } from 'react'
import { IImageProps } from 'assets/images/types'

const TheoBadLuck: FC<IImageProps> = ({ ...rest }) => {
    return (
        <div {...rest}>
            <svg
                width='339'
                height='374'
                viewBox='0 0 339 374'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <g id='bad-luck'>
                    <path
                        id='Path'
                        d='M108.583 188.002C111.423 176.092 119.263 165.062 125.303 154.472C138.637 131.139 152.637 108.139 167.303 85.4719C181.843 63.0319 196.423 39.9519 213.303 19.0419C220.103 10.5919 228.523 3.31186 239.613 0.791864C249.793 -1.51814 259.913 1.24186 266.983 8.86186C275.803 18.4719 279.783 32.6919 284.423 44.3819C290.21 59.0485 295.627 73.8119 300.673 88.6719C312.583 123.902 327.423 188.682 333.913 211.232C335.713 217.442 336.863 223.792 335.523 229.632C333.613 237.982 326.473 241.992 318.523 243.082C320.143 253.582 322.373 263.942 326.203 273.812C328.323 270.622 333.443 270.412 336.253 273.602C339.643 277.462 338.703 284.922 335.763 288.852C331.653 294.382 325.383 294.122 319.973 291.292C316.902 292.469 313.49 292.393 310.473 291.082C302.773 293.962 294.733 289.352 290.623 282.642C290.083 281.772 289.623 280.872 289.113 279.952C292.233 300.952 295.413 321.702 298.433 339.362L307.903 348.852C309.563 350.502 312.413 352.782 313.383 355.552C314.353 358.322 314.203 362.682 310.833 362.682H258.123C253.273 362.682 251.063 359.942 250.123 353.492C249.423 348.662 236.643 273.112 230.273 235.492L223.733 234.862C229.783 274.522 235.573 318.612 241.893 356.022C242.533 359.852 240.273 362.702 238.193 362.702H176.583C171.813 362.702 173.493 354.452 175.303 352.612C179.963 347.882 187.513 341.172 187.513 341.172C184.253 323.872 181.103 305.442 178.023 286.842C176.735 286.997 175.432 286.997 174.143 286.842C170.037 289.965 164.37 290.03 160.193 287.002C159.468 287.182 158.719 287.249 157.973 287.202C157.044 287.861 155.845 288.008 154.783 287.592C147.063 284.812 143.783 276.432 141.723 269.122C139.273 260.442 138.403 251.392 137.483 242.452C136.69 234.752 136.05 227.042 135.563 219.322C130.752 217.893 126.069 216.063 121.563 213.852C111.163 208.592 105.893 199.232 108.583 188.002Z'
                        fill='#50D4F1'
                    />
                    <path
                        id='Path_2'
                        d='M2.04324 359.142C4.98325 356.582 8.83325 356.942 12.6732 357.192H13.4232C14.2865 353.156 17.8663 350.282 21.9932 350.312C23.4367 347.336 26.1122 345.143 29.3132 344.312C32.001 343.569 34.8444 343.604 37.5132 344.412C35.8718 334.575 38.8607 324.531 45.6132 317.192C54.4532 307.662 69.3132 304.492 81.8632 305.462C82.2052 305.494 82.5415 305.572 82.8632 305.692C94.4632 306.692 105.783 311.692 113.053 321.072C119.589 329.464 121.857 340.414 119.193 350.712C124.273 352.312 129.023 355.472 129.263 361.412C129.523 367.812 123.583 371.092 118.053 371.942C115.053 372.402 104.523 372.422 97.4432 372.592C90.3632 372.762 63.9932 373.812 47.2832 373.152C39.0832 372.832 30.8999 372.305 22.7332 371.572C18.4799 371.192 14.2366 370.755 10.0032 370.262C7.39324 369.962 4.27324 370.072 2.00324 368.512C-0.456757 366.832 -0.886749 361.702 2.04324 359.142Z'
                        fill='#FDC93A'
                    />
                    <path
                        id='Path_3'
                        d='M75.0332 293.202C85.7132 283.862 97.6832 276.092 109.623 268.482C113.393 266.072 120.253 260.612 124.913 263.852C129.143 266.792 127.453 275.592 127.363 279.852C127.012 294.657 125.221 309.394 122.013 323.852C122.603 334.852 103.303 330.402 97.4232 328.782C89.0687 326.457 81.3309 322.315 74.7632 316.652L74.5732 316.542C71.2632 314.232 63.9032 311.022 64.0932 306.082C64.2832 301.142 71.6932 296.122 75.0332 293.202Z'
                        fill='#E3D6BD'
                    />
                    <path
                        id='Path_4'
                        d='M88.7144 298.362C90.4511 303.377 93.2751 307.946 96.9844 311.742'
                        stroke='#063F58'
                        strokeWidth='6'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                    <path
                        id='Path_5'
                        d='M102.364 287.612C103.597 294.294 106.85 300.437 111.684 305.212'
                        stroke='#063F58'
                        strokeWidth='6'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                    <path
                        id='Path_6'
                        d='M114.553 284.472L115.553 287.022'
                        stroke='#063F58'
                        strokeWidth='6'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                    <path
                        id='Path_7'
                        d='M39.3342 349.932C40.3205 351.487 41.589 352.843 43.0742 353.932'
                        stroke='#063F58'
                        strokeWidth='6'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                    <path
                        id='Path_8'
                        d='M59.9032 361.632L68.0332 363.822'
                        stroke='#063F58'
                        strokeWidth='6'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                    <path
                        id='Path_9'
                        d='M100.312 342.192C99.9417 343.542 98.9724 344.648 97.6825 345.192'
                        stroke='#063F58'
                        strokeWidth='6'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                    <path
                        id='Path_10'
                        d='M76.793 344.702L74.633 341.702'
                        stroke='#063F58'
                        strokeWidth='6'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                    <path
                        id='Path_11'
                        d='M68.6133 324.862C67.266 325.216 66.1191 326.099 65.4333 327.312'
                        stroke='#063F58'
                        strokeWidth='6'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                    <path
                        id='Path_12'
                        d='M59.8938 335.562L61.4238 337.092'
                        stroke='#063F58'
                        strokeWidth='6'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                    <path
                        id='Path_13'
                        d='M52.7637 325.322H49.1937'
                        stroke='#063F58'
                        strokeWidth='6'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                    <path
                        id='Path_14'
                        d='M109.954 336.042L109.444 335.532'
                        stroke='#063F58'
                        strokeWidth='6'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                    <path
                        id='Path_15'
                        d='M329.734 279.722L327.184 274.032'
                        stroke='#063F58'
                        strokeWidth='6'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                    <path
                        id='Path_16'
                        d='M314.053 285.582C315.132 287.505 316.572 289.203 318.293 290.582'
                        stroke='#063F58'
                        strokeWidth='6'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                    <path
                        id='Path_17'
                        d='M300.883 283.622C301.963 285.072 304.543 288.162 305.803 289.462'
                        stroke='#063F58'
                        strokeWidth='6'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                    <path
                        id='Path_18'
                        d='M265.344 203.522C268.774 232.692 277.704 269.102 287.014 282.932'
                        stroke='#063F58'
                        strokeWidth='6'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                    <path
                        id='Path_19'
                        d='M160.764 258.282C161.804 260.576 162.478 263.019 162.764 265.522C163.054 268.172 162.174 271.572 159.014 272.012C155.854 272.452 153.574 269.562 152.444 267.082C150.61 263.073 149.342 258.829 148.674 254.472'
                        stroke='#063F58'
                        strokeWidth='6'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                    <path
                        id='Path_20'
                        d='M165.014 283.182C166.814 280.362 168.644 277.472 169.464 274.182C170.284 270.892 169.954 267.182 167.784 264.662C166.864 263.582 164.784 263.012 164.334 264.362'
                        stroke='#063F58'
                        strokeWidth='6'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                    <path
                        id='Path_21'
                        d='M139.703 222.872C149.403 226.122 167.463 228.252 167.463 228.252C167.463 228.252 173.273 265.652 175.633 284.472'
                        stroke='#063F58'
                        strokeWidth='6'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                    <path
                        id='Path_22'
                        d='M197.873 80.9319L195.783 86.2719'
                        stroke='#063F58'
                        strokeWidth='6'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                    <path
                        id='Path_23'
                        d='M221.133 88.8919L219.043 94.2319'
                        stroke='#063F58'
                        strokeWidth='6'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                    <path
                        id='Path_24'
                        d='M206.062 109.472C202.203 106.212 196.983 103.692 191.953 104.052'
                        stroke='#063F58'
                        strokeWidth='6'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                </g>
            </svg>
        </div>
    )
}

export default TheoBadLuck
