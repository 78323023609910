import { FC, Fragment, ReactNode, useEffect } from 'react'

interface IWrapper {
    children: ReactNode | ReactNode[]
}

const Wrapper: FC<IWrapper> = ({ children }) => {
    useEffect(() => {
        const element = document.getElementById('root-layout')
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' })
        }
    }, [])
    return <Fragment>{children}</Fragment>
}

export default Wrapper
