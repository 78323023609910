import { ajax } from 'api/index'
import { Contract, ContractsListResponse, EContractsType } from 'api/contracts/types'

interface IContractsApi {
    contractsList: (type: EContractsType) => Promise<ContractsListResponse>
    contractDetail: (id: number) => Promise<Contract>
    signDistanceAmendment: () => Promise<void>
    signDistanceAmendmentRS: () => Promise<void>
    signContract: (data: { contract_type: number; code: string }) => Promise<any>
    contractsToSign: () => Promise<ContractsListResponse>
    sendSmsCode: (type: number) => Promise<void>
}

export const useContractsApi = (): IContractsApi => {
    const contractsList = async (type: EContractsType): Promise<ContractsListResponse> => {
        try {
            const res = await ajax.get(`contracts/?contract_type=${type}`)
            return Promise.resolve(res.data)
        } catch (error) {
            return Promise.reject(error)
        }
    }
    const contractsToSign = async (): Promise<ContractsListResponse> => {
        try {
            const res = await ajax.get(`contracts/?to_sign=1`)
            return Promise.resolve(res.data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const signContract = async (data: { contract_type: number; code: string }): Promise<any> => {
        try {
            const res = await ajax.post(`sign-contract/`, data)
            return Promise.resolve(res.data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const sendSmsCode = async (type: number): Promise<any> => {
        try {
            const res = await ajax.post(`send-sign-sms/`, { type })
            return Promise.resolve(res.data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const contractDetail = async (id: number): Promise<Contract> => {
        try {
            const res = await ajax.get(`contracts/${id}/`)
            return Promise.resolve(res.data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const signDistanceAmendment = async (): Promise<void> => {
        try {
            const res = await ajax.post(`sign-distance-amendment/`)
            return Promise.resolve(res.data)
        } catch (error) {
            return Promise.reject(error)
        }
    }
    const signDistanceAmendmentRS = async (): Promise<void> => {
        try {
            const res = await ajax.post(`sign-framework-amendment-012021/`)
            return Promise.resolve(res.data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    return {
        contractsList,
        contractDetail,
        contractsToSign,
        signDistanceAmendment,
        signDistanceAmendmentRS,
        signContract,
        sendSmsCode,
    }
}
