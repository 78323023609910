export enum EContractsType {
    Contracts = 'contract',
    Deferral = 'deferral',
    Personal = 'personal',
}

export enum EContractDocumentType {
    ContractAboutIssues = 0,
    LoanContract = 1,
    FrameContract = 2,
    Registration = 3,
    Postponement = 6,
    GDPR = 7,
    AgreementOfPersonalDataProcessing = 8,
    Policy = 9,
}

export type Contract = {
    id: number
    number: string
    type: number
    created: string
    signed: string
    url: string
    type_text: string
    loan_public_id?: string
    signi_sign_url?: string
    latest_signi_status?: 'pending' | 'rejected' | 'client_pending' | 'vista_pending'
}

export type ContractsListRequest = {
    page?: number
    contract_type: EContractsType
    to_sign?: boolean
}

export type ContractsListResponse = {
    count: number
    next: string
    previous: string
    results: Array<Contract>
}

export type ContractDetailRequest = {
    id: number
}

export type ContractDetailResponse = Contract
