import React, { FC } from 'react'
import { Link } from 'react-router-dom'

const QuestionTwenty: FC = () => {
    return (
        <div className='space-y-4'>
            <p>Pokud nejste aktuálně schopen(a) splatit čerpaný úvěr, máme řešení !</p>

            <p>Konkrétní výpočet je:</p>
            <ol className=' space-y-3 pl-10 list-decimal'>
                <li>
                    Změňte si datum splatnosti pomocí naší služby Odložení splatnosti - postup - Jak
                    na to je popsaný{' '}
                    <Link to={'/pomoc/jak-a-kde-zmenim-dobu-splatnosti-meho-aktivniho-uveru'}>
                        {' '}
                        <b>ZDE</b>
                    </Link>
                    .
                </li>
                <li className={'space-y-3'}>
                    <p>
                        Společnost VistaCredit, s.r.o.{' '}
                        <b>
                            neposkytuje svým klientů splátkové kalendáře, ale je možné se dohodnout
                            na postoupení Vašeho závazku na našeho partnera spol. Maxfine, s.r.o.,
                            která může dočasně pohledávku spravovat
                        </b>{' '}
                        a také Vám vystavit splátkový kalendář.
                    </p>
                    <p>
                        Vzhledem k celkové výši pohledávek nebude možné nastavit delší dobu splácení
                        jak 6 měsíců. Je také počítat s tím, že Vám bude účtován poplatek za správu
                        a vystavení spl. kalendáře.
                    </p>

                    <p>
                        Na konci splátkového procesu Vám budou doměřeny sankce plynoucí z Rámcové
                        smlouvy (úroky a smluvní pokuta) <br />
                        Po splacení budete mít opět nárok čerpat úvěr od naší společnosti
                        VistaCredit, s.r.o. (tato možnost je podmíněna platební disciplínou a
                        rozhodnutím vedení spol. VistaCredit, s.r.o.)
                    </p>
                    <p>
                        <b>* Proto doporučujeme</b>, pokud to situace umožňuje, využít službu
                        Odložení splatnosti.
                    </p>
                </li>
            </ol>
            {/*<img src={Question20_1} className='max-w-xl my-3' alt='question3 image' />*/}

            <p>
                Rozhodně nám napište pomocí <b>Kontaktního formuláře</b> výběrem volby :{' '}
                <b>Dostal(a) jsem se do finančních problému</b> nebo na email{' '}
                <a href='mailto:uver@vistacredit.cz' className={'underline text-lightBlue'}>
                    uver@vistacredit.cz
                </a>{' '}
                - společně to vyřešíme.
            </p>
        </div>
    )
}

export default QuestionTwenty
