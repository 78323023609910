import React, { FC } from 'react'
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline'

type MenuButtonProps = {
    onClick: () => void
}

export const MenuButton: FC<MenuButtonProps> = ({ onClick }) => (
    <>
        <button
            type='button'
            className='flex flex-col items-center m-0 p-0 hover:text-lightBlue'
            onClick={onClick}>
            <AdjustmentsHorizontalIcon className='block h-6 w-6' aria-hidden='true' />
            <span className='text-sm font-light'>Menu</span>
        </button>
    </>
)
