import React, { FC } from 'react'
import { Loan } from 'api/loans/types'
import { Badge } from '@vistacredit/lib-ui'
import { getLoanStatusColor } from 'utils/loanStatus'
import { getDate } from 'utils/date'
import { DocumentMagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { formatCurrency } from 'utils'
import { useGetActiveLoanQuery } from 'api/services/loan/serviceLoan'

interface ITableBodyProps {
    data: Loan[]
    openDetail: (id: number) => void
}

const TableBody: FC<ITableBodyProps> = ({ data, openDetail }) => {
    const activeLoanQuery = useGetActiveLoanQuery()

    return (
        <tbody className='divide-y divide-gray-200 bg-white'>
            {data
                .filter((loan) => loan.id !== activeLoanQuery?.data?.id)
                .map((result: Loan) => (
                    <tr key={result.id}>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                            {result.contract_number}
                        </td>
                        <td
                            className={`whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500 ${
                                result.type === 0 ? 'text-lightPurple' : 'text-lightBlue'
                            }`}>
                            {result.type_text}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500'>
                            {getDate(result.created)}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500'>
                            {formatCurrency(result.amount, 'CZK')}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500'>
                            {getDate(result.due)}
                        </td>
                        <td
                            className='whitespace-nowrap flex justify-center px-3 py-4 text-sm text-center cursor-pointer text-lightPurple hover:text-lightBlue'
                            onClick={() => openDetail(result.id)}>
                            <DocumentMagnifyingGlassIcon className='w-5 h-5 mr-1' />
                            <span>Zobrazit detail</span>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right'>
                            <Badge
                                type={getLoanStatusColor(result.status)}
                                text={result.status_text}
                            />
                        </td>
                    </tr>
                ))}
        </tbody>
    )
}

export default TableBody
