import React, { FC, useEffect } from 'react'
import { PageWrapLayout } from 'layout/components'
import { useLocation, useParams } from 'react-router'
import { faqs } from 'pages/faq/Faq'
import { Link } from 'react-router-dom'
import { classNames } from 'utils'
import { ChevronDownIcon } from '@heroicons/react/24/solid'

const FaqDetail: FC = () => {
    const { id } = useParams()
    const location = useLocation()

    const faq = faqs?.find((item) => item.slug === id)

    useEffect(() => {
        const element = document.getElementById('detail-1')
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' })
        }
    }, [location.pathname])

    if (!faq) {
        return <>Chyba</>
    }

    return (
        <PageWrapLayout>
            <div id={'detail-1'} className='bg-white rounded-xl border border-borderGray'>
                <div className='mx-auto max-w-7xl py-12 pt-4 px-4 sm:py-6 sm:pt-4 sm:px-6 lg:px-8'>
                    <div className='mx-auto max-w-8xl divide-y-2 divide-gray-200'>
                        <div className='mt-2 divide-y divide-gray-200'>
                            <div className='mt-2 pr-12'>
                                <Link
                                    to={'/pomoc'}
                                    className={
                                        'inline-flex  -ml-1.5 py-4 hover:text-lightBlue cursor-pointer font-light'
                                    }>
                                    <ChevronDownIcon
                                        className={classNames(
                                            'rotate-90',
                                            'h-5 w-5 relative top-1',
                                        )}
                                        aria-hidden='true'
                                    />
                                    <span>Zpět</span>
                                </Link>
                                <h2 className='text-xl font-semibold font-Quicksand text-gray-900 sm:text-2xl sm:tracking-tight '>
                                    {faq.question}
                                </h2>
                                <div className='text-base text-justify leading-6 pt-6 font-light w-full sm:max-w-3xl'>
                                    {faq.answer}
                                </div>
                                <Link
                                    to={'/pomoc'}
                                    className={
                                        'inline-flex -ml-1.5 py-4 hover:text-lightBlue cursor-pointer font-light'
                                    }>
                                    <ChevronDownIcon
                                        className={classNames(
                                            'rotate-90',
                                            'h-5 w-5 relative top-1',
                                        )}
                                        aria-hidden='true'
                                    />
                                    <span>Zpět</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageWrapLayout>
    )
}

export default FaqDetail
