import { FC } from 'react'
import { CheckIcon } from '@heroicons/react/24/solid'

interface IUploadedFilePreviewProps {
    file?: File
}

const UploadedFilePreview: FC<IUploadedFilePreviewProps> = ({ file }) => {
    return (
        <div>
            <div className='mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100'>
                <CheckIcon className='h-6 w-6 text-green-600' aria-hidden='true' />
            </div>
            <p className='mt-4'>
                Dokument <span className='text-lightPurple'>{file?.name}</span> jste úspěšně nahrál.
            </p>
        </div>
    )
}

export default UploadedFilePreview
