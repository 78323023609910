import { LoanResponse } from 'api/loans/types'
import { FC } from 'react'
import { Badge, ButtonCommon, ButtonPurple, ButtonWhite } from '@vistacredit/lib-ui'
import { getLoanStatusColor, LoanStatus } from 'utils/loanStatus'
import { formatCurrency } from 'utils/currencyFormatter'
import { getDate } from 'utils/date'
import LoanStatusLoader from 'pages/dashboard/components/LoanStatusLoader'

interface ILoanCardProps {
    loan: LoanResponse | null
    hasDeferral: boolean
    openDetail: (id: number) => void
    setOpenPaymentModal: (open: boolean) => void
    setOpenDeferralModal: (open: boolean) => void
}

const LoanCardMobile: FC<ILoanCardProps> = ({
    loan,
    setOpenPaymentModal,
    setOpenDeferralModal,
    hasDeferral,
    openDetail,
}) => {
    if (!loan) return null

    return (
        <div className='sm:sr-only col-span-12 sm:col-span-12 lg:col-span-8 xl:col-span-6 bg-white rounded-lg border border-borderGray p-6'>
            <div className='grid grid-cols-1 divide-y sm:sr-only'>
                <div className='pb-4'>
                    <div className='mt-3 sm:mt-0 mb-3'>
                        <LoanStatusLoader status={loan?.status} />
                    </div>
                    <div className='flex justify-between items-start'>
                        <div>
                            <div className='mt-2'>
                                <Badge
                                    type={getLoanStatusColor(loan?.status)}
                                    text={loan?.status_text}
                                />
                            </div>
                            <div className='mt-6'>
                                <p className='truncate text-sm font-light text-gray-500 mb-1'>
                                    Typ úvěru
                                </p>
                                <p className='text-lg mb-0 font-normal text-gray-900'>
                                    {loan?.loan_type}
                                </p>
                            </div>
                        </div>
                        <div>
                            <ButtonCommon onClick={() => openDetail(loan?.id)}>
                                Zobrazit detail
                            </ButtonCommon>
                            <div className='mt-3'>
                                <p className='truncate text-right text-sm font-light text-gray-500 mb-1'>
                                    {`ID: ${loan?.contract_number ?? loan?.id}`}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='py-4'>
                    <div className='flex flex-wrap justify-between'>
                        <div className='sm:flex-1 w-25'>
                            <p className='truncate text-sm font-light text-gray-500 mb-1'>
                                Jistina
                            </p>
                            <p className='text-base mb-0 font-normal text-gray-900'>{`${formatCurrency(
                                loan?.amount,
                                'CZK',
                            )}`}</p>
                        </div>
                        <div className='sm:flex-1 w-25 text-right'>
                            <div className='mb-2'>
                                <p className='truncate text-sm font-light text-gray-500 mb-1'>
                                    Poplatek
                                </p>
                                <p className='text-base mb-0 font-normal text-gray-900'>{`${formatCurrency(
                                    loan?.fee,
                                    'CZK',
                                )}`}</p>
                            </div>
                            <div className='sm:flex-1 w-25'>
                                <p className='truncate text-sm text-right font-light text-gray-500 mb-1'>
                                    Ke splacení
                                </p>
                                <p className='text-base mb-0 text-right font-normal text-gray-900'>
                                    {' '}
                                    {`${formatCurrency(loan?.sum, 'CZK')}`}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='py-4'>
                    <div className='flex justify-between'>
                        <div className='sm:flex-1 w-25'>
                            <p className='truncate text-sm font-light text-gray-500 mb-1'>
                                Datum žádosti
                            </p>
                            <p className='text-base mb-0 font-normal text-gray-900'>{`${getDate(
                                loan?.created,
                            )}`}</p>
                        </div>

                        <div className='sm:flex-1 w-25 text-right'>
                            <p className='truncate text-sm font-light text-gray-500 mb-1'>
                                Doba poskytnutí
                            </p>
                            <p className='text-base mb-0 font-normal text-gray-900'>{`${loan?.period} dnů`}</p>
                        </div>
                    </div>
                </div>
                <div className='py-4'>
                    <div className='flex justify-between'>
                        <div className='sm:flex-1 w-25'>
                            <p className='truncate text-sm font-light text-gray-500 mb-1'>
                                Po splatnosti
                            </p>
                            <p className='text-base mb-0 font-normal text-gray-900'>{`${loan?.overdue} dnů`}</p>
                        </div>
                        <div className='sm:flex-1 w-25 text-right'>
                            <p className='truncate text-sm text-right font-light text-gray-500 mb-1'>
                                Splatnost úvěru
                            </p>
                            <p className='text-base mb-0 sm:text-right font-normal text-gray-900'>{`${getDate(
                                loan?.due,
                            )}`}</p>
                        </div>
                    </div>
                </div>
            </div>
            {loan?.status >= LoanStatus.SentToBankAccount && (
                <div className='flex flex-wrap flex-col flex-1 justify-center gap-y-4 lg:flex-row lg:justify-between mt-4'>
                    {loan?.status &&
                        loan?.status !== LoanStatus.Trial &&
                        loan?.status !== LoanStatus.InkasoEOS &&
                        loan?.status !== LoanStatus.BeforeComplaint &&
                        loan?.status !== LoanStatus.InkasoMaxifine && (
                            <ButtonWhite
                                disabled={hasDeferral}
                                className={
                                    'bg-white border border-lightBlue text-lightBlue hover:bg-lightBlue hover:text-white'
                                }
                                onClick={() => setOpenDeferralModal(true)}>
                                Odložit splatnost{' '}
                            </ButtonWhite>
                        )}
                    <ButtonPurple disabled={hasDeferral} onClick={() => setOpenPaymentModal(true)}>
                        Splatit úvěr
                    </ButtonPurple>
                </div>
            )}
        </div>
    )
}

export default LoanCardMobile
