import { Disclosure } from '@headlessui/react'
import { PhoneIcon } from '@heroicons/react/24/outline'
import { Logo } from '@vistacredit/lib-ui'
import { FC } from 'react'

const UnloggedLayout: FC = () => {
    return (
        <Disclosure
            as='header'
            className='bg-transparent rounded-xl border border-borderGray shadow-md sm:border-0 sm:shadow-none sm:rounded-0 sm:bg-transparent py-4 sm:py-6 z-10 relative w-full  '>
            <div className='mx-auto px-2 sm:px-4 lg:px-8'>
                <div className='relative flex h-16 justify-between'>
                    <div className='relative z-10 flex px-2 lg:px-0'>
                        <div className='flex flex-shrink-0 items-center'>
                            <Logo />
                        </div>
                    </div>

                    <div className='relative z-10 lg:ml-4 mr-3 sm:mr-0 flex items-center'>
                        <a
                            rel='noreferrer'
                            href='tel:+420587407707'
                            className='border border-borderGray flex-shrink-0 rounded-full bg-white p-3 text-gray-400 hover:text-lightBlue hover:border-lightBlue'>
                            <PhoneIcon className='h-6 w-' aria-hidden='true' />
                        </a>
                    </div>
                </div>
            </div>
        </Disclosure>
    )
}

export default UnloggedLayout
