import axios, { AxiosInstance } from 'axios'
import AxiosCommon from './axiosCommon'

export function CreateAxiosInstance(store: any): AxiosInstance {
    const ajax = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
    })

    ajax.defaults.headers.common['Content-Type'] = 'application/json'
    AxiosCommon.applyRequestInterceptor(ajax, null, store)

    return ajax
}
