export const createDateString = (date: Date) => {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const dt = date.getDate()

    return dt + '.' + month + '.' + year
}

export const getDate = (date?: number | string | Date | null) => {
    if (!date) return null

    const dateTime = new Date(date)
    return createDateString(dateTime)
}

export const transformDate = (date: string) => {
    return createDateString(new Date(date))
}

export const dateParse = (date: string) => {
    const parsedDate = new Date(date).toLocaleDateString('cs-CZ', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    })
    return parsedDate.replace(/\s+/g, '')
}

export const getBirthDateObject = (birthNumber: string) => {
    const birthDate = {
        year: parseInt(birthNumber.substr(0, 2), 10),
        month: parseInt(birthNumber.substr(2, 2), 10),
        day: parseInt(birthNumber.substr(4, 2), 10),
    }

    // druhe dve pozicie znacia mesiac - u zien nutne odratat 50
    if (birthDate.month > 12) {
        return {
            ...birthDate,
            month: birthDate.month - 50,
        }
    }

    return birthDate
}

export const getBirthDateFromBirthNumber = (birthNumber: string) => {
    const birthDate = getBirthDateObject(birthNumber)

    return getDate(new Date(`${birthDate.year}/${birthDate.month}/${birthDate.day}`))
}

export function isDateOlderThanToday(inputDate: string) {
    // Convert input date to a Date object if it's not already one
    const date = new Date(inputDate)

    // Get today's date with time set to 00:00:00 for accurate comparison
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    // Compare the input date with today's date
    return date < today
}
