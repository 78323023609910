import { FC } from 'react'
import ModalTitle from 'layout/components/modal/shared/ModalTitle'
import ModalFooter from 'layout/components/modal/shared/ModalFooter'
import ModalWrapper from 'layout/components/modal/ModalWrapper'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

interface ICancelationModalProps {
    open: boolean
    setOpen: (value: boolean) => void
    onClick: () => void
    onClose: () => void
}

const CancelationModal: FC<ICancelationModalProps> = ({ open, setOpen, onClick, onClose }) => {
    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <div className='mb-8'>
                <ModalTitle title='Storno žádosti o odložení splatnosti' />
            </div>
            <div className='mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:h-20 sm:w-20'>
                <ExclamationTriangleIcon
                    className='h-8 w-8 sm:h-12 sm:w-12 text-red-600'
                    aria-hidden='true'
                />
            </div>
            <h3 className='mt-2 text-center text-lg font-medium text-gray-900 sm:mt-5 sm:text-xl'>
                Přejete si opravdu stornovat žádost?
            </h3>
            <p className='text-sm text-center text-gray-500 mt-2'>
                Pro stornování žádosti o odklad splatnosti kliknete na tlačidlo{' '}
                <b className='text-lightPurple'>STORNOVAT ODKLAD</b>. V případě, že si žádost
                přejete ponechat, klikněte na tlačidlo <b className='text-red-500'>Zrušit</b>.
            </p>
            <ModalFooter onClick={onClick} onClose={onClose} title={'STORNOVAT ODKLAD'} />
        </ModalWrapper>
    )
}

export default CancelationModal
