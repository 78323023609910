import { FC } from 'react'
import { Customer } from 'api/customers/types'
import { customerCategory, getCustomerInitials } from 'utils'
import { sumPoints } from 'utils/points'
import { getIdentityStatus } from 'utils/getIdentityStatus'
import { Avatar } from 'pages/profile/components/Avatar'
import { useGetPointsListQuery } from 'api/services/utils/points/servicePoints'

interface IProfileHeaderProps {
    customer: Customer
}

const ProfileHeader: FC<IProfileHeaderProps> = ({ customer }) => {
    const pointsListQuery = useGetPointsListQuery()

    return (
        <div className='max-w-3xl px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8'>
            <div className='flex items-center space-x-5'>
                <div className='flex-shrink-0'>
                    <div className='relative'>
                        <Avatar
                            initials={getCustomerInitials(customer)}
                            size={'medium'}
                            customer={customer}
                        />
                        <span
                            className='absolute inset-0 rounded-full shadow-inner'
                            aria-hidden='true'
                        />
                    </div>
                </div>
                <div>
                    <h1 className='text-2xl font-bold text-gray-900'>{`${customer.first_name} ${customer.last_name}`}</h1>
                    <p className='text-sm font-medium text-gray-500'>
                        {`Vista ${customerCategory(customer.category)} - ${sumPoints(
                            pointsListQuery?.data,
                        )} bodů`}
                    </p>
                    <p
                        className={`text-sm font-medium ${
                            getIdentityStatus(customer).status === 'success' && 'text-green-500'
                        } ${
                            getIdentityStatus(customer).status === 'pending' &&
                            'text-status-orangeTextLight'
                        } ${getIdentityStatus(customer).status === 'error' && 'text-red-500'}`}>
                        {getIdentityStatus(customer).text}
                    </p>
                </div>
            </div>
            {/*<div className='justify-stretch mt-6 flex flex-col-reverse space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-y-0 sm:space-x-3 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3'>*/}
            {/*    <ButtonWhite>Odklady</ButtonWhite>*/}
            {/*    <ButtonPurple>Smlouvy</ButtonPurple>*/}
            {/*</div>*/}
        </div>
    )
}

export default ProfileHeader
