import React, { FC } from 'react'
import Question3_1 from 'assets/images/question3_1.png'

const QuestionThree: FC = () => {
    return (
        <div className='space-y-4'>
            <p>
                Služba VistaCredit.cz nikde neprezentuje ceník služeb, který by byl postupem času
                neaktuální. Aktuální ceny jsou vždy součástí kalkulačky, pomocí které si klient
                vybere přímo výši požadovaného úvěru.
            </p>

            <img src={Question3_1} className='w-fit lg:max-w-xl my-3' alt='question3 image' />
            <p>
                Online uvidíte cenu poskytované služby a pokud s ní souhlasíte, odešlete nám návrh
                na uzavření smlouvy o úvěru - žádost.
            </p>
        </div>
    )
}

export default QuestionThree
