import { FC } from 'react'
import { NavLink } from 'react-router-dom'

export const NavLinks: FC = () => {
    const links = [
        { name: 'Kontakt', href: 'kontakt' },
        { name: 'GDPR', href: 'gdpr' },
        { name: 'POMOC', href: 'pomoc' },
    ]

    return (
        <nav aria-label='Global' className='flex space-x-10'>
            {links.map((link) => (
                <NavLink
                    key={link.name}
                    to={link.href}
                    className={({ isActive }) =>
                        isActive ? 'text-lightBlue hover:text-lightBlue' : ' hover:text-lightBlue'
                    }>
                    {link.name}
                </NavLink>
            ))}
        </nav>
    )
}
