export const checkInaccessibleDaysRange = (
    value: number,
    days?: boolean,
    type?: string,
): boolean => {
    if (days && type === 'fix') {
        if (value > 14 && value < 35) {
            return true
        }
    }

    return false
}
