import { formatCurrency } from 'utils'
import Slider from 'layout/components/slider/Slider'
import { DAY_MARKS, PRICE_MARKS_RENT } from 'layout/components/slider/marks'
import { Dispatch, FC, SetStateAction } from 'react'

interface ICalculationProps {
    activeTab: string
    setPriceRange: Dispatch<SetStateAction<number | number[]>>
    setDaysRange: Dispatch<SetStateAction<number | number[]>>
    priceRange: number | number[]
    daysRange: number | number[]
}

const Calculation: FC<ICalculationProps> = ({
    activeTab,
    priceRange,
    daysRange,
    setDaysRange,
    setPriceRange,
}) => {
    return (
        <>
            <h2 className='text-base font-light font-Quicksand sm:text-2xl mt-6 mb-6 sm:mb-2 sm:px-10 flex justify-between'>
                <span className='whitespace-pre-wrap'>
                    Jakou <span className='font-normal'>částku požadujete?</span>
                </span>
                <span className='font-normal'>{formatCurrency(+priceRange, 'CZK')}</span>
            </h2>
            <Slider
                min={activeTab === 'fix' ? 3000 : 4000}
                max={8000}
                marks={PRICE_MARKS_RENT}
                defaultValue={priceRange}
                setSliderValue={setPriceRange}
            />

            <h2 className='text-base font-light font-Quicksand mt-6 mb-6 sm:mb-2 sm:text-2xl sm:px-10 flex justify-between'>
                <span className='whitespace-pre-wrap'>
                    {activeTab === 'fix' ? (
                        <>
                            Za jakou dobu <span className='font-normal'>úvěr splatíte?</span>
                        </>
                    ) : (
                        <>
                            Jak dlouho <span className='font-normal'>budete finance využívat?</span>
                        </>
                    )}
                </span>
                <div className='flex justify-end flex-col'>
                    <div className='flex flex-col sm:flex-row items-end justify-end'>
                        <span className='font-light text-sm sm:mr-3'>splatím do</span>
                        <span className='font-normal whitespace-nowrap'>{daysRange} dní</span>
                    </div>
                    <span className='font-light text-sm text-right'>
                        případně si prodloužím splatnost
                    </span>
                </div>
            </h2>
            <Slider
                min={14}
                max={70}
                marks={DAY_MARKS}
                defaultValue={activeTab === 'fix' ? 35 : 14}
                days={true}
                type={activeTab as 'fix' | 'rent' | 'deferral'}
                setSliderValue={setDaysRange}
            />
        </>
    )
}

export default Calculation
