import React, { FC } from 'react'
import { PageWrapLayout } from 'layout/components'

const ProfileSkeleton: FC = () => {
    return (
        <PageWrapLayout>
            <main className='py-4'>
                {/* Page header */}
                <div role='status' className='w-auto mb-4 animate-pulse'>
                    <div className='flex items-center mt-4 space-x-3'>
                        <svg
                            className='w-14 h-14 fill-bg-borderGray'
                            aria-hidden='true'
                            fill='currentColor'
                            viewBox='0 0 20 20'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                                fillRule='evenodd'
                                d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z'
                                clipRule='evenodd'></path>
                        </svg>
                        <div>
                            <div className='h-2.5 bg-borderGray rounded-full w-32 mb-2'></div>
                            <div className='w-48 h-2 bg-borderGray rounded-full '></div>
                        </div>
                    </div>
                </div>

                <div className='flex flex-col sm:grid sm:grid-cols-12 sm:gap-4'>
                    <div className='cols-span-12 lg:col-span-7'>
                        <div className='sm:col-span-12 lg:col-span-8 xl:col-span-6 bg-white rounded-lg border border-borderGray p-6 sm:not-sr-only sm:p-6 mb-4'>
                            <div role='status' className='w-auto mb-4 animate-pulse'>
                                <div className='h-2.5 bg-borderGray rounded-full  w-48 mb-4'></div>
                                <div className='h-2 bg-borderGray rounded-full  max-w-[360px] mb-2.5'></div>
                                <div className='h-2 bg-borderGray rounded-full  mb-2.5'></div>
                                <div className='h-2 bg-borderGray rounded-full  max-w-[330px] mb-2.5'></div>
                                <div className='h-2 bg-borderGray rounded-full  max-w-[300px] mb-2.5'></div>
                                <div className='h-2 bg-borderGray rounded-full  max-w-[360px]'></div>
                                <span className='sr-only'>Loading...</span>
                            </div>
                            <div role='status' className='w-auto animate-pulse'>
                                <div className='h-2.5 bg-borderGray rounded-full  w-48 mb-4'></div>
                                <div className='h-2 bg-borderGray rounded-full  max-w-[360px] mb-2.5'></div>
                                <div className='h-2 bg-borderGray rounded-full  mb-2.5'></div>
                                <div className='h-2 bg-borderGray rounded-full  max-w-[330px] mb-2.5'></div>
                                <div className='h-2 bg-borderGray rounded-full  max-w-[300px] mb-2.5'></div>
                                <div className='h-2 bg-borderGray rounded-full  max-w-[360px]'></div>
                                <span className='sr-only'>Loading...</span>
                            </div>
                        </div>
                        <div className='h-4'></div>
                        <div className=' sm:col-span-12 lg:col-span-8 xl:col-span-6 bg-white rounded-lg border border-borderGray p-6 sm:not-sr-only sm:p-6'>
                            <div role='status' className='w-auto mb-4 animate-pulse'>
                                <div className='h-2.5 bg-borderGray rounded-full  w-48 mb-4'></div>
                                <div className='h-2 bg-borderGray rounded-full  max-w-[360px] mb-2.5'></div>
                                <div className='h-2 bg-borderGray rounded-full  mb-2.5'></div>
                                <div className='h-2 bg-borderGray rounded-full  max-w-[330px] mb-2.5'></div>
                                <div className='h-2 bg-borderGray rounded-full  max-w-[300px] mb-2.5'></div>
                                <div className='h-2 bg-borderGray rounded-full  max-w-[360px]'></div>
                                <span className='sr-only'>Loading...</span>
                            </div>
                            <div role='status' className='w-auto animate-pulse'>
                                <div className='h-2.5 bg-borderGray rounded-full  w-48 mb-4'></div>
                                <div className='h-2 bg-borderGray rounded-full  max-w-[360px] mb-2.5'></div>
                                <div className='h-2 bg-borderGray rounded-full  mb-2.5'></div>
                                <div className='h-2 bg-borderGray rounded-full  max-w-[330px] mb-2.5'></div>
                                <div className='h-2 bg-borderGray rounded-full  max-w-[300px] mb-2.5'></div>
                                <div className='h-2 bg-borderGray rounded-full  max-w-[360px]'></div>
                                <span className='sr-only'>Loading...</span>
                            </div>
                        </div>
                    </div>
                    <div className='cols-span-12 lg:col-span-4'>
                        <div className=' sm:col-span-12 lg:col-span-8 xl:col-span-6 bg-white rounded-lg border border-borderGray p-6 sm:not-sr-only sm:p-6'>
                            <div role='status' className='w-auto mb-4 animate-pulse'>
                                <div className='h-2.5 bg-borderGray rounded-full  w-48 mb-4'></div>
                                <div className='h-2 bg-borderGray rounded-full  max-w-[360px] mb-2.5'></div>
                                <div className='h-2 bg-borderGray rounded-full  mb-2.5'></div>
                                <div className='h-2 bg-borderGray rounded-full  max-w-[330px] mb-2.5'></div>
                                <div className='h-2 bg-borderGray rounded-full  max-w-[300px] mb-2.5'></div>
                                <div className='h-2 bg-borderGray rounded-full  max-w-[360px]'></div>
                                <span className='sr-only'>Loading...</span>
                            </div>
                            <div role='status' className='w-auto animate-pulse'>
                                <div className='h-2.5 bg-borderGray rounded-full  w-48 mb-4'></div>
                                <div className='h-2 bg-borderGray rounded-full  max-w-[360px] mb-2.5'></div>
                                <div className='h-2 bg-borderGray rounded-full  mb-2.5'></div>
                                <div className='h-2 bg-borderGray rounded-full  max-w-[330px] mb-2.5'></div>
                                <div className='h-2 bg-borderGray rounded-full  max-w-[300px] mb-2.5'></div>
                                <div className='h-2 bg-borderGray rounded-full  max-w-[360px]'></div>
                                <span className='sr-only'>Loading...</span>
                            </div>
                        </div>
                        <div className='h-4'></div>

                        <div className='sm:col-span-12 lg:col-span-8 xl:col-span-6 bg-white rounded-lg border border-borderGray p-6 sm:not-sr-only sm:p-6'>
                            <div role='status' className='w-auto mb-4 animate-pulse'>
                                <div className='h-2.5 bg-borderGray rounded-full  w-48 mb-4'></div>
                                <div className='h-2 bg-borderGray rounded-full  max-w-[360px] mb-2.5'></div>
                                <div className='h-2 bg-borderGray rounded-full  mb-2.5'></div>
                                <div className='h-2 bg-borderGray rounded-full  max-w-[330px] mb-2.5'></div>
                                <div className='h-2 bg-borderGray rounded-full  max-w-[300px] mb-2.5'></div>
                                <div className='h-2 bg-borderGray rounded-full  max-w-[360px]'></div>
                                <span className='sr-only'>Loading...</span>
                            </div>
                            <div role='status' className='w-auto animate-pulse'>
                                <div className='h-2.5 bg-borderGray rounded-full  w-48 mb-4'></div>
                                <div className='h-2 bg-borderGray rounded-full  max-w-[360px] mb-2.5'></div>
                                <div className='h-2 bg-borderGray rounded-full  mb-2.5'></div>
                                <div className='h-2 bg-borderGray rounded-full  max-w-[330px] mb-2.5'></div>
                                <div className='h-2 bg-borderGray rounded-full  max-w-[300px] mb-2.5'></div>
                                <div className='h-2 bg-borderGray rounded-full  max-w-[360px]'></div>
                                <span className='sr-only'>Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </PageWrapLayout>
    )
}

export default ProfileSkeleton
