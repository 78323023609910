import { serviceApi } from 'api/serviceApi'
import { IPriceList, TPriceListType } from 'api/services/utils/pricesLists/types'

const priceListsApi = serviceApi.injectEndpoints({
    endpoints: (build) => ({
        getPriceList: build.query<IPriceList[], TPriceListType>({
            query: (type) => `price-list/${type}/`,
        }),
    }),
    overrideExisting: false,
})

export const { useGetPriceListQuery } = priceListsApi
