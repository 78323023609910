import { FC, useEffect } from 'react'
import { BadgePapers, Loader } from '@vistacredit/lib-ui'
import { useGetContractsToSignQuery } from 'api/services/customer/serviceContracts'
import { useAppSelector } from 'store/store'

interface ILoanStatusLoader {
    status: number
}

const LoanStatusLoader: FC<ILoanStatusLoader> = ({ status }) => {
    const contractsQuery = useGetContractsToSignQuery()
    const contractsProcess = useAppSelector((state) => state.app.contractsProcess)

    useEffect(() => {
        contractsQuery.refetch()
    }, [status])

    switch (contractsProcess?.type) {
        case 'OP':
            return (
                <BadgePapers
                    type={'status_orangeLight'}
                    text={
                        <>
                            <Loader fill={'#F2994A'} />
                            <span>{contractsProcess?.message}</span>
                        </>
                    }
                />
            )
        case 'GENERATION':
            return (
                <BadgePapers
                    type={'status-greenLight'}
                    text={
                        <>
                            <Loader fill={'#28a745'} />
                            <span>{contractsProcess?.message}</span>
                        </>
                    }
                />
            )
        case 'PENDING_SIGNATURE':
        case 'CLIENT_PENDING':
        case 'VISTA_PENDING':
            return (
                <BadgePapers
                    type={'status_orangeLight'}
                    text={
                        <>
                            <Loader fill={'#F2994A'} />
                            <span>{contractsProcess?.message}</span>
                        </>
                    }
                />
            )
        case 'REJECTED':
            return (
                <BadgePapers
                    type={'status-redLight'}
                    text={
                        <>
                            <Loader fill={'#EB5757'} />
                            <span>{contractsProcess?.message}</span>
                        </>
                    }
                />
            )
        default:
            return <></>
    }
}

export default LoanStatusLoader
