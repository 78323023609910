import { FC } from 'react'
import { ButtonPurple, ButtonWhite } from '@vistacredit/lib-ui'
import { formatCurrency } from 'utils/currencyFormatter'
import { getDate } from 'utils/date'
import { IDeferralOrder } from 'api/services/loan/types'

interface IDeferralCardProps {
    deferral?: IDeferralOrder
    stornoDeferral: () => void
    payDeferral: () => void
}

const DeferralCardMobile: FC<IDeferralCardProps> = ({ deferral, stornoDeferral, payDeferral }) => {
    return (
        <div className='sm:sr-only col-span-12 sm:col-span-12 lg:col-span-8 xl:col-span-6 bg-white rounded-lg border border-borderGray p-6'>
            <div className='grid grid-cols-1 divide-y sm:sr-only'>
                <div className='pb-4'>
                    <div className='flex justify-between items-start'>
                        <div className='mt-3'>
                            <p className='truncate text-sm font-light text-gray-500 mb-1'>
                                Odložení splatnosti úvěru
                            </p>
                        </div>
                        <div>
                            <div className='mt-3'>
                                <p className='truncate text-right text-sm font-light text-gray-500 mb-1'>
                                    {`ID: ${deferral?.order_id}`}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='py-4'>
                    <div className='flex flex-wrap justify-between'>
                        <div className='sm:flex-1 w-25'>
                            <p className='truncate text-sm font-light text-gray-500 mb-1'>
                                Délka odložení
                            </p>
                            <p className='text-base mb-0 font-normal text-gray-900'>
                                {`${deferral?.days} dnů`}
                            </p>
                        </div>
                        <div className='sm:flex-1 w-25 text-right'>
                            <div className='mb-2'>
                                <p className='truncate text-sm font-light text-gray-500 mb-1'>
                                    Cena za odložení
                                </p>
                                <p className='text-base mb-0 font-normal text-gray-900'>{`${formatCurrency(
                                    deferral?.deferral_amount,
                                    'CZK',
                                )}`}</p>
                            </div>
                            {/*<div className='sm:flex-1 w-25'>*/}
                            {/*    <p className='truncate text-sm text-right font-light text-gray-500 mb-1'>*/}
                            {/*        Celkem*/}
                            {/*    </p>*/}
                            {/*    <p className='text-base mb-0 text-right font-normal text-gray-900'>*/}
                            {/*        {' '}*/}
                            {/*        /!*{`${formatCurrency(loan.sum, 'CZK')}`}*!/*/}
                            {/*    </p>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
                <div className='py-4'>
                    <div className='flex justify-between'>
                        <div className='sm:flex-1 w-25'>
                            <p className='truncate text-sm font-light text-gray-500 mb-1'>
                                Datum žádosti o úvěr
                            </p>
                            <p className='text-base mb-0 font-normal text-gray-900'>{`${getDate(
                                deferral?.created,
                            )}`}</p>
                        </div>

                        <div className='sm:flex-1 w-25 text-right'>
                            <p className='truncate text-sm font-light text-gray-500 mb-1'>Celkem</p>
                            <p className='text-base mb-0 font-normal text-gray-900'>{`${formatCurrency(
                                deferral?.deferral_amount,
                                'CZK',
                            )}`}</p>
                        </div>
                    </div>
                </div>
                <div className='py-4'>
                    <div className='flex justify-between'>
                        <div className='sm:flex-1 w-25 text-left'>
                            <p className='truncate text-sm font-light text-gray-500 mb-1'>
                                Splatnost úvěru po odložení
                            </p>
                            <p className='text-base mb-0 font-normal text-gray-900'>{`${getDate(
                                deferral?.new_due_date,
                            )}`}</p>
                        </div>
                        <div className='sm:flex-1 w-25 text-right'>
                            <p className='truncate text-sm font-light text-gray-500 mb-1'>
                                Poplatek uhraďte do
                            </p>
                            <p className='text-base mb-0 font-normal text-gray-900'>{`${getDate(
                                deferral?.pay_due_date,
                            )}`}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-wrap flex-col gap-y-4 flex-1 justify-center items-center mt-4 w-100'>
                <ButtonWhite
                    style={{ width: '100%' }}
                    className='bg-white w-100 border border-lightBlue text-lightBlue hover:bg-lightBlue hover:text-white'
                    onClick={stornoDeferral}>
                    Stornovat žádost o odložení
                </ButtonWhite>
                <ButtonPurple style={{ width: '100%' }} onClick={payDeferral} className={'w-100'}>
                    Zaplatit poplatek a odložit splatnost
                </ButtonPurple>
            </div>
        </div>
    )
}

export default DeferralCardMobile
