import { Customer } from 'api/customers/types'

export const getCustomerInitials = (customer: Customer): string => {
    if (!customer) {
        return ''
    }
    const { first_name, last_name } = customer
    const initials = `${first_name[0]}${last_name[0]}`
    return initials.toUpperCase()
}
