import { FC } from 'react'
import { ButtonPurple } from '@vistacredit/lib-ui'
import { Customer } from 'api/customers/types'
import { formatCurrency } from 'utils'
import { useNavigate } from 'react-router'
import { routerCatalog } from 'routes/hooks/useSecureRouting'

interface IProfileFinanceProps {
    customer: Customer
}

const ProfileFinance: FC<IProfileFinanceProps> = ({ customer }) => {
    const navigate = useNavigate()
    return (
        <div className='bg-white px-4 mt-6 py-5 border border-borderGray rounded-lg sm:px-6'>
            <h2 id='timeline-title' className='text-lg font-medium text-gray-900'>
                Finance
            </h2>

            {/* Activity Feed */}
            <div className='mt-6 flow-root'>
                <dl className='grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2'>
                    <div className='sm:col-span-1 md:col-span-2'>
                        <dt className='text-sm font-medium text-gray-500'>
                            Měsíční příjem z činnosti
                        </dt>
                        <dd className='mt-1 text-sm text-gray-900'>
                            {formatCurrency(customer.monthly_income, 'CZK')}
                        </dd>
                    </div>
                    <div className='sm:col-span-1 md:col-span-2'>
                        <dt className='text-sm font-medium text-gray-500'>
                            Měsíční výdaje v rámci činnosti
                        </dt>
                        <dd className='mt-1 text-sm text-gray-900'>
                            {formatCurrency(customer.monthly_payments, 'CZK')}
                        </dd>
                    </div>
                    <div className='sm:col-span-1 md:col-span-2'>
                        <dt className='text-sm font-medium text-gray-500'>Jiné splátky</dt>
                        <dd className='mt-1 text-sm text-gray-900'>
                            {formatCurrency(customer.monthly_loans, 'CZK')}
                        </dd>
                    </div>
                </dl>
            </div>
            {/*<div className='justify-stretch mt-6 flex flex-col'>*/}
            {/*    <ButtonPurple onClick={() => navigate(routerCatalog.settings)}>*/}
            {/*        Upravit finance*/}
            {/*    </ButtonPurple>*/}
            {/*</div>*/}
        </div>
    )
}

export default ProfileFinance
