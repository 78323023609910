// because of the smoothness purpose of the slider, we need to round the value to the nearest step
// we recognize two types of roundings: Days and Price
// when type is defined, the function will round the value to the nearest multiplies of 7
// because of the defined type, which can be either "days" or "undefined"
// In case that the type is not defined, the function will round the value to the nearest thousand
export const roundSliderValue = (value: number, type?: boolean) => {
    if (!type) {
        return Math.floor(value / 1000) * 1000
    }

    return Math.floor(value / 7.0) * 7
}
