import React, { FC } from 'react'
import Question1_1 from 'assets/images/question1.png'
import { Link } from 'react-router-dom'

const QuestionOne: FC = () => {
    return (
        <div className='space-y-4'>
            <p>
                Vista v současné době nabízí službu VistaFIX, VistaRENT a službu Odložení
                splatnosti. Jedná se o dvě finanční služby a jednu službu doplňkovou.
            </p>
            <p>
                <b>VistaFIX</b> je standardní podnikatelský účelový úvěr ve výši od 4 000 Kč do výše
                8 000 Kč. Individuálně může být klientovi posouzen úvěr do výše 25 000 Kč.
            </p>
            <p>
                <b>VistaRENT</b> je služba pro VIP klienty a jedná se o pronájem finanční hotovosti,
                kdy klient nehradí žádné poplatky za poskytnutí úvěru ani jiné úroky. Službu je
                možné aktivovat v klientském účtu, pokud je klient v kategorii VistaBusiness. Tato
                služba je detailně popsána{' '}
                <Link
                    className={'hover:text-lightBlue hover:underline'}
                    to='/pomoc/co-je-sluzba-rent'>
                    <b>ZDE</b>
                </Link>
                .
            </p>
            <p>
                <b>Doplňkovou službu</b> - Odložení splatnosti je možné uplatnit o všech typů našich
                úvěrů a je detailně popsána{' '}
                <Link
                    className={'hover:text-lightBlue hover:underline'}
                    to='/pomoc/jak-a-kde-zmenim-dobu-splatnosti-meho-aktivniho-uveru'>
                    <b>ZDE</b>
                </Link>
                .
            </p>
            <p>
                Kompletní přehled našich finančních služeb jsme přehledně popsali na samostatné{' '}
                <b>prezentaci pod tímto bannerem v klientském účtu</b>...
            </p>
            <img src={Question1_1} className='w-fit lg:max-w-xl my-3' alt='question1 image' />

            <p>
                Můžete nám také poslat dotaz přes <b>Kontaktní formulář</b> výběrem volby :{' '}
                <b>Vlastní dotaz, námět, prosba, pochvala, kritika</b>.
            </p>
            {/*<img src={Question1_2} className='max-w-xl my-3' alt='question1 image' />*/}
        </div>
    )
}

export default QuestionOne
