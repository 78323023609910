import { Dispatch, FC, SetStateAction } from 'react'
import { renderIcon } from 'pages/dashboard/utils/renderIcon'
import { LoanResponse } from 'api/loans/types'
import { DeferralOrderResponse } from 'api/orders/types'
import {
    usePayDeferralOrderByPointsMutation,
    usePayOrderMutation,
    usePayOrderPostMutation,
} from 'api/services/loan/serviceOrders'
import { useSendPaymentInfoMutation } from 'api/services/loan/serviceLoan'
import { setNotification } from 'store/stateReducer'
import { useDispatch } from 'react-redux'
import { classNames } from 'utils'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { RadioGroup } from '@headlessui/react'

interface IPaymentMethodFormProps {
    setShowQRCode: () => void
    type: 'loan' | 'deferral'
    loan?: LoanResponse | null
    amount: number
    order_id: number
    deferral?: DeferralOrderResponse
    selectedMethod: { sid: string; text: string; method: () => Promise<void> | void | null }
    setMethod: Dispatch<
        SetStateAction<{ sid: string; text: string; method: () => Promise<void> | void | null }>
    >

    onClose(): void
}

const PaymentMethodForm: FC<IPaymentMethodFormProps> = ({
    setShowQRCode,
    deferral,
    type,
    loan,
    amount,
    order_id,
    onClose,
    selectedMethod,
    setMethod,
}) => {
    const [payOrderPost] = usePayOrderPostMutation()
    const [payOrder] = usePayOrderMutation()
    const [payDeferralOrderByPoints] = usePayDeferralOrderByPointsMutation()
    const [sendPaymentInfo] = useSendPaymentInfoMutation()
    const dispatch = useDispatch()

    const getButtonText = (sid: string) => {
        switch (sid) {
            case 'email_payment':
                return 'Odeslat'
            case 'bonus_payment':
                return 'Použít body'
            default:
                return 'Uhradit'
        }
    }

    const options = [
        {
            id: 1,
            sid: 'card_payment',
            title: 'Platba kartou',
            Icon: 'CreditCardIcon',
            onClick: async () => {
                if (type === 'loan') {
                    await payOrderPost({
                        order_id: loan?.order_id ?? 0,
                        amount: amount,
                        next_url: `${window.location.origin}/aktivni-uver`,
                    }).then((res: any) => {
                        window.location.href = res?.data.redirect_url
                    })
                } else {
                    await payOrder({
                        order_id,
                        amount: amount,
                        next_url: `${window.location.origin}/aktivni-uver`,
                    }).then((res: any) => {
                        window.location.href = res?.data.redirect_url
                    })
                }
            },
        },
        {
            id: 2,
            sid: 'qr_payment',
            title: 'Platba převodem',
            Icon: 'QrCodeIcon',
            onClick: () => setShowQRCode(),
        },
        {
            id: 3,
            sid: 'email_payment',
            title: 'Odeslat informace na e-mail',
            Icon: 'EnvelopeIcon',
            onClick: async () => {
                try {
                    await sendPaymentInfo(loan?.id as number)
                    dispatch(
                        setNotification({
                            type: 'success',
                            title: 'To se povedlo!',
                            message: `Platební informace jsme Vám odeslali na Váš e-mail.`,
                        }),
                    )
                    onClose()
                } catch (err) {
                    console.log(err)
                    dispatch(
                        setNotification({
                            type: 'error',
                            title: 'Něco se nepovedlo!',
                            message: `Platební informace jsme Vám nedokázali poslat na Váš e-mail. Prosím skuste to později nebo nás kontaktujte.`,
                        }),
                    )
                }
            },
        },
    ]

    if (deferral?.can_be_paid_with_points) {
        options.push({
            id: 4,
            sid: 'bonus_payment',
            title: 'Zaplatit bonusovými body',
            Icon: 'CurrencyDolarIcon',
            onClick: async () => {
                try {
                    await payDeferralOrderByPoints()
                    dispatch(
                        setNotification({
                            type: 'success',
                            title: 'To se povedlo!',
                            message: `Odklad byl úspěšně zaplacen body.`,
                        }),
                    )
                    onClose()
                } catch (err) {
                    console.log(err)
                    dispatch(
                        setNotification({
                            type: 'error',
                            title: 'Něco se nepovedlo!',
                            message: `Platba odkladu za pomocí bodů se nepovedla. Prosím skuste to později nebo nás kontaktujte.`,
                        }),
                    )
                }
            },
        })
    }

    return (
        <>
            <h3 className='text-center mb-3 -mt-3'>Výběr spůsobu platby</h3>
            {/*<div className='mt-4 grid grid-cols-1 gap-y-3 sm:grid-cols-3 sm:gap-x-4 sm:items-stretch'>*/}
            {/*    {options.map((option) => (*/}
            {/*        <div key={option.id} onClick={option.onClick}>*/}
            {/*            <div className='flex h-full sm:flex-col sm:justify-center items-center cursor-pointer p-3 text-base border border-borderGray font-bold text-gray-900 rounded-lg bg-white hover:bg-lightBlue group hover:shadow hover:text-white'>*/}
            {/*                {renderIcon(option.Icon)}*/}
            {/*                <span className='ml-2 text-wrap sm:text-center'>{option.title}</span>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    ))}*/}
            {/*</div>*/}
            <RadioGroup value={selectedMethod.sid}>
                <div className='mt-4 grid grid-cols-1 gap-y-3 sm:grid-cols-3 sm:gap-x-4'>
                    {options.map((option) => (
                        <RadioGroup.Option
                            key={option.sid}
                            value={option.sid}
                            onClick={() => {
                                setMethod({
                                    sid: option.sid,
                                    text: getButtonText(option.sid),
                                    method: option.onClick,
                                })
                            }}
                            className={({ checked, active }) =>
                                classNames(
                                    checked ? 'border-transparent' : 'border-borderGray',
                                    active ? 'border border-indigo-500 ring-2 ring-indigo-500' : '',
                                    'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none focus:ring-0 hover:border-lightPurple hover:ring-1 hover:ring-lightPurple',
                                )
                            }>
                            {({ checked, active }) => (
                                <>
                                    <span className='flex flex-1'>
                                        <span className='flex flex-col flex-wrap'>
                                            <span className={'max-h-12 mb-2'}>
                                                {renderIcon(option.Icon)}
                                            </span>
                                            <RadioGroup.Label
                                                as='span'
                                                className='block text-sm font-normal text-gray-900 mr-2'>
                                                {option.title}
                                            </RadioGroup.Label>
                                            {/*    <RadioGroup.Description*/}
                                            {/*        as='span'*/}
                                            {/*        className='flex items-center text-sm font-light text-lightGray'>*/}
                                            {/*    </RadioGroup.Description>*/}
                                        </span>
                                    </span>
                                    <CheckCircleIcon
                                        className={classNames(
                                            !checked ? 'invisible' : '',
                                            'h-5 w-5 text-lightPurple',
                                        )}
                                        aria-hidden='true'
                                    />
                                    <span
                                        className={classNames(
                                            active ? 'border-2' : 'border-2',
                                            checked ? 'border-lightPurple' : 'border-transparent',
                                            'pointer-events-none absolute -inset-px rounded-lg',
                                        )}
                                        aria-hidden='true'
                                    />
                                </>
                            )}
                        </RadioGroup.Option>
                    ))}
                </div>
            </RadioGroup>
        </>
    )
}
export default PaymentMethodForm
