import { FC } from 'react'
import { IImageProps } from 'assets/images/types'

import TheoKeys from 'assets/images/thekeys.svg'

const TheoLogin: FC<IImageProps> = ({ ...rest }) => {
    return <img src={TheoKeys} {...rest} alt='Theo Keys' />
}

export default TheoLogin
