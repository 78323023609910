export const PRICE_MARKS = [
    { value: 5000 },
    { value: 7500 },
    { value: 10000 },
    { value: 12500 },
    { value: 15000 },
    { value: 17500 },
    { value: 20000 },
    { value: 23000 },
]
export const PRICE_MARKS_RENT = [
    { value: 3500 },
    { value: 4150 },
    { value: 4950 },
    { value: 5600 },
    { value: 6250 },
    { value: 6900 },
    { value: 7550 },
]
export const DAY_MARKS = [
    { value: 19.6 },
    { value: 25.2 },
    { value: 30.8 },
    { value: 36.4 },
    { value: 42 },
    { value: 47.6 },
    { value: 53.2 },
    { value: 58.8 },
    { value: 64.4 },
]
export const DEFERRED_MARKS = [
    { value: 7 },
    { value: 14 },
    { value: 21 },
    { value: 28 },
    { value: 35 },
]
