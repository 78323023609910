import { ajax } from 'api/index'
import { PaymentRentResponse, PaymentsListResponse } from 'api/payments/types'

interface IPaymentsApi {
    paymentsList: (loan_id: number) => Promise<PaymentsListResponse>
    paymentsRent: () => Promise<PaymentRentResponse>
}

export const usePaymentsApi = (): IPaymentsApi => {
    const paymentsList = async (loan_id: number): Promise<PaymentsListResponse> => {
        try {
            const res = await ajax.get(`payments/?loan_id=${loan_id}`)
            return Promise.resolve(res.data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const paymentsRent = async (): Promise<PaymentRentResponse> => {
        try {
            const res = await ajax.post(`payments/rent-activate/`, {
                next_url: `${window.location.origin}/aktivni-uver/rent`,
            })
            return Promise.resolve(res.data)
        } catch (error) {
            return Promise.reject(error)
        }
    }
    return {
        paymentsList,
        paymentsRent,
    }
}
