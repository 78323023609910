import { serviceApi } from 'api/serviceApi'
import {
    IDeferralOrder,
    IDeferralOrderPost,
    IOrderPayment,
    IOrdersList,
    IPayOrder,
} from 'api/services/loan/types'

const ordersApi = serviceApi.injectEndpoints({
    endpoints: (build) => ({
        getDeferralOrderDetail: build.mutation<IDeferralOrder, number>({
            query: (id) => `orders/deferral/?loan_id=${id}`,
            invalidatesTags: ['Deferral'],
        }),
        getOrdersToPay: build.query<IOrdersList, number>({
            query: (loan_id) => `orders/to-pay/?loan_id=${loan_id}`,
            providesTags: ['OrdersToPay'],
        }),
        createDeferralOrder: build.mutation<IDeferralOrderPost, number>({
            query: (payload) => {
                return {
                    url: `orders/deferral/`,
                    method: 'POST',
                    body: { days: payload },
                }
            },
            invalidatesTags: ['Deferral', 'Loan'],
        }),
        payDeferralOrderByPoints: build.mutation<void, void>({
            query: () => {
                return {
                    url: `orders/deferral/payment/points/`,
                    method: 'POST',
                }
            },
            invalidatesTags: ['Deferral', 'Loan', 'OrdersToPay'],
        }),
        cancelDeferralOrder: build.mutation<void, number>({
            query: (id) => {
                return {
                    url: `orders/deferral/${id}/cancel/`,
                    method: 'POST',
                }
            },
            invalidatesTags: ['Deferral', 'Loan'],
        }),
        payOrder: build.mutation<IPayOrder, IOrderPayment>({
            query: (data) => {
                return {
                    url: `orders/payment/?order_id=${data.order_id}&amount=${data.amount}`,
                    body: data,
                    method: 'POST',
                }
            },
            invalidatesTags: ['Deferral', 'Loan', 'OrdersToPay'],
        }),
        payOrderPost: build.mutation<any, IOrderPayment>({
            query: (payload) => {
                return {
                    url: `orders/payment/`,
                    method: 'POST',
                    body: payload,
                }
            },
            invalidatesTags: ['Deferral', 'Loan', 'OrdersToPay'],
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetDeferralOrderDetailMutation,
    useGetOrdersToPayQuery,
    useCreateDeferralOrderMutation,
    useCancelDeferralOrderMutation,
    usePayDeferralOrderByPointsMutation,
    usePayOrderMutation,
    usePayOrderPostMutation,
} = ordersApi
