import React, { FC } from 'react'
import Question7_1 from 'assets/images/question7_1.png'
import Question7_2 from 'assets/images/question7_2.png'

const QuestionSeven: FC = () => {
    return (
        <div className='space-y-4'>
            <p>
                Znamená to, že <b>doposud jste nedoložil(a) všechny potřebné dokumenty</b>, na
                základě kterých bychom ověřili, že na druhé straně sedíte opravdu vy.{' '}
                <b>Současně jste neprošel(a) procesem Ověření identity kliknutím na banner:</b>
            </p>
            <img src={Question7_1} className='w-fit lg:max-w-xl my-3' alt='question3 image' />

            <p>
                <b>Tuto službu pro nás zajišťuje společnost Signi.com</b>, která je špičkou v
                oblasti elektronického zpracování informací na internetu.{' '}
                <b>Služba Ověření identity je pro Vás zdarma !</b>
            </p>
            <img src={Question7_2} className='max-w-xs mx-auto my-3' alt='question3 image' />

            <p>
                <b>Ptáte se proč to děláme ?</b>
            </p>

            <p>
                Vysvětlíme Vám to velice jednoduše. Za roky provozu naší služby bychom z falešných a
                jinak upravených dokumentů, které nám někteří žadatelé poskytli, mohli sestavit
                knihu hříchů :-).
            </p>
            <p>
                Není to náš koníček, ale opravdu potřebujeme mít jistotu, s kým uzavíráme smlouvu a
                komu posíláme peníze.
            </p>
            <p>
                <b>
                    Proto se snažíme maximálně předcházet podvodnému jednání a tak chráníme i Vaše
                    osobní údaje.
                </b>
            </p>
        </div>
    )
}

export default QuestionSeven
