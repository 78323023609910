import { Navigate, Route, Routes } from 'react-router'
import Dashboard from 'pages/dashboard/Dashboard'
import Profile from 'pages/profile/Profile'
import Settings from 'pages/settings/Settings'
import Login from 'pages/login/Login'
import Layout from 'layout/Layout'
import Contact from 'pages/contact/Contact'
import Faq from 'pages/faq/Faq'
import Gdpr from 'pages/gdpr/Gdpr'
import Bonuses from 'pages/bonuses/Bonuses'
import Loans from 'pages/loans/Loans'
import Loading from 'pages/login/Loading'
import { routerCatalog } from 'routes/hooks/useSecureRouting'
import Logout from 'pages/login/Logout'
import NewLoan from 'pages/newloan/NewLoan'
import Pricing from 'pages/pricing/Pricing'
import Documents from 'pages/documents/Documents'
import Error404 from 'pages/error/Error404'
import Error500 from 'pages/error/Error500'
import ErrorLayout from 'layout/ErrorLayout'
import ForgotPassword from 'pages/login/ForgotPassword'
import FaqDetail from 'pages/faq/FaqDetail'

export const RoutesCatalog = () => {
    return (
        <Routes>
            <Route path='/' element={<Layout />}>
                <Route
                    path={routerCatalog.root}
                    element={<Navigate to={routerCatalog.dashboard} />}
                />
                <Route path={routerCatalog.dashboard} element={<Dashboard />} />
                <Route path={routerCatalog.dashboardRent} element={<Dashboard />} />
                <Route path={routerCatalog.newLoan} element={<NewLoan />} />
                <Route path={routerCatalog.profile} element={<Profile />} />
                <Route path={routerCatalog.settings} element={<Settings />} />
                <Route path={routerCatalog.loans} element={<Loans />} />
                <Route path={routerCatalog.bonuses} element={<Bonuses />} />
                <Route path={routerCatalog.contact} element={<Contact />} />
                <Route path={routerCatalog.gdpr} element={<Gdpr />} />
                <Route path={routerCatalog.faq} element={<Faq />} />
                <Route path={routerCatalog.faqDetai} element={<FaqDetail />} />
                <Route path={routerCatalog.pricing} element={<Pricing />} />
                <Route path={routerCatalog.documents} element={<Documents />} />
            </Route>
            <Route path={routerCatalog.login} element={<Login />} />
            <Route path={routerCatalog.forgotPassw} element={<ForgotPassword />} />
            <Route path={routerCatalog.loading} element={<Loading withText />} />
            <Route path={routerCatalog.logout} element={<Logout />} />
            <Route path={'/chyba/'} element={<ErrorLayout />}>
                <Route path={routerCatalog.err404} element={<Error404 />} />
                <Route path={routerCatalog.err500} element={<Error500 />} />
            </Route>
            <Route path={'*'} element={<Navigate to={routerCatalog.err404} />} />
        </Routes>
    )
}
