import { useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router'

export const useLogoutOnInactivity = (excludedOn: string[], LOGOUT_TIMEOUT: number) => {
    const location = useLocation()
    const logoutTimerRef = useRef<any>(null)
    const navigate = useNavigate()

    const onTimeOutLogout = (): void => {
        navigate('/odhlaseni', { replace: true })
    }

    const createInterval = () => {
        logoutTimerRef.current = setInterval(onTimeOutLogout, LOGOUT_TIMEOUT)

        window.addEventListener('click', (): void => {
            if (logoutTimerRef.current) {
                clearInterval(logoutTimerRef.current)

                logoutTimerRef.current = setInterval(onTimeOutLogout, LOGOUT_TIMEOUT)
            }
        })
    }

    useEffect(() => {
        const isExcluded = excludedOn.some((item) => location.pathname.includes(item))
        if (!isExcluded) {
            createInterval()
        }

        return () => {
            clearInterval(logoutTimerRef.current)
            logoutTimerRef.current = null
        }
    }, [location.pathname])
}
