import ModalTitle from 'layout/components/modal/shared/ModalTitle'
import ModalFooter from 'layout/components/modal/shared/ModalFooter'
import { FC, useState } from 'react'
import ModalWrapper from 'layout/components/modal/ModalWrapper'
import IDcard from '../assets/idCard.svg'
import { CustomerPapers } from 'api/customers/types'
import { ICustomerPapers, TCustomer } from 'api/services/customer/types'

interface IVerificationModalProps {
    setOpen: (value: boolean) => void
    open: boolean
    handleCreateLoan: () => void
    handleCloseModal: () => void
    customer?: TCustomer
    customerPapers?: ICustomerPapers[]
}

const VerificationModal: FC<IVerificationModalProps> = ({
    open,
    setOpen,
    handleCloseModal,
    handleCreateLoan,
    customer,
    customerPapers,
}) => {
    const [disabled, setDisabled] = useState(false)

    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <div className='mb-8'>
                <ModalTitle title='Kontrola osobních údajů' />
                <p className='text-center'>
                    Překontrolujte si prosím své osobní údaje, vložené dokumenty a ostatní údaje k
                    Vaší osobě. Pokud víte, <b className='text-lightPurple'>že je vše v pořádku</b>,
                    klikněte na tlačítko <b className='text-lightPurple'>ODESLAT ŽÁDOST</b>
                </p>

                <div className='text-center mt-4'>
                    <p>{`${customer?.first_name} ${customer?.last_name}`}</p>
                    <p className='font-light'>{`${customer?.business.address.street} ${customer?.business.address.house_number} ${customer?.business.address.city} ${customer?.business.address.postal_code}`}</p>
                </div>

                <div className='flex justify-center flex-wrap items-center mt-4'>
                    {customerPapers
                        ?.filter((paper: CustomerPapers) => paper.type === 0)
                        .map((paper: CustomerPapers) => (
                            <a key={paper.id} rel='noreferrer' href={paper.file} target='_blank'>
                                <img
                                    src={IDcard}
                                    className='h-20 sm:h-28 m-2 cursor-pointer hover:shadow-lg rounded-lg'
                                    alt='ID card placeholder'
                                />
                            </a>
                        ))}
                    <p className='text-xs font-light'>
                        Pro zobrazení dokladu kliknete na jednotlivé ilustrace dokladu.
                    </p>
                </div>
            </div>

            <ModalFooter
                onClick={() => {
                    setDisabled(true)
                    handleCreateLoan()
                }}
                onClose={handleCloseModal}
                loading={true}
                disabled={disabled}
                loadingText='Odesílám žádost'
                title={'ODESLAT ŽÁDOST'}
            />
        </ModalWrapper>
    )
}

export default VerificationModal
