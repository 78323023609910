import React, { FC } from 'react'

const QuestionEighteen: FC = () => {
    return (
        <div className='space-y-4'>
            <p>
                <b>Tato informace je pro nás důležitá z pohledu vyplácení financí</b>. Pokud byste
                nám změnu neoznámil(a), budeme Vám vyplácet peníze na pravděpodobně již neexistující
                účet a s tím budou nemalé komplikace.
            </p>

            <p>
                <b>Sám(a) si číslo účtu změnit nemůžete</b>. Tuto prácičku za Vás udělá
                administrátor, pokud doložíte nejdříve novou kopii výpisu z b.ú. (výpis můžete
                poslat na @mail:{' '}
                <a href='mailto:uver@vistacredit.cz' className='underline text-lightBlue'>
                    uver@vistacredit.cz
                </a>{' '}
                ).
            </p>

            <p>
                Následně můžete být vyzván k verifikaci (ověření Vašeho účtu) v systému našeho
                partnera signi.cz.{' '}
            </p>

            <p>
                Můžete také nový výpis z b.ú. vložit (nahrát) přímo v klientském účtu naší aplikace.
                Tím se aktuálně schválený dokument (výpis) deaktivuje a administrátor při posouzení
                další žádosti číslo účtu upraví a dokument schválí.
            </p>
            <p>
                Můžete nám také poslat dotaz přes <b>Kontaktní formulář</b> výběrem volby :{' '}
                <b>Vlastní dotaz, námět, prosba, pochvala, kritika</b>.
            </p>

            {/*<img src={Question1_2} className='max-w-xl my-3' alt='question3 image' />*/}
        </div>
    )
}

export default QuestionEighteen
