import React, { FC } from 'react'
import { Link } from 'react-router-dom'

interface INavItemProps {
    Icon: any
    title: string
    href: string
}

const NavItem: FC<INavItemProps> = ({ Icon, title, href }) => {
    return (
        <Link to={href}>
            <div className='flex flex-col justify-center items-center content-center hover:text-lightBlue'>
                <Icon className='h-6 w-6 text-primary' />
                <span className='text-sm font-light'>{title}</span>
            </div>
        </Link>
    )
}

export default NavItem
