import React, { FC } from 'react'
import Question9_1 from 'assets/images/question9_1.png'
import { Link } from 'react-router-dom'

const QuestionNine: FC = () => {
    return (
        <div className='space-y-4'>
            <p>
                Následuj pár kroků,{' '}
                <b>které musíte dodržet, abychom mohli Vaši žádost dále posoudit:</b>
            </p>

            <ul className=' space-y-3 pl-10 list-disc '>
                <li>
                    <b>přihlaste se k Vašemu účtu</b> - na adrese{' '}
                    <a href='https://klient.vistaredit.cz' rel={'noreferrer'} target={'_blank'}>
                        klient.vistaredit.cz
                    </a>
                </li>
                <li>
                    <b>nahrajte požadované dokumenty</b> - obě strany osobního dokladu (občanský
                    průkaz + přední strana výpisu z bankovního účtu)
                </li>
            </ul>

            <p>
                <b>
                    Během procesu, kdy budete nahrávat dokumenty si Vaší žádost převezme
                    administrátor
                </b>
                , který Vám následně odešle přímo do Vašeho účtu{' '}
                <b>požadavek na Ověření Vaší identity</b>- zobrazí se Vám tento banner, na který
                pokud kliknete, tak vstoupíte do procesu Ověření identity.
            </p>
            <img src={Question9_1} className='w-fit lg:max-w-xl my-3' alt='question3 image' />
            <ul className=' space-y-3 pl-10 list-disc '>
                <li>
                    <b>Ověřte svou identitu</b> - klikněte na banner v klientském účtu, který Vás
                    přesměruje na web našeho partnera Signi.com a zde provedete rychlé Ověření
                    identity. (pravděpodobně pořídáte selfie a instantní mikroplatbou ověříte svůj
                    bankovní účet - případně lze použít i BANK ID)
                </li>
            </ul>

            <p>
                <b>Po ukončení procesu Ověření identity budeme směrován zpět k nám</b> a většinou do
                10 minut obdržíte rozhodnutí.
            </p>

            <p>
                Po schválení žádosti od Vás budeme potřebovat{' '}
                <b>elektronicky podepsat požadované dokumenty</b>. Kompletní proces podpisu je
                popsaný detailně{' '}
                <Link to={'/pomoc/mam-schvalenou-zadost-jak-a-kde-mam-podepsat-smlouvy'}>
                    <b>ZDE</b>
                </Link>
                .
            </p>
            <p>
                <b>Proces Ověření identity provádí klient pouze jednou - jako nový klient.</b>
            </p>
        </div>
    )
}

export default QuestionNine
