import { FC } from 'react'
import LoanSkeleton from 'pages/dashboard/components/LoanSkeleton'
import { PageWrapLayout } from 'layout/components'

const LoanSkeletonLoader: FC = () => {
    return (
        <PageWrapLayout>
            <section aria-labelledby='applicant-information-title'>
                <div className={'md:mt-0 grid grid-cols-1 gap-x-6 gap-y-4 md:grid-cols-12'}>
                    <div className='sm:col-span-12 lg:col-span-8 xl:col-span-6 bg-white rounded-lg border border-borderGray p-6'>
                        <LoanSkeleton />
                    </div>

                    <div className='sm:col-span-12 md:col-span-8 xl:col-span-5 border border-borderGray bg-white rounded-lg p-6 relative overflow-hidden'>
                        <LoanSkeleton />
                    </div>
                </div>
            </section>
        </PageWrapLayout>
    )
}

export default LoanSkeletonLoader
