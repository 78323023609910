import { Customer } from 'api/customers/types'
import { transformDate } from 'utils/date'

export const getExpirationText = (customer: Customer, expiration_date: string): string => {
    const expiration = transformDate(expiration_date)

    if (customer.papers_uploaded && customer.papers_checked) {
        if (expiration_date) {
            return `Platnost Vašeho dokladu je do ${expiration}.`
        } else {
            return `Dne ${expiration} končí platnost Vašeho dokladu. Nahrajte prosím nový !`
        }
    } else {
        return 'Ověřujeme platnost Vašeho dokladu'
    }
}
