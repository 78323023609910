import React, { FC, useEffect, useState } from 'react'
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    ClipboardDocumentListIcon,
} from '@heroicons/react/24/outline'

interface IPaginationProps {
    setList: (list: any[] | []) => void
    perPage: number
    itemsList: any[]
    count: number
}

const Pagination: FC<IPaginationProps> = ({ perPage, itemsList, setList, count }) => {
    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage] = useState(perPage)

    const previousPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    const nextPage = () => {
        if (currentPage !== Math.ceil(itemsList.length / postsPerPage)) {
            setCurrentPage(currentPage + 1)
        }
    }

    useEffect(() => {
        const indexOfLastPost = currentPage * postsPerPage
        const indexOfFirstPost = indexOfLastPost - postsPerPage
        const newList = itemsList.filter((item, index) => index < perPage * currentPage)
        const currentPosts = newList.slice(indexOfFirstPost, indexOfLastPost)

        setList(currentPosts)
    }, [currentPage])

    return (
        <div className='flex gap-x-2 items-center divide-gray-400 divide-x mt-5 border-t border-borderGray pt-4'>
            <div className='flex items-center font-light'>
                <ClipboardDocumentListIcon
                    className='h-6 w-6 text-gray-400 mr-2'
                    aria-hidden='true'
                />
                <h1 className='text-base text-gray-400 font-Quicksand'>
                    Počet všech záznamů: {count}
                </h1>
            </div>
            <div className='flex items-center pl-2'>
                <p className='text-gray-400 font-light'>{`${currentPage} z ${Math.ceil(
                    itemsList.length / postsPerPage,
                )}`}</p>
                <div className='flex ml-4'>
                    <ChevronLeftIcon
                        className='h-5 w-5 cursor-pointer text-gray-400 mr-2'
                        onClick={previousPage}
                    />
                    <ChevronRightIcon
                        className='h-5 w-5 cursor-pointer text-gray-400'
                        onClick={nextPage}
                    />
                </div>
            </div>
        </div>
    )
}

export default Pagination
