import { FC } from 'react'
import { Pattern } from 'assets/pattern/Pattern'
import TheoFire from 'assets/images/TheoFire'

const Error500: FC = () => {
    return (
        <div className={'px-10 sm:px-20'}>
            <div className={'2xl:container relative mx-auto h-full w-full overflow-x-hidden'}>
                {/* Background pattern */}
                <Pattern className={'hidden md:block absolute top-0 right-0'} />
                <div className='flex flex-wrap relative z-10'>
                    <div className='max-w-4xl flex flex-col items-center sm:items-start justify-center mt-10 lg:mt-2 order-2 lg:order-1'>
                        <h1 className='text-4xl sm:text-6xl md:text-8xl uppercase text-left font-semibold font-Quicksand mb-4'>
                            OU...
                        </h1>
                        <h1 className='text-2xl sm:text-3xl uppercase text-left font-normal font-Quicksand mb-4'>
                            Něco tu bouchlo.
                        </h1>

                        <div className='mt-10'>
                            <h2 className='text-lg text-gray-400 font-light mb-1'>
                                500 Internal Server Error
                            </h2>
                            <p className='max-w-xl text-base text-gray-400 font-light'>
                                Kolo se nám polámalo, mnoho škody nadělalo, udělalo bác... Omlouvám
                                se, ale něco neklaplo jak mělo a na opravě chyby makáme jak šrouby.
                                Zkuste prosím přijít později, snad bude všechno opraveno.
                            </p>
                        </div>
                        {/*<Link*/}
                        {/*    to={routerCatalog.dashboard}*/}
                        {/*    className='font-light underline text-lightPurple hover:text-lightBlue mt-6 block'>*/}
                        {/*    Vrátit se zpět*/}
                        {/*</Link>*/}
                    </div>
                    <div className='ml-10 mt-10 lg:mt-2 order-1 lg:order-2'>
                        <TheoFire className='errorSVG' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Error500
