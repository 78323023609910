import React, { FC } from 'react'
import ModalTitle from 'layout/components/modal/shared/ModalTitle'
import ModalWrapper from 'layout/components/modal/ModalWrapper'
import { ButtonCommon } from '@vistacredit/lib-ui'

interface ISignContractModalProps {
    open: boolean
    setOpen: () => void
    closeModal: () => void
    handleCloseModal: () => void
}

const SignContractRejectModal: FC<ISignContractModalProps> = ({
    open,
    setOpen,
    handleCloseModal,
}) => {
    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <div className='mb-0'>
                <ModalTitle title='Podpis Dokumentu byl odmítnutý' />
            </div>

            <div>
                <p className='font-light text-justify sm:text-center mb-6 mt-2'>
                    <b>Podpis Dokumentu byl odmítnutý</b> jednou ze smluvních stran. <br /> Pokud
                    jste odmítl podpis vy, jako klient omylem, nevadí -{' '}
                    <b>za hodinu Vám vygenerujeme smlouvu znovu</b>. <br />
                    <span className='text-xs'>
                        <b>Jestli nemáte o finance již zájem</b>, žádost stornujeme. (informujte nás
                        o tomto požadavku na email{' '}
                        <a
                            href='mailto:uver@vistacredit.cz'
                            className='underline text-lightBlue border-0 outline-none'>
                            uver@vistacredit.cz
                        </a>
                        . Jestli podpis odmítla Vista, důvod odmítnutí jsme Vám poslali do emailu.
                    </span>
                </p>

                <div className={'flex items-center w-full mt-6 sm:mt-6 '}>
                    <ButtonCommon
                        className='bg-red-600 hover:bg-red-700'
                        onClick={handleCloseModal}>
                        Rozumím
                    </ButtonCommon>
                </div>
            </div>
        </ModalWrapper>
    )
}

export default SignContractRejectModal
