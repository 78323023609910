import { FC } from 'react'
import { Badge, ButtonCommon, ButtonPurple, ButtonWhite } from '@vistacredit/lib-ui'
import { LoanResponse } from 'api/loans/types'
import { formatCurrency } from 'utils/currencyFormatter'
import { getDate } from 'utils/date'
import { getLoanStatusColor, LoanStatus } from 'utils/loanStatus'
import LoanStatusLoader from 'pages/dashboard/components/LoanStatusLoader'
import { classNames } from 'utils'

interface ILoanCardProps {
    loan: LoanResponse | null
    hasDeferral: boolean
    openDetail: (id: number) => void
    setOpenPaymentModal: (open: boolean) => void
    setOpenDeferralModal: (open: boolean) => void
}

const LoanCard: FC<ILoanCardProps> = ({
    loan,
    setOpenPaymentModal,
    setOpenDeferralModal,
    hasDeferral,
    openDetail,
}) => {
    if (!loan) return null

    const handleStatusText = (text?: string) => {
        if (loan?.status === LoanStatus.InkasoEOS || loan?.status === LoanStatus.InkasoMaxifine) {
            return 'Vymáháno'
        }

        return text
    }

    return (
        <div className='sr-only z-10 sm:col-span-12 lg:col-span-8 xl:col-span-6 bg-white rounded-lg border border-borderGray p-6 sm:not-sr-only sm:p-6'>
            <div className=''>
                <div className='mt-3 sm:mt-0 sm:mb-3'>
                    <LoanStatusLoader status={loan?.status} />
                </div>
                <div className='flex justify-between items-center'>
                    <div className='flex flex-wrap items-center'>
                        <h1 className='text-xl font-medium font-Quicksand text-gray-900'>
                            {[LoanStatus.New, LoanStatus.PreApproved, LoanStatus.Processed, LoanStatus.Approved].includes(loan?.status) ? 'Stav žádosti' : 'Stav úvěru'}
                        </h1>
                        <div className={'2xl:ml-4 ml-2 2xl:mt-0'}>
                            <Badge
                                type={getLoanStatusColor(loan?.status)}
                                text={handleStatusText(loan?.status_text)}
                            />
                        </div>
                    </div>
                    <div className=' sm:mt-0 sm:ml-16 sm:flex-none'>
                        <ButtonCommon onClick={() => openDetail(loan?.id)}>
                            Zobrazit detail
                        </ButtonCommon>
                    </div>
                </div>

                <div className='flex justify-between sm:items-start mt-10 sm:mt-4'>
                    <div className='flex-0 sm:flex-1'>
                        <div className='flex'>
                            <div>
                                <p className='truncate text-sm font-light text-gray-500 mb-1'>
                                    Typ úvěru
                                </p>
                                <p className='text-base mb-0 font-normal text-gray-900'>
                                    {loan?.loan_type}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='sm:flex-1'>
                        <p className='truncate text-right text-sm font-light text-gray-500 mb-1'>
                            {`ID: ${loan?.contract_number ?? loan?.id}`}
                        </p>
                    </div>
                </div>

                <div className='flex flex-wrap justify-between sm:items-center mt-4'>
                    <div className='sm:flex-1 w-25'>
                        <p className='truncate text-sm font-light text-gray-500 mb-1'>Jistina</p>
                        <p className='text-base mb-0 font-normal text-gray-900'>{`${formatCurrency(
                            loan?.amount,
                            'CZK',
                        )}`}</p>
                    </div>
                    <div className='sm:flex-1  w-25'>
                        <p className='truncate text-sm font-light text-gray-500 mb-1'>Poplatek</p>
                        <p className='text-base mb-0 font-normal text-gray-900'>{`${formatCurrency(
                            loan?.fee,
                            'CZK',
                        )}`}</p>
                    </div>

                    <div className='sm:flex-1 w-25'></div>
                    <div className='sm:flex-1 w-25'>
                        <p className='truncate text-sm text-right font-light text-gray-500 mb-1'>
                            Ke splacení
                        </p>
                        <p className='text-base mb-0 text-right font-normal text-gray-900'>
                            {' '}
                            {`${formatCurrency(loan?.sum, 'CZK')}`}
                        </p>
                    </div>
                </div>

                <div className='flex flex-wrap justify-between sm:items-center mt-4'>
                    <div className='sm:flex-1 w-25'>
                        <p className='truncate text-sm font-light text-gray-500 mb-1'>
                            Datum žádosti
                        </p>
                        <p className='text-base mb-0 font-normal text-gray-900'>{`${getDate(
                            loan?.created,
                        )}`}</p>
                    </div>

                    <div className='sm:flex-1 w-25'>
                        <p className='truncate text-sm font-light text-gray-500 mb-1'>
                            Doba poskytnutí
                        </p>
                        <p className='text-base mb-0 font-normal text-gray-900'>{`${loan?.period} dnů`}</p>
                    </div>
                    <div className='sm:flex-1 w-25'>
                        <p className='truncate text-sm font-light text-gray-500 mb-1'>
                            Po splatnosti
                        </p>
                        <p className='text-base mb-0 font-normal text-gray-900'>{`${loan?.overdue} dnů`}</p>
                    </div>
                    <div className='sm:flex-1 w-25 mt-2 sm:mt-0'>
                        <p className='truncate text-sm text-right font-light text-gray-500 mb-1'>
                            Splatnost úvěru
                        </p>
                        <p className='text-base mb-0 sm:text-right font-normal text-gray-900'>{`${getDate(
                            loan?.due,
                        )}`}</p>
                    </div>
                </div>
                {loan?.status >= LoanStatus.SentToBankAccount && (
                    <div
                        className={classNames(
                            'flex flex-1 flex-col gap-y-4 lg:flex-row mt-6',
                            loan?.status &&
                                loan?.status !== LoanStatus.Trial &&
                                loan?.status !== LoanStatus.InkasoEOS &&
                                loan?.status !== LoanStatus.BeforeComplaint &&
                                loan?.status !== LoanStatus.InkasoMaxifine
                                ? 'justify-center lg:justify-between'
                                : 'justify-end',
                        )}>
                        {loan?.status &&
                            loan?.status !== LoanStatus.Trial &&
                            loan?.status !== LoanStatus.InkasoEOS &&
                            loan?.status !== LoanStatus.BeforeComplaint &&
                            loan?.status !== LoanStatus.InkasoMaxifine && (
                                <ButtonWhite
                                    className='bg-white border border-lightBlue text-lightBlue hover:bg-lightBlue hover:text-white'
                                    disabled={hasDeferral}
                                    onClick={() => setOpenDeferralModal(true)}>
                                    Odložit splatnost
                                </ButtonWhite>
                            )}
                        <ButtonPurple
                            disabled={hasDeferral}
                            onClick={() => setOpenPaymentModal(true)}>
                            Splatit úvěr
                        </ButtonPurple>
                    </div>
                )}
            </div>
        </div>
    )
}

export default LoanCard
