import React, { FC } from 'react'
import Question16_1 from 'assets/images/question16_1.png'

const QuestionSixteen: FC = () => {
    return (
        <div className='space-y-4'>
            <p>
                Tak to je ta nejjednodušší co umíme. V klientském účtu si v menu Můj účet vybere
                volbu Nastavení a budete automaticky směrování na změnu hesla kde{' '}
                <b>zadáte nové heslo</b>.
            </p>
            <img src={Question16_1} className='w-fit lg:max-w-xl my-3' alt='question3 image' />

            <p>Následně zadáte nové heslo znovu pro potvrzení, že jste se nesekli a máte hotovo.</p>
            <p>
                Můžete nám také poslat dotaz přes <b>Kontaktní formulář</b> výběrem volby :{' '}
                <b>Vlastní dotaz, námět, prosba, pochvala, kritika</b>.
            </p>

            {/*<img src={Question1_2} className='max-w-xl my-3' alt='question3 image' />*/}
        </div>
    )
}

export default QuestionSixteen
