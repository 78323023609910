import { FC } from 'react'
import { formatCurrency } from 'utils'
import { TLoanResponse } from 'api/services/loan/types'
import { BanknotesIcon } from '@heroicons/react/24/outline'

interface IPaymentModalProps {
    loan?: TLoanResponse
    form: any
    setSelectedData: any
}

const PaymentModalForm: FC<IPaymentModalProps> = ({ loan, form, setSelectedData }) => {
    const handleOnChange = (e: any) => {
        setSelectedData([])

        if (isNaN(e.target.value) || e.target.value.includes('.')) {
            form.setValue('price', form.getValues('price'))
            return
        }

        form.setValue('price', e.target.value)
    }

    return (
        <div>
            <label htmlFor='price' className='block text-lg font-medium text-gray-700'>
                Ke splacení: {formatCurrency(loan?.sum, 'CZK')}
            </label>
            <div className='relative mt-1 rounded-md shadow-sm'>
                <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
                    <span className='text-gray-500 sm:text-sm'>
                        <BanknotesIcon className={'h-5 w-5'} />
                    </span>
                </div>
                <input
                    type='text'
                    {...form.register('price')}
                    id='price'
                    value={form.getValues('price')}
                    onChange={handleOnChange}
                    className='block w-full border-b py-4 border-borderGray pl-10 pr-12 sm:text-sm focus:outline-none focus:ring-transparent focus:border-b focus:border-lightPurple'
                    placeholder='0.00'
                    aria-describedby='price-currency'
                />
                <div className='pointer-events-none absolute inset-y-0 right-0 flex  items-center pr-3'>
                    <span className='text-gray-500 sm:text-sm' id='price-currency'>
                        Kč
                    </span>
                </div>
            </div>
        </div>
    )
}

export default PaymentModalForm
