import { FC, useEffect, useState } from 'react'
import ModalWrapper from 'layout/components/modal/ModalWrapper'
import ModalFooter from 'layout/components/modal/shared/ModalFooter'
import Slider from 'layout/components/slider/Slider'
import { DEFERRED_MARKS } from 'layout/components/slider/marks'
import ModalTitle from 'layout/components/modal/shared/ModalTitle'
import PaymentMethodForm from 'pages/dashboard/modals/shared/PaymentMethodForm'
import { LoanResponse } from 'api/loans/types'
import QRcode from 'pages/dashboard/modals/payment/components/QRcode'
import { generateQRPaymentData } from 'pages/dashboard/utils/generateQRPaymentData'
import { classNames, formatCurrency } from 'utils'
import { getDate } from 'utils/date'
import { IDeferralOrder } from 'api/services/loan/types'
import { TCustomer } from 'api/services/customer/types'
import { ButtonCommon, ButtonWithLoader } from '@vistacredit/lib-ui'

interface IPaymentModalProps {
    open: boolean
    loan: LoanResponse | null
    customer?: TCustomer
    handleDeferral: (days: number) => void
    setOpen: (value: boolean) => void
    showPaymentMethodForm: boolean
    setShowPaymentMethodForm: (value: boolean) => void
    showPaymentForm: boolean
    setShowPaymentForm: (value: boolean) => void
    deferral?: IDeferralOrder
}

const DeferralModal: FC<IPaymentModalProps> = ({
    open,
    setOpen,
    loan,
    customer,
    handleDeferral,
    showPaymentMethodForm,
    setShowPaymentMethodForm,
    showPaymentForm,
    setShowPaymentForm,
    deferral,
}) => {
    const [rangeDays, setRangeDays] = useState<number | number[]>(28)
    const [showQRCode, setShowQRCode] = useState(false)
    const [amount, setAmount] = useState(
        loan?.deferral_price_list[loan?.deferral_price_list.findIndex((item) => item.days === 28)]
            .amount ?? 0,
    )
    const [loading, setLoading] = useState(false)
    const [selectedMethodObj, setSelectedMethodObj] = useState<{
        sid: string
        text: string
        method: () => Promise<void> | void | null
    }>({
        sid: '',
        text: '',
        method: () => null,
    })

    const handleCloseModal = () => {
        setOpen(false)
        setTimeout(() => {
            setShowPaymentForm(true)
            setShowPaymentMethodForm(false)
            setShowQRCode(false)
            setSelectedMethodObj({
                sid: '',
                text: '',
                method: () => null,
            })
        }, 500)
    }

    const handleShowQRCode = () => {
        setShowQRCode(true)
        setShowPaymentMethodForm(false)
    }

    const handleMovementThroughSteps = () => {
        if (!showPaymentMethodForm && !showQRCode) {
            setShowPaymentMethodForm(true)
            setShowPaymentForm(false)
            handleDeferral(rangeDays as number)
        } else {
            handleCloseModal()
        }
    }

    const getSaveAmount = () => {
        if (!loan?.deferral_price_list) {
            return 0
        }

        const base = loan?.deferral_price_list[0].amount
        const currentPrice =
            loan?.deferral_price_list.find((item) => item.days === rangeDays)?.amount || 0

        return (
            base * (loan?.deferral_price_list.findIndex((item) => item.days === rangeDays) + 1) -
                currentPrice ?? 0
        )
    }

    const getNewDueDate = () => {
        if (!loan?.due) {
            return
        }

        const someDate = new Date(loan?.due)

        someDate.setDate(someDate.getDate() + +rangeDays)
        return getDate(someDate)
    }

    useEffect(() => {
        setAmount(
            loan?.deferral_price_list[
                loan?.deferral_price_list.findIndex((item) => item.days === rangeDays)
            ].amount ?? 0,
        )
    }, [rangeDays])

    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <div className='mb-8'>
                <ModalTitle
                    title={
                        showPaymentMethodForm
                            ? 'Platba odkladu splatnosti úvěru'
                            : 'Odklad splatnosti úvěru'
                    }
                />

                {showPaymentMethodForm && (
                    <PaymentMethodForm
                        order_id={(deferral?.order_id as number) ?? (loan?.id as number)}
                        setShowQRCode={handleShowQRCode}
                        amount={deferral?.deferral_amount ?? amount}
                        deferral={deferral}
                        type={'deferral'}
                        onClose={handleCloseModal}
                        selectedMethod={selectedMethodObj}
                        setMethod={setSelectedMethodObj}
                    />
                )}
                {showQRCode && (
                    <QRcode
                        paymentData={generateQRPaymentData(
                            'deferral',
                            amount,
                            customer?.birth_number,
                            loan?.contract_number,
                        )}
                    />
                )}
                {showPaymentForm && (
                    <>
                        <div className='mt-6 mb-6 sm:mb-2 w-full flex flex-col justify-center items-center'>
                            <p className='relative'>
                                {`${rangeDays} dnů `}
                                {rangeDays === 28 && (
                                    <span className='absolute -top-0.5 -right-10  rounded-full bg-status-orange p-1.5 text-xs font-normal text-white'>
                                        TOP
                                    </span>
                                )}
                            </p>
                        </div>

                        <div className='px-3 sm:px-0 text-center relative'>
                            <Slider
                                min={7}
                                max={35}
                                marks={DEFERRED_MARKS}
                                defaultValue={28}
                                days={true}
                                type={'deferral'}
                                setSliderValue={setRangeDays}
                            />

                            <div className='sm:-mt-10 mb-2 grid gap-y-2'>
                                <p>{formatCurrency(amount, 'CZK')}</p>
                                <p className='text-lightPurple'>
                                    Ušetříte {formatCurrency(getSaveAmount(), 'CZK')}
                                </p>
                                <p className='text-lightGray'>
                                    Splatnost nastane {getNewDueDate()}
                                </p>
                            </div>
                        </div>
                    </>
                )}
            </div>
            {showPaymentMethodForm ? (
                <div
                    className={classNames(
                        selectedMethodObj.sid !== ''
                            ? 'grid content-center items-center gap-y-2 sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3'
                            : 'flex items-center w-full',
                        'mt-6 sm:mt-6 ',
                    )}>
                    <ButtonCommon
                        className='bg-red-600 hover:bg-red-700 mt-6 sm:mt-0'
                        onClick={handleCloseModal}>
                        Zrušit
                    </ButtonCommon>
                    {selectedMethodObj.text !== '' && (
                        <ButtonWithLoader
                            onClick={async () => {
                                setLoading(true)
                                await selectedMethodObj.method()
                                setLoading(false)
                            }}
                            loading={loading}>
                            {selectedMethodObj.text}
                        </ButtonWithLoader>
                    )}
                </div>
            ) : (
                <ModalFooter
                    onClick={handleMovementThroughSteps}
                    onClose={handleCloseModal}
                    title={'Potvrdit výběr'}
                />
            )}
        </ModalWrapper>
    )
}

export default DeferralModal
