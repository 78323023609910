import { useState } from 'react'
import { useNavigate } from 'react-router'

export const useApiCall = () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<Error | null>(null)
    const [data, setData] = useState<any>(null)
    const navigate = useNavigate()

    const handleError = (error: any) => {
        switch (error.response.status) {
            // case 401:
            //     navigate('/prihlaseni')
            //     break
            case 403:
                navigate('/odhlaseni')
                break
            case 404:
                // navigate('/404')
                break
            // case 500:
            //     navigate('/500')
            //     break
            default:
                // navigate('/odhlaseni')
                break
        }
    }

    const apiCall = async <T extends (...args: any[]) => any>(fn: T) => {
        setLoading(true)

        try {
            const response = await fn()
            setData(response)
        } catch (error: any) {
            handleError(error)
            setData({ data: null })

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setError(error)
        }

        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }

    const apiMultiCall = async (fn: Array<any>) => {
        setLoading(true)
        try {
            const response = await Promise.all(fn)
            setData(response)
        } catch (error: any) {
            handleError(error)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setError(error)
        }

        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }

    const apiBackgroundCall = async <T extends (...args: any[]) => any>(fn: T) => {
        try {
            const response = await fn()
            setData(response)
        } catch (error: any) {
            handleError(error)
            setData({ data: null })

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setError(error)
        }
    }

    const apiMultiBackgroundCall = async (fn: Array<any>) => {
        try {
            return await Promise.all(fn)
        } catch (error: any) {
            handleError(error)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setError(error)
        }
    }

    return {
        data,
        setData,
        loading,
        error,
        apiCall,
        apiMultiCall,
        apiBackgroundCall,
        handleError,
        apiMultiBackgroundCall,
    }
}
