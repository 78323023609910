import { FC } from 'react'
import { ButtonCommon } from '@vistacredit/lib-ui'
import TheoRentIcon from 'assets/images/TheoRentIcon'
import LimitedPattern from 'assets/pattern/limitedPatter.svg'

interface IActivateRentBannerProps {
    showRentModal: () => void
}

const ActivateRentBanner: FC<IActivateRentBannerProps> = ({ showRentModal }) => {
    return (
        <div className='flex flex-wrap overflow-hidden sm:flex-nowrap relative justify-between items-center 3xl:max-h-28 col-span-12 sm:col-span-12 lg:col-span-8 xl:col-span-6 rounded-lg border border-borderGray bg-gradient-to-r from-lightBlue to-darkPurple p-6 sm:p-6 mt-4'>
            <img src={LimitedPattern} className='absolute left-0 bottom-0 z-0' alt='' />
            <div className='flex flex-wrap flex-auto flex-col md:flex-row justify-center items-center sm:justify-between sm:flex-nowrap relative z-10'>
                <div className='flex flex-col md:flex-row justify-center items-center sm:justify-start '>
                    {/*<p className='text-center text-white  font-light break-all mb-2 sm:mb-0'>*/}
                    {/*    Aktivujte si naši prémiovou službu*/}
                    {/*    /!*<br className='not-sr-only' />{' '}*!/*/}
                    {/*    <span className='font-normal '> VistaRENT</span>*/}
                    {/*</p>*/}
                    <TheoRentIcon className='relative my-4 md:my-0 mr-2 ' />
                    <p className='text-center text-white font-light break-word mb-2 sm:mb-0 px-4'>
                        Aktivujte si naši prémiovou službu{' '}
                        <span className='font-normal '> VistaRENT</span> <br />
                        <span className='font-normal '>8000 Kč na 14 dnů vždy ZDARMA</span> + další
                        výhody
                    </p>
                </div>
                <div className='flex-auto mt-4 flex-grow-1 sm:flex-grow-0 sm:flex-shrink-1'>
                    <ButtonCommon
                        className='bg-lightPurple hover:bg-transparent hover:border-white'
                        onClick={showRentModal}>
                        Vyzkoušejte si nás !
                    </ButtonCommon>
                </div>
            </div>
        </div>
    )
}

export default ActivateRentBanner
