import React, { FC, ReactNode } from 'react'

interface IPageWrapLayoutProps {
    title?: string
    children: ReactNode | ReactNode[]
}

export const PageWrapLayout: FC<IPageWrapLayoutProps> = ({ title, children }) => {
    return (
        <div id={'section-1'} className='bg-transparent '>
            <div className='mx-auto sm:mx-0 -mt-5 py-8 px-4 sm:pt-8 sm:pb-24 sm:px-4 lg:px-4 scrollbar-hide'>
                {title && (
                    <h2 className='text-2xl font-semibold font-Quicksand text-gray-900 sm:text-3xl sm:tracking-tight mb-8'>
                        {title}
                    </h2>
                )}
                {children}
            </div>
        </div>
    )
}
