/**
 * This file includes all logic rules for the app
 */
import { useEffect } from 'react'
import { useAppSelector } from 'store/store'
import { useLocation, useNavigate } from 'react-router'
import { routerCatalog } from 'routes/hooks/useSecureRouting'
import { useDispatch } from 'react-redux'
import { setFlags, setNotification } from 'store/stateReducer'
import { useGetActiveLoanQuery } from 'api/services/loan/serviceLoan'
import {
    useGetCustomerPapersQuery,
    useGetCustomerQuery,
} from 'api/services/customer/serviceCustomer'
import { useGetPointsListQuery } from 'api/services/utils/points/servicePoints'
import { useGetContractsToSignQuery } from 'api/services/customer/serviceContracts'
import { useGetPriceListQuery } from 'api/services/utils/pricesLists/servicePriceLists'
import { TCustomer } from 'api/services/customer/types'
import { useLogoutOnInactivity } from 'hooks/useLogoutOnInactivity'

export const useRules = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const queryParams = new URLSearchParams(location.search).get('payment_status')

    const Token = useAppSelector((state) => state.app.token)
    const Flags = useAppSelector((state) => state.app.flags)

    const activeLoanQuery = useGetActiveLoanQuery()
    const customerQuery = useGetCustomerQuery()
    const contractsToSignQuery = useGetContractsToSignQuery()
    const pointsQuery = useGetPointsListQuery()
    const customerPapersQuery = useGetCustomerPapersQuery()
    const priceListFixQuery = useGetPriceListQuery('fix')
    const priceListRentQuery = useGetPriceListQuery('rent')

    useLogoutOnInactivity(['/prihlaseni'], 30 * 60 * 1000)

    /**
     * 1. Rule of Token
     * If there is no token, redirect to login page
     */
    useEffect(() => {
        if (!Token) {
            navigate(routerCatalog.login, { replace: true })
            return
        }
    }, [])

    /**
     * 2. Rule of Distance Amendment Agreement
     * If the distance amendment agreement is not signed, deny access to all pages except the dashboard
     */
    useEffect(() => {
        if (
            customerQuery?.data &&
            !customerQuery?.data?.distance_amendment_signed &&
            location.pathname !== routerCatalog.dashboard &&
            Token
        ) {
            navigate(routerCatalog.dashboard, { replace: true })
        }
    }, [location])

    /**
     * 3. Rule of First Fetch
     * After the login execute the first fetch of the data
     */

    /**
     * 4. Rule of Interval re-fetch
     */
    useEffect(() => {
        const refreshInterval = setInterval(async () => {
            // fetch Customer data
            await customerQuery.refetch()

            // fetch Loan data
            await activeLoanQuery.refetch()

            // fetch Contracts to sign
            contractsToSignQuery?.data?.results?.length === 0 ||
                (contractsToSignQuery?.data?.results &&
                    contractsToSignQuery?.data?.results?.filter(
                        (contract) => contract?.latest_signi_status === 'pending',
                    )?.length > 0 &&
                    (await contractsToSignQuery.refetch()))
        }, 30000)

        return () => clearInterval(refreshInterval)
    }, [])

    /**
     * 5. Rule of Fetch data on render
     */

    /**
     * 6. Rule of Uploaded Documents Check
     */
    useEffect(() => {
        if (!customerQuery?.data) return

        // if documents are not uploaded
        if (
            !(customerQuery.data as unknown as TCustomer)?.papers_uploaded ||
            !(customerQuery.data as unknown as TCustomer)?.id_number
        ) {
            dispatch(setFlags({ ...Flags, can_apply_for_loan: false }))
        } else {
            dispatch(setFlags({ ...Flags, can_apply_for_loan: true }))
        }
    }, [customerQuery?.data])

    /**
     * 7. Rule of Notifications on redirect to client from outside
     */
    useEffect(() => {
        // handle notification's status

        if (!queryParams) return

        switch (queryParams.toLowerCase()) {
            case 'pending':
                dispatch(
                    setNotification({
                        type: 'info',
                        title: 'Podrobnosti o platbě',
                        message: 'Vaše platba čeká na spracování!',
                    }),
                )

                setTimeout(() => {
                    if (location.pathname.includes('rent')) {
                        dispatch(
                            setNotification({
                                type: 'info',
                                title: 'Po spracování Vaší platby bude služba RENT aktivována.',
                                message:
                                    'Po spracovaní vaší platby budete moct u nové žádosti o úvěr vybrat i typ RENT.',
                            }),
                        )
                    }
                }, 3000)
                break
            case 'paid':
                dispatch(
                    setNotification({
                        type: 'success',
                        title: 'Podrobnosti o platbě',
                        message: 'Vaše platba proběhla úspěšně a nyní ji zpracováváme, vyčkejte.',
                    }),
                )

                setTimeout(() => {
                    if (location.pathname.includes('rent')) {
                        dispatch(
                            setNotification({
                                type: 'success',
                                title: 'Služba RENT aktivována!',
                                message: 'Nyní můžete u nové žádosti o úvěr vybrat typ RENT.',
                            }),
                        )
                    }
                }, 3000)
                break
            case 'cancelled':
                dispatch(
                    setNotification({
                        type: 'error',
                        title: 'Podrobnosti o platbě',
                        message: 'Vaše platba sa nezdařila!',
                    }),
                )
                break
            default:
                dispatch(
                    setNotification({
                        type: 'error',
                        title: 'Podrobnosti o platbě',
                        message: 'Vaše platba je mimo naši galaxii, opakujte znovu!',
                    }),
                )
                break
        }
    }, [queryParams])

    useEffect(() => {
        if (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            activeLoanQuery?.error?.status === 403 ||
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            customerQuery?.error?.status === 403 ||
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            contractsToSignQuery?.error?.status === 403 ||
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            pointsQuery?.error?.status === 403
        ) {
            if (Token !== '') return

            navigate(routerCatalog.logout)
        }
    }, [activeLoanQuery, customerQuery])

    return {
        contractsToSignQuery,
        customerQuery,
        pointsQuery,
        activeLoanQuery,
    }
}
