import { FC, useState } from 'react'
import DocumentsUploadModal from 'pages/profile/modals/DocumentsUploadModal'
import ProfilePersonalInfo from 'pages/profile/components/ProfilePersonalInfo'
import ProfileUploadedDocuments from 'pages/profile/components/ProfileUploadedDocuments'
import { getMappedProfileData } from 'pages/profile/utils/mappedProfileData'
import { ICustomerExpiration, ICustomerPapers, TCustomer } from 'api/services/customer/types'

interface IProfilePersonalInfoSectionProps {
    customer: TCustomer
    customerPapers: ICustomerPapers[]
    expiration?: ICustomerExpiration
}

const ProfilePersonalInfoSection: FC<IProfilePersonalInfoSectionProps> = ({
    customer,
    customerPapers,
    expiration,
}) => {
    const [openDocumentsUploadModal, setOpenDocumentsUploadModal] = useState(false)

    const userData = getMappedProfileData(customer)

    return (
        <div className='space-y-6 lg:col-span-2 lg:col-start-1'>
            <ProfilePersonalInfo data={userData} customer={customer} />

            <ProfileUploadedDocuments
                data={customerPapers as any}
                customer={customer}
                expiration={expiration}
                onOpen={() => setOpenDocumentsUploadModal(true)}
            />

            <DocumentsUploadModal
                customerPapers={customerPapers}
                open={openDocumentsUploadModal}
                setOpen={setOpenDocumentsUploadModal}
            />
        </div>
    )
}

export default ProfilePersonalInfoSection
