import React, { FC } from 'react'
import { TButtonProps } from './types'

export const ButtonWhite: FC<TButtonProps> = ({
    children,
    style,
    className,
    onClick,
    disabled,
}) => {
    return (
        <button
            type='button'
            style={style}
            disabled={disabled}
            className={`inline-flex
                items-center justify-center rounded-md border
                 px-6 py-3 text-sm font-light duration-300 ${
                     className ?? 'bg-white text-black border-borderGray'
                 } ${disabled && 'opacity-50'}`}
            onClick={onClick}>
            {children}
        </button>
    )
}
