import { FC } from 'react'
import { Customer } from 'api/customers/types'
import { transformDate } from 'utils/date'

interface IProfilePersonalInfo {
    data: any[]
    customer: Customer
}

const ProfilePersonalInfo: FC<IProfilePersonalInfo> = ({ data, customer }) => {
    return (
        <section aria-labelledby='applicant-information-title'>
            <div className='bg-white border border-borderGray rounded-lg'>
                <div className='px-4 py-5 sm:px-6'>
                    <h2
                        id='applicant-information-title'
                        className='text-lg font-medium leading-6 text-gray-900'>
                        Osobní údaje
                    </h2>
                    <p className='mt-1 max-w-2xl text-sm text-gray-500'>
                        {`${customer.business.type} - Podnikatel od ${transformDate(
                            customer.business.start_day,
                        )} - Oprávnění vydal ${customer.business.authority}`}
                    </p>
                </div>
                <div className='border-t border-gray-200 px-4 py-5 sm:px-6'>
                    <dl className='grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2'>
                        {data.map((item) => (
                            <div key={item.title} className='sm:col-span-1'>
                                <dt className='text-sm font-medium text-gray-500'>{item.title}</dt>
                                <dd className='mt-1 text-sm text-gray-900'>{item.description}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </section>
    )
}

export default ProfilePersonalInfo
