import { LoginRequest } from 'api/auth/types'
import { useNavigate } from 'react-router'
import { useDispatch } from 'react-redux'
import { useLoginMutation } from 'api/services/auth/serviceAuth'
import { setFlagWasSet, setNotification, setToken } from 'store/stateReducer'
import { useAppSelector } from 'store/store'
import { useState } from 'react'

export const useLogin = () => {
    const token = useAppSelector((state) => state.app.token)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const [login] = useLoginMutation()

    const handleLogin = async (requestedData: LoginRequest | any) => {
        try {
            setLoading(true)
            if (requestedData.email === '' && requestedData.password === '') {
                dispatch(
                    setNotification({
                        type: 'error',
                        title: 'Chyba!',
                        message: `Vyplňte e-mail a heslo správně.`,
                    }),
                )
                setLoading(false)
                return
            }

            const loginRes: any = await login(requestedData)

            if (!loginRes?.data) {
                dispatch(
                    setNotification({
                        type: 'error',
                        title: 'Chyba!',
                        message: `${loginRes?.error?.data?.non_field_errors[0]}`,
                    }),
                )

                setLoading(false)
                return
            }

            dispatch(setToken(loginRes.data.token))
            dispatch(setFlagWasSet(false))

            setLoading(false)
            // Loading screen
            navigate('/loading')

            setTimeout(() => {
                dispatch(
                    setNotification({
                        type: 'success',
                        title: 'To se povedlo!',
                        message: `Byl jste úspěšne přihlášen.`,
                    }),
                )
                // navigate('/aktivni-uver', { replace: true })
            }, 5000)
        } catch (error) {
            // throw error notification
            console.log(error)
        }
    }

    return { handleLogin, loading }
}
