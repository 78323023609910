import { FC } from 'react'
import { ButtonWithLoader, InputWithIcon } from '@vistacredit/lib-ui'
import { EnvelopeIcon, LockClosedIcon } from '@heroicons/react/24/outline'
import { useForm } from 'react-hook-form'
import { useLogin } from 'pages/login/hooks/useLogin'
import { Link } from 'react-router-dom'
import { routerCatalog } from 'routes/hooks/useSecureRouting'

const LoginForm: FC = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()

    const { handleLogin, loading } = useLogin()

    return (
        <form
            onSubmit={handleSubmit(handleLogin)}
            className='space-y-6 pb-6 px-8 md:space-y-8 md:px-2 md md:w-96 mx-auto'>
            <div>
                <div className='mt-1'>
                    <InputWithIcon
                        Icon={EnvelopeIcon}
                        placeholder={'Přihlasovací e-mail'}
                        type={'text'}
                        {...register('email')}
                    />
                </div>
            </div>

            <div>
                <div className='mt-1'>
                    <InputWithIcon
                        Icon={LockClosedIcon}
                        placeholder={'Heslo'}
                        type={'password'}
                        {...register('password')}
                    />
                </div>
            </div>

            <div className='flex items-center justify-between cursor-pointer'>
                <div className='flex items-center '>
                    <input
                        id='remember-me'
                        name='remember-me'
                        type='checkbox'
                        className='h-4 w-4 rounded-sm border-lightGray text-pink-600 accent-lightPurple'
                    />
                    <label
                        htmlFor='remember-me'
                        className='ml-2 block text-sm text-lightGray cursor-pointer'>
                        Pamatuj si mě
                    </label>
                </div>

                <div className='text-sm'>
                    <Link
                        to={routerCatalog.forgotPassw}
                        className='font-medium underline cursor-pointer text-lightBlue hover:text-lightPurple'>
                        Zapomněli jste heslo?
                    </Link>
                </div>
            </div>

            <div>
                <ButtonWithLoader
                    type={'submit'}
                    onClick={handleSubmit(handleLogin)}
                    loading={loading}
                    loadingText={'Malý moment...'}
                    className='flex w-full justify-center py-4 text-md font-normal uppercase bg-lightPurple hover:bg-darkPurple'>
                    Přihlásit se k účtu
                </ButtonWithLoader>
                {/*<ButtonCommon*/}
                {/*    type={'submit'}*/}
                {/*    onClick={handleSubmit(handleLogin)}*/}
                {/*    className='flex w-full justify-center py-4 text-md font-normal uppercase bg-lightPurple hover:bg-darkPurple'>*/}
                {/*    Přihlásit se k účtu*/}
                {/*</ButtonCommon>*/}
            </div>
        </form>
    )
}

export default LoginForm
