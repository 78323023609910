import { Customer } from 'api/customers/types'
import { TMappedProfileData } from 'pages/profile/types/types'
import { getBirthDateFromBirthNumber, getDate } from 'utils/date'

export const getMappedProfileData = (data: Customer): TMappedProfileData[] => {
    return [
        {
            title: 'Obchodní jméno',
            description: data.business.name,
        },
        {
            title: 'Rodné číslo',
            description: data.birth_number,
        },
        {
            title: 'Křestní jméno',
            description: data.first_name,
        },
        {
            title: 'Příjmení',
            description: data.last_name,
        },
        {
            title: 'Datum narození',
            description: getBirthDateFromBirthNumber(data.birth_number) ?? '',
        },
        {
            title: 'Číslo ob. průkazu',
            description: data.id_number,
        },
        {
            title: 'E-mail',
            description: data.email,
        },
        {
            title: 'Telefon',
            description: data.phone,
        },
        {
            title: 'Číslo účtu',
            description: data.bank_account,
        },
    ]
}
