import { FC } from 'react'

interface IModalTitleProps {
    title: string
}

const ModalTitle: FC<IModalTitleProps> = ({ title }) => {
    return (
        <h2 className='text-2xl sm:text-3xl text-center mt-3 mb-6 font-normal font-Quicksand leading-6 text-gray-900'>
            {title}
        </h2>
    )
}

export default ModalTitle
