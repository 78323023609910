import { FC } from 'react'
import SigniPattern from 'assets/pattern/signiPattern.svg'
import { ButtonCommon } from '@vistacredit/lib-ui'
import { ContractsListResponse } from 'api/contracts/types'

interface ISignContractBanner {
    setShowSignContractModal: (value: boolean) => void
    contracts: ContractsListResponse
}

const SignContractBannerSigni: FC<ISignContractBanner> = ({
    setShowSignContractModal,
    contracts,
}) => {
    return (
        <div className='flex flex-wrap overflow-hidden sm:flex-nowrap relative justify-between items-center sm:max-h-28 sm:col-span-12 lg:col-span-8 xl:col-span-6 rounded-lg border border-borderGray p-6 sm:p-6 bg-signiBackground mt-4'>
            <img
                src={SigniPattern}
                className='absolute right-0 bottom-0 z-0 h-full opacity-10 2xl:opacity-90'
                alt=''
            />
            <div className='flex flex-wrap flex-auto flex-col justify-center items-center sm:justify-between sm:flex-nowrap sm:flex-row relative z-10'>
                {/*<div className='flex flex-col justify-center items-center sm:justify-start sm:flex-row'>*/}
                <p className=' text-lg text-center sm:text-left text-signiText font-semibold font-Quicksand break-all mb-2 sm:mb-0 sm:text-xl'>
                    Čekáme na podpis dokumentů
                </p>
                {/*</div>*/}
                <div className='flex-auto mt-2 sx:mt-0 flex-grow-1 sm:flex-grow-0 sm:flex-shrink-1'>
                    <ButtonCommon
                        className='bg-signiText hover:bg-transparent hover:border-signiText font-Quicksand font-semibold hover:text-signiText'
                        onClick={() => {
                            return (window.location.href =
                                contracts.results?.[0]?.signi_sign_url ?? '/')
                        }}>
                        Podepsat smlouvu
                    </ButtonCommon>
                </div>
            </div>
        </div>
    )
}

export default SignContractBannerSigni
