import React, { FC, useEffect, useState } from 'react'
import ModalTitle from 'layout/components/modal/shared/ModalTitle'
import ModalWrapper from 'layout/components/modal/ModalWrapper'
import { ButtonCommon } from '@vistacredit/lib-ui'
import Navigation from 'pages/loans/modals/components/Navigation'
import {
    BanknotesIcon,
    CreditCardIcon,
    DocumentTextIcon,
    InformationCircleIcon,
} from '@heroicons/react/20/solid'
import PaymentsTab from 'pages/loans/modals/components/tabs/PaymentsTab'
import TopayTab from 'pages/loans/modals/components/tabs/TopayTab'
import InfoTab from 'pages/loans/modals/components/tabs/InfoTab'
import ContractsTab from 'pages/loans/modals/components/tabs/ContractsTab'
import { useContractsApi } from 'api'
import { ContractsListResponse, EContractsType } from 'api/contracts/types'
import { TLoanDetail } from 'api/services/loan/types'
import { useGetActiveLoanQuery } from 'api/services/loan/serviceLoan'

interface IDetailModalProps {
    open: boolean
    setOpen: (value: boolean) => void
    closeModal: () => void
    loan?: TLoanDetail
}

const DetailModal: FC<IDetailModalProps> = ({ open, setOpen, closeModal, loan }) => {
    const activeLoanQuery = useGetActiveLoanQuery()

    const { contractsList } = useContractsApi()
    const [contracts, setContracts] = useState<{
        loanContracts: ContractsListResponse | null
        deferralContracts: ContractsListResponse | null
    } | null>(null)

    const [tabs, setTabs] = useState([
        {
            id: 1,
            name: 'Základní informace',
            href: '#',
            icon: InformationCircleIcon,
            current: true,
        },
        { id: 2, name: 'Ke splaceni', href: '#', icon: BanknotesIcon, current: false },
        { id: 3, name: 'Platby', href: '#', icon: CreditCardIcon, current: false },
        { id: 4, name: 'Smlouvy', href: '#', icon: DocumentTextIcon, current: false },
    ])

    const cells = ['ID ÚVĚRU', 'TYP', 'POPLATEK', 'DATUM ŽÁDOSTI', 'SPLATNOST ÚVĚRU', 'STAV ÚVĚRU']
    const cellsToPay = ['ID ÚVĚRU', 'TYP', 'ČÁSTKA']
    const cellsPayments = ['ID ÚVĚRU', 'TYP', 'DATUM', 'ČÁSTKA', 'ZDROJ', 'DETAIL']
    const cellsContracts = ['SMLOUVA', 'PODEPSÁNO', 'DATUM AKCE', 'PDF']

    const handleTab = (id: number) => {
        setTabs((prev) => prev.map((tab) => ({ ...tab, current: tab.id === +id })))
    }

    useEffect(() => {
        (async () => {
            const loanContracts = await contractsList(EContractsType.Contracts)
            const deferralContracts = await contractsList(EContractsType.Deferral)
            setContracts({ loanContracts, deferralContracts })
        })()
    }, [])

    if (!loan) return null

    return (
        <ModalWrapper open={open} setOpen={setOpen} size={'sm:max-w-7xl'}>
            <ModalTitle title={`Detail úvěru - ID: ${loan.contract_number}`} />

            <Navigation
                tabs={tabs.filter((tab) => {
                    if (+loan?.id === activeLoanQuery?.data?.id) {
                        return tab
                    } else if (loan?.id !== activeLoanQuery?.data?.id) {
                        return tab.id !== 2
                    }
                })}
                setTab={handleTab}
            />

            {tabs.find((tab) => tab.current && tab.id === 1) && (
                <InfoTab loan={loan} cells={cells} />
            )}

            {tabs.find((tab) => tab.current && tab.id === 2) && (
                <TopayTab loan={loan} cells={cellsToPay} />
            )}

            {tabs.find((tab) => tab.current && tab.id === 3) && (
                <PaymentsTab loan={loan} cells={cellsPayments} />
            )}

            {tabs.find((tab) => tab.current && tab.id === 4) && (
                <ContractsTab cells={cellsContracts} contracts={contracts} loan={loan} />
            )}

            <ButtonCommon
                className='bg-red-600 hover:bg-red-700 md:block md:max-w-md md:mx-auto'
                onClick={closeModal}>
                Zavřít
            </ButtonCommon>
        </ModalWrapper>
    )
}

export default DetailModal
