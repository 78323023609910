import { Customer } from 'api/customers/types'

export const getAddressTimeline = (customer: Customer, eventTypes: any) => {
    const addressTimeline = []

    const businessAddress = {
        id: 1,
        type: eventTypes.applied,
        content: 'Adresa sídla',
        target: `${customer.business.address.street} ${customer.business.address.house_number}, ${customer.business.address.city} ${customer.business.address.postal_code}`,
    }

    const correspondenceAddress = {
        id: 2,
        type: eventTypes.advanced,
        content: 'Korespondenční adresa - aktivní',
        target: `${customer?.address?.street} ${customer?.address?.house_number}, ${customer?.address?.city} ${customer?.address?.postal_code}`,
    }

    addressTimeline.push(businessAddress)
    if (customer.address) {
        addressTimeline.push(correspondenceAddress)
    }

    return addressTimeline
}
