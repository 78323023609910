import React, { FC } from 'react'

const QuestionEight: FC = () => {
    return (
        <div className='space-y-4'>
            <p>
                Bohužel, ale musíme i některé žádosti zamítnout. Pokud obdržíte informaci SMS nebo
                @mailem o tom, že byla <b>žádost zamítnuta Robotem</b>, může jít o drobný nedostatek
                ve Vaší žádosti, ale také o vážnější záležitost.
            </p>

            <p>
                Rozhodně nám napište na{' '}
                <a className='underline text-lightBlue' href='mailto:admin@vistaredit.cz'>
                    admin@vistaredit.cz
                </a>{' '}
                a zjistíme, co bylo příčinou. V mnoha případech jsme schopni{' '}
                <b>ještě vše zachránit a úvěr Vám poskytnout</b>.
            </p>

            <p>
                Pokud jste obdrželi informaci o tom, že <b>žádost byla Zamítnuta</b>, bylo
                pravděpodobně zjištěno, že podklady Vaší žádosti neodpovídají našim interním
                podmínkám a nejsme schopni Vám úvěr poskytnout.
            </p>

            <p>
                <b>V řadě případů se jedná o vážné porušení interních podmínek naší společnosti</b>.
                Pokud Vám byl úvěr Zamítnutý, nelze po dobu 3 měsíců odeslat novou žádost.
            </p>
            <p>
                Můžete nám také poslat dotaz přes <b>Kontaktní formulář</b> výběrem volby :{' '}
                <b>Vlastní dotaz, námět, prosba, pochvala, kritika</b>.
            </p>

            {/*<img src={Question1_2} className='max-w-xl my-3' alt='question3 image' />*/}
        </div>
    )
}

export default QuestionEight
