import { FC, useEffect } from 'react'
import { RadioGroup } from '@headlessui/react'
import { classNames } from 'utils'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { TLoanResponse } from 'api/services/loan/types'

interface IPaymentModalQuickChoiceProps {
    data: any[]
    selectedData: any
    loan?: TLoanResponse
    setSelectedData: (value: any) => void
    form: any
}

const PaymentModalQuickChoice: FC<IPaymentModalQuickChoiceProps> = ({
    selectedData,
    setSelectedData,
    loan,
    data,
    form,
}) => {
    const handleQuickChoice = (value: any) => {
        form.setValue('price', Math.round(((loan?.sum ?? 0) * value) / 100))
    }

    useEffect(() => {
        form.setValue('price', Math.round(((loan?.sum ?? 0) * 100) / 100))
    }, [])

    return (
        <div className='my-6'>
            <RadioGroup value={selectedData} onChange={setSelectedData}>
                <RadioGroup.Label className='text-base font-medium text-gray-900 text-center sm:text-left'>
                    Rýchla volba
                </RadioGroup.Label>

                <div className='mt-4 grid grid-cols-1 gap-y-3 sm:grid-cols-3 sm:gap-x-4'>
                    {data.map((item) => (
                        <RadioGroup.Option
                            key={item.id}
                            {...form.register('quickChoice')}
                            value={item.value}
                            checked={item.id === selectedData.id}
                            onClick={() => handleQuickChoice(item.value)}
                            className={({ checked, active }) =>
                                classNames(
                                    checked ? 'border-transparent' : 'border-borderGray',
                                    active ? 'border-indigo-500 ring-2 ring-indigo-500' : '',
                                    'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none focus:ring-0',
                                )
                            }>
                            {({ checked, active }) => (
                                <>
                                    <span className='flex flex-1'>
                                        <span className='flex flex-wrap'>
                                            <RadioGroup.Label
                                                as='span'
                                                className='block text-sm font-normal text-gray-900 mr-2'>
                                                {item.title}
                                            </RadioGroup.Label>
                                            <RadioGroup.Description
                                                as='span'
                                                className='flex items-center text-sm font-light text-lightGray'>
                                                {item.description}
                                            </RadioGroup.Description>
                                        </span>
                                    </span>
                                    <CheckCircleIcon
                                        className={classNames(
                                            !checked ? 'invisible' : '',
                                            'h-5 w-5 text-lightPurple',
                                        )}
                                        aria-hidden='true'
                                    />
                                    <span
                                        className={classNames(
                                            active ? 'border-2' : 'border-2',
                                            checked ? 'border-lightPurple' : 'border-transparent',
                                            'pointer-events-none absolute -inset-px rounded-lg',
                                        )}
                                        aria-hidden='true'
                                    />
                                </>
                            )}
                        </RadioGroup.Option>
                    ))}
                </div>
            </RadioGroup>
        </div>
    )
}

export default PaymentModalQuickChoice
