import { FC } from 'react'

type TBadgeProps = {
    type?: string
    text?: string
    fontStyle?: 'uppercase' | 'normal' | 'capitalize'
}

export const Badge: FC<TBadgeProps> = ({ type, text, fontStyle = 'normal' }) => {
    const applyClassesByType = (): string => {
        switch (type) {
            case 'status_green':
                return 'bg-status-green '
            case 'status_red':
                return 'bg-status-red '
            case 'status_orange':
                return 'bg-status-orange '
            case 'status_blue':
                return 'bg-status-blue '
            case 'status_gray':
                return 'bg-status-gray '

            default:
                return 'bg-status-blue'
        }
    }

    return (
        <span
            className={`text-sm font-normal text-white ${fontStyle} mr-2 px-6 lg:px-3 2xl:px-6 py-3 rounded
                ${applyClassesByType()}`}>
            {text}
        </span>
    )
}
