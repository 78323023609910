import React, { FC } from 'react'
import Question19_1 from 'assets/images/question19_1.png'

const QuestionNineteen: FC = () => {
    return (
        <div className='space-y-4'>
            <p>
                Odměňujeme poctivé splácení. Za splacený úvěr klient obdrží daný počet bodů (mimo
                splacení úvěru u služby VistaRENT).
            </p>

            <p>Konkrétní výpočet je:</p>
            <ul className=' space-y-3 pl-10 list-disc '>
                <li>
                    <b>Získání bodů</b> funguje následovně - za každých splacených 1 000 Kč jistiny
                    úvěru získáte 10 bodů (například: splatíte-li úvěr ve výši 8 000 Kč, získáte 80
                    bodů)
                </li>
                <li>
                    <b>Čerpání bodů</b> je počítáno následovně: 1 bod = 5 Kč. (například: pokud je
                    cena za Odložení splatnosti o 35 dnů 825 Kč a použijete k úhradě nasbírané body,
                    strhneme Vám jich 165)
                </li>
                <li>
                    Nasbírané body můžete použít ve chvíli, kdy jste požádal(a) o Odložení
                    splatnosti a systém Vás vyzývá k úhradě poplatku za odložení splatnosti. Pokud
                    systém zjistí, že na to máte dostatek bodů, nenabídne Vám tuto formu úhrady.
                </li>
            </ul>
            <p>
                <b>* TIP pro Vás</b>
            </p>

            <p>
                V praxi to může znamenat, že když splatíte včas dva úvěry ve výši jistiny 8 000 Kč -
                získáte 160 bodů a to = 35 dnů splatnosti navíc ZDARMA !
            </p>

            <p>
                <b>Získávání bodů má i svá pravidla !</b>
            </p>
            <ul className=' space-y-3 pl-10 list-disc '>
                <li>
                    body můžete uplatnit až v kategorii VistaBusiness, kdy je možné je směnit za
                    Odložení doby splatnosti.
                </li>
                <li>pokud splatíte svůj závazek po splatnosti více jak 10 dnů, body Vám smažeme</li>
                <li>
                    pokud nemáte dostatek bodů, můžete snížit požadovanou dobu Odložení splatnosti
                    nebo uhradit penězi.
                </li>
                <li>
                    pokud chcete snížit požadovanou dobu Odložení splatnosti, musíte nejdříve
                    původní žádost STORNOVAT.
                </li>
                <li>
                    <b>Nelze kombinovat</b> úhradu poplatku za doložení body a penězi
                </li>
            </ul>

            <p>Svou žádost stornujte tlačítkem Stornovat odklad v klientském účtu.</p>
            <img
                src={Question19_1}
                className='w-fit lg:max-w-xl h-12  my-3'
                alt='question3 image'
            />

            <p>
                Můžete nám také poslat dotaz přes <b>Kontaktní formulář</b> výběrem volby :{' '}
                <b>Vlastní dotaz, námět, prosba, pochvala, kritika</b>.
            </p>

            {/*<img src={Question1_2} className='max-w-xl my-3' alt='question3 image' />*/}
        </div>
    )
}

export default QuestionNineteen
