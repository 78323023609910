import React, { FC } from 'react'
import Question12_1 from 'assets/images/question12_1.png'
import Question12_2 from 'assets/images/question12_2.png'

const QuestionTwelve: FC = () => {
    return (
        <div className='space-y-4'>
            <p>
                Změnit dokumenty (kopii OP a výpis z účtu){' '}
                <b>můžete pouze v případě, že nemáte aktivní úvěr</b>. To znamená, že Váš poslední
                úvěr je ve stavu Splaceno.
            </p>

            <p>Postup:</p>
            <ul className=' space-y-3 pl-10 list-disc '>
                <li>
                    klikněte na Vaše jméno v horní části, případně v pravém horním roku a zobrazí se
                    Vám nabídka. Nyní <b>klikněte na položku Můj účet</b> a vstoupíte do Vašeho
                    osobního menu, kde ve spodní části je blok Osobní doklady.
                </li>
                <li>
                    pokud máte možnost vložit doklad, bude tlačítko NAHRÁT DOKLADY aktivní a po jeho
                    stisknutí se Vám zobrazí Okno pro nahrávání dokladů.
                </li>
                <li>dále již budete postupovat dle průvodce přímo v tomto okně.</li>
            </ul>

            <img src={Question12_1} className='w-fit lg:max-w-xl my-3' alt='question3 image' />

            <p>
                Pokud se po stisku tlačítka NAHRÁT DOKLAD nezobrazí okno viz. výše - máte
                pravděpodobně aktivní - nesplacený úvěr a doklady nyní změnit nelze!
            </p>

            <p>
                Po úspěšném nahrání se současné doklady “deaktivují”, tzn., že jejich platnost musí
                být opět ověřena administrátorem.
            </p>

            <div className='flex justify-start items-center'>
                <img src={Question12_2} className='block h-12 my-3' alt='question3 image' />

                <ul className='space-y-3 pl-10 list-disc '>
                    <li>doklad je potvrzen administrátorem žádosti</li>
                    <li>doklad čeká na potvrzení administrátorem žádosti</li>
                </ul>
            </div>
        </div>
    )
}

export default QuestionTwelve
