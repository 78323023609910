import React, { FC } from 'react'

const QuestionFifteen: FC = () => {
    return (
        <div className='space-y-4'>
            <p>
                Změna ostatních osobních údajů klienta je možná pouze ze strany administrátorů.
                Pokud potřebujete jakýkoliv svůj osobní údaj změnit, napište nám @mail na{' '}
                <a href='mailto:admin@vistacredit.cz' className='underline text-lightBlue'>
                    admin@vistacredit.cz
                </a>{' '}
                a údaj Vám změníme.{' '}
            </p>

            <p>
                <b>
                    <u>POZOR:</u>
                </b>{' '}
                změnou @mailové adresy si automaticky změníte přihlašovací údaj k Vašemu účtu,
                (heslo zůstane nezměněno) proto na to myslete před požadovanou změnou !
            </p>

            <p>
                Před změnou telefonního čísla se ujistěte, že nové telefonní číslo používáte -
                posíláme Vám na něj podpisové kódy, upozornění a připomínky. .
            </p>
            <p>
                Můžete nám také poslat dotaz přes <b>Kontaktní formulář</b> výběrem volby :{' '}
                <b>Vlastní dotaz, námět, prosba, pochvala, kritika</b>.
            </p>

            {/*<img src={Question1_2} className='max-w-xl my-3' alt='question3 image' />*/}
        </div>
    )
}

export default QuestionFifteen
