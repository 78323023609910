import React from 'react'
import { PageWrapLayout } from 'layout/components'
import {
    AcademicCapIcon,
    ArchiveBoxXMarkIcon,
    ArrowPathRoundedSquareIcon,
    BanknotesIcon,
    CheckBadgeIcon,
    ClockIcon,
    ReceiptRefundIcon,
    UsersIcon,
} from '@heroicons/react/24/solid'
import { classNames } from 'utils'

const Gdpr = () => {
    const actions = [
        {
            title: 'Informace ke zpracování osobních dat',
            href: 'https://fra1.digitaloceanspaces.com/vistacredit/static/web/pdf/informace_ke_zpracovani_osobnich_dat.pdf',
            icon: UsersIcon,
            iconForeground: 'text-teal-700',
            iconBackground: 'bg-teal-50',
        },
        {
            title: 'Žádost o přenos osobních údajů',
            href: 'https://fra1.digitaloceanspaces.com/vistacredit/static/web/pdf/zadost_o_prenos_osobnich_udaju.pdf',
            icon: CheckBadgeIcon,
            iconForeground: 'text-purple-700',
            iconBackground: 'bg-purple-50',
        },
        {
            title: 'Výmaz osobních údajů',
            href: 'https://fra1.digitaloceanspaces.com/vistacredit/static/web/pdf/vymaz_osobnich_udaju.pdf',
            icon: ArchiveBoxXMarkIcon,
            iconForeground: 'text-sky-700',
            iconBackground: 'bg-sky-50',
        },
        {
            title: '',
            href: '#',
            icon: null,
            iconForeground: '',
            iconBackground: '',
        },
    ]

    return (
        <PageWrapLayout title={'GDPR soubory'}>
            <div className='divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 border border-borderGray sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0'>
                {actions.map((action, actionIdx) => {
                    if (!action.icon)
                        return (
                            <div
                                key={'none-doc'}
                                className={classNames(
                                    actionIdx === 0
                                        ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none'
                                        : '',
                                    actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                                    actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
                                    actionIdx === actions.length - 1
                                        ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none'
                                        : '',
                                    'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500',
                                )}></div>
                        )
                    return (
                        <div
                            key={action.title}
                            className={classNames(
                                actionIdx === 0
                                    ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none'
                                    : '',
                                actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                                actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
                                actionIdx === actions.length - 1
                                    ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none'
                                    : '',
                                'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500',
                            )}>
                            <div>
                                <span
                                    className={classNames(
                                        action.iconBackground,
                                        action.iconForeground,
                                        'rounded-lg inline-flex p-3 ring-4 ring-white',
                                    )}>
                                    <action.icon className='h-6 w-6' aria-hidden='true' />
                                </span>
                            </div>
                            <div className='mt-8'>
                                <h3 className='text-lg font-medium'>
                                    <a
                                        rel='noreferrer'
                                        href={action.href}
                                        target='_blank'
                                        className='focus:outline-none'>
                                        {/* Extend touch target to entire panel */}
                                        <span className='absolute inset-0' aria-hidden='true' />
                                        {action.title}
                                    </a>
                                </h3>
                                <p className='mt-2 text-sm text-gray-500'>
                                    K dispozici ke stažení v PDF formátu.
                                </p>
                            </div>
                            <span
                                className='pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400'
                                aria-hidden='true'>
                                <svg
                                    className='h-6 w-6'
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='currentColor'
                                    viewBox='0 0 24 24'>
                                    <path d='M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z' />
                                </svg>
                            </span>
                        </div>
                    )
                })}
            </div>
        </PageWrapLayout>
    )
}

export default Gdpr
