import React from 'react'
import VistaLogo from './vistacreditlogo.png'

export const Logo = () => (
    <div className='flex h-24 w-52 p-4 items-center justify-center align-center md:w-72'>
        <img src={VistaLogo} alt='' />
        {/*<svg*/}
        {/*    width='240'*/}
        {/*    height='42'*/}
        {/*    viewBox='0 0 240 42'*/}
        {/*    fill='none'*/}
        {/*    xmlns='http://www.w3.org/2000/svg'>*/}
        {/*    <path*/}
        {/*        d='M4.20117 9.09448L14.5555 34.9982L24.9944 9.09448H29.1518L16.6334 39.5034H12.5627L0 9.09448H4.20117Z'*/}
        {/*        fill='#00B8F5'*/}
        {/*    />*/}
        {/*    <path*/}
        {/*        d='M33.3082 39.4159H36.8593V17.9307H33.3082V39.4159ZM37.4663 11.52C37.4663 14.5957 32.7869 14.5957 32.7869 11.4775C32.7869 8.40174 37.4663 8.40174 37.4663 11.52Z'*/}
        {/*        fill='#00B8F5'*/}
        {/*    />*/}
        {/*    <path*/}
        {/*        d='M57.09 22.7831C55.2277 21.0932 53.278 20.7029 51.0679 20.7029C48.1233 20.6598 45.7413 21.6996 45.785 23.8223C45.8275 26.0319 48.4267 26.6814 51.1116 26.9843C55.0104 27.4602 60.2083 28.2408 60.1221 33.5254C60.0359 37.8134 55.964 39.9355 51.1547 39.9355C47.5605 39.9355 44.0077 38.9394 41.5833 35.9068L43.6192 33.4392C45.5234 35.7775 48.5998 36.7305 51.2427 36.7305C53.5803 36.7305 56.5693 35.9948 56.6561 33.395C56.6992 31.0561 54.1879 30.3629 50.8955 29.9732C46.78 29.4536 42.3633 28.3707 42.3633 23.8223C42.3184 19.274 46.9104 17.4979 51.0254 17.541C54.2304 17.541 56.8739 18.3216 59.1697 20.4006L57.1337 22.7394L57.09 22.7831Z'*/}
        {/*        fill='#00B8F5'*/}
        {/*    />*/}
        {/*    <path*/}
        {/*        d='M70.9927 18.062H77.6195V21.0935H70.9496V32.3991C70.9496 34.9115 71.7733 36.514 74.3282 36.514C75.2806 36.514 76.3641 36.2111 77.3177 35.734L78.3575 38.6793C76.9274 39.3299 75.6284 39.676 74.1977 39.7197C69.608 39.8065 67.4416 37.1204 67.4416 32.3991V21.0935H63.1531V18.062H67.4416V12.1703L70.9927 11.7806V18.062Z'*/}
        {/*        fill='#00B8F5'*/}
        {/*    />*/}
        {/*    <path*/}
        {/*        d='M91.9579 21.0507C87.7136 21.0507 84.2919 24.0833 84.2919 28.8472C84.2919 33.6123 87.7136 36.6874 91.9579 36.6874C102.267 36.6874 102.267 21.007 92.0016 21.007L91.9579 21.0507ZM99.755 39.5033L99.6688 35.6045C97.9794 38.5503 94.7307 39.9361 91.7849 39.9361C85.634 39.9361 80.8264 35.6913 80.8264 28.7173C80.8264 21.787 85.7202 17.6284 91.8286 17.6715C95.0336 17.6715 98.1955 19.1441 99.755 21.9163L99.7987 18.0187H103.265V39.4165H99.8418L99.755 39.5033Z'*/}
        {/*        fill='#00B8F5'*/}
        {/*    />*/}
        {/*    <path*/}
        {/*        d='M129.706 41.2208C119.007 41.2208 113.938 33.814 113.896 25.7571C113.81 17.7433 119.05 9.94684 129.706 9.94684C133.735 9.94684 137.719 11.4626 140.795 14.4952L138.196 16.9208C135.857 14.6245 132.782 13.5416 129.706 13.5416C121.563 13.5416 117.62 19.4332 117.665 25.7134C117.752 31.8655 121.52 37.6261 129.706 37.6261C132.782 37.6261 136.03 36.4133 138.327 34.1175L140.968 36.7162C137.893 39.7482 133.864 41.2208 129.706 41.2208Z'*/}
        {/*        fill='#B800F5'*/}
        {/*    />*/}
        {/*    <path*/}
        {/*        d='M148.765 19.3034L149.024 22.509C150.498 19.7368 153.139 18.9568 155.61 18.9568C157.472 18.9131 159.291 19.5638 160.591 20.5593L158.988 23.5919C157.862 22.8119 156.735 22.4222 155.263 22.4222C151.841 22.4653 148.981 24.8915 148.981 28.8329V40.7455H145.43V19.3471H148.722L148.765 19.3034Z'*/}
        {/*        fill='#B800F5'*/}
        {/*    />*/}
        {/*    <path*/}
        {/*        d='M180.345 28.4859C180.171 24.3267 177.312 22.0752 173.112 22.0752C169.385 22.0752 166.136 24.3267 165.53 28.4428H180.345V28.4859ZM182.423 37.3234C180.257 39.8777 176.531 41.1342 173.197 41.1342C166.569 41.1342 161.935 36.7595 161.935 29.9585C161.935 23.5053 166.569 18.8696 173.024 18.8696C179.565 18.8696 184.547 22.8977 183.723 31.4311H165.53C165.919 35.3731 169.168 37.8424 173.285 37.8424C175.71 37.8424 178.655 36.8894 180.085 35.157L182.466 37.3234H182.423Z'*/}
        {/*        fill='#B800F5'*/}
        {/*    />*/}
        {/*    <path*/}
        {/*        d='M198.45 22.2482C194.292 22.2482 190.912 24.9774 190.912 30.0022C190.912 34.9403 194.292 37.7556 198.45 37.7556C202.696 37.7556 206.03 34.6374 206.03 29.9585C206.03 25.3671 202.651 22.2482 198.407 22.2482H198.45ZM209.712 10.3799V40.7014H206.377L206.204 36.8026C204.471 39.7484 201.352 41.1342 198.19 41.1342C192.082 41.1342 187.361 37.1055 187.361 30.0453C187.361 22.8552 192.082 18.9564 198.148 18.9564C201.179 18.9564 204.645 20.3422 206.161 23.2443V10.423H209.669L209.712 10.3799Z'*/}
        {/*        fill='#B800F5'*/}
        {/*    />*/}
        {/*    <path*/}
        {/*        d='M216.207 40.7449H219.759V19.2602H216.207V40.7449ZM220.366 12.8489C220.366 15.9241 215.687 15.9241 215.687 12.8058C215.687 9.7301 220.366 9.7301 220.366 12.8489Z'*/}
        {/*        fill='#B800F5'*/}
        {/*    />*/}
        {/*    <path*/}
        {/*        d='M232.276 19.3904H238.904V22.4225H232.234V33.7281C232.234 36.2411 233.057 37.843 235.612 37.843C236.564 37.843 237.647 37.5395 238.601 37.063L239.641 40.0089C238.211 40.659 236.913 41.0044 235.483 41.0481C230.89 41.1355 228.724 38.4495 228.724 33.7281V22.4225H224.436V19.3904H228.724V13.4988L232.276 13.1091V19.3904Z'*/}
        {/*        fill='#B800F5'*/}
        {/*    />*/}
        {/*    <path*/}
        {/*        d='M37.522 11.4948C37.522 12.8369 36.4361 13.9239 35.0946 13.9239C33.753 13.9239 32.6643 12.8369 32.6643 11.4948C32.6643 10.1544 33.753 9.06622 35.0946 9.06622C36.4361 9.06622 37.522 10.1544 37.522 11.4948Z'*/}
        {/*        fill='#B800F5'*/}
        {/*    />*/}
        {/*    <path*/}
        {/*        d='M162.708 12.1283L185.806 12.2151L174.257 0.611206L162.708 12.1283Z'*/}
        {/*        fill='#00B8F5'*/}
        {/*    />*/}
        {/*</svg>*/}
    </div>
)
