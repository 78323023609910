import { FC, useEffect } from 'react'
import { Pattern } from 'assets/pattern/Pattern'
import UnloggedLayout from 'layout/UnloggedLayout'
import LoginForm from 'pages/login/components/LoginForm'
import LoginFormWrap from 'pages/login/components/LoginFormWrap'
import Notification from 'layout/components/notifications/Notification'
import { useAppSelector } from 'store/store'
import { routerCatalog } from 'routes/hooks/useSecureRouting'
import { Navigate } from 'react-router'
import Gleap from 'gleap'

const Login: FC = () => {
    const notification = useAppSelector((state) => state.app.notification)
    const Token = useAppSelector((state) => state.app.token)

    useEffect(() => {
        Gleap.showFeedbackButton(false)
    }, [])

    if (Token) return <Navigate to={routerCatalog.dashboard} />

    return (
        <div
            className={'h-full w-full bg-appBackground'}
            style={{
                height: '100%',
                background:
                    'radial-gradient(116.48% 209.06% at 80.47% 81.35%,#dff7ff 0,hsla(0,0%,100%,0) 100%),#fff',
            }}>
            <div className={'2xl:container relative mx-auto h-full w-full overflow-x-hidden'}>
                {/* Background pattern */}
                <Pattern className={'hidden md:block'} />

                {/* Navigation */}
                <UnloggedLayout />

                {/* Content - login form*/}
                <LoginFormWrap>
                    <LoginForm />
                </LoginFormWrap>
            </div>
            {notification && <Notification notification={notification} />}
        </div>
    )
}

export default Login
