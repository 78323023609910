import { TCustomer } from 'api/services/customer/types'

export const getIdentityStatus = (customer?: TCustomer | null) => {
    if (!customer) {
        return { text: '', status: 'pending' }
    }
    if (customer?.papers_uploaded && customer?.papers_checked) {
        return { text: 'Byla ověřena identita klienta', status: 'success' }
    } else if (customer?.papers_uploaded && !customer?.papers_checked) {
        return { text: 'Probíhá ověření identity klienta', status: 'pending' }
    } else {
        return { text: 'Nebyla ověřena identita klienta', status: 'error' }
    }
}
