import { FC } from 'react'
import { classNames } from 'utils'
import { ButtonPurple } from '@vistacredit/lib-ui'
import { BuildingOffice2Icon, BuildingOfficeIcon } from '@heroicons/react/24/solid'
import { Customer } from 'api/customers/types'
import { getAddressTimeline } from 'pages/profile/utils/getAddressTimeline'
import { routerCatalog } from 'routes/hooks/useSecureRouting'
import { useNavigate } from 'react-router'

interface IProfileAddressProps {
    customer: Customer
}

const ProfileAddress: FC<IProfileAddressProps> = ({ customer }) => {
    const eventTypes = {
        applied: { icon: BuildingOfficeIcon, bgColorClass: 'bg-lightGray' },
        advanced: { icon: BuildingOffice2Icon, bgColorClass: 'bg-green-500' },
    }
    const navigate = useNavigate()

    const timeline = getAddressTimeline(customer, eventTypes)

    return (
        <div className='bg-white px-4 py-5 rounded-lg sm:px-6 border border-borderGray'>
            <h2 id='timeline-title' className='text-lg font-medium text-gray-900'>
                Adresa sídla
            </h2>

            {/* Activity Feed */}
            <div className='mt-6 flow-root'>
                <ul role='list' className='-mb-8'>
                    {timeline.map((item, itemIdx) => (
                        <li key={item.id}>
                            <div className='relative pb-8'>
                                {itemIdx !== timeline.length - 1 ? (
                                    <span
                                        className='absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200'
                                        aria-hidden='true'
                                    />
                                ) : null}
                                <div className='relative flex space-x-3'>
                                    <div>
                                        <span
                                            className={classNames(
                                                item.type.bgColorClass,
                                                'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white',
                                            )}>
                                            <item.type.icon
                                                className='h-5 w-5 text-white'
                                                aria-hidden='true'
                                            />
                                        </span>
                                    </div>
                                    <div className='flex min-w-0 flex-1 justify-between space-x-4 pt-1.5'>
                                        <div>
                                            <p className='text-sm text-gray-500'>
                                                {item.content} <br />
                                                <a href='#' className='font-medium text-gray-900'>
                                                    {item.target}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            <div className='justify-stretch mt-6 flex flex-col'>
                <ButtonPurple onClick={() => navigate(routerCatalog.contact)}>
                    Změnit adresu
                </ButtonPurple>
            </div>
        </div>
    )
}

export default ProfileAddress
