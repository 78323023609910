import { LoginRequest, LoginResponse, PasswordChangeRequest, PasswordResetRequest } from './types'
import { ajax } from 'api/index'

interface IAuthApi {
    authLogin: (data: LoginRequest) => Promise<LoginResponse>
    authLogout: () => Promise<void>
    authPasswordChange: (data: PasswordChangeRequest) => Promise<void>
    authPasswordReset: (data: PasswordResetRequest) => Promise<void>
}

export const useAuthApi = (): IAuthApi => {
    const authLogin = async (data: LoginRequest): Promise<LoginResponse> => {
        try {
            const res = await ajax.post('auth/login/', data)
            return Promise.resolve(res.data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const authLogout = async (): Promise<void> => {
        try {
            const res = await ajax.post('auth/logout/')
            return Promise.resolve(res.data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const authPasswordChange = async (data: PasswordChangeRequest): Promise<void> => {
        try {
            const res = await ajax.post('auth/password-change/', data)
            return Promise.resolve(res.data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const authPasswordReset = async (data: PasswordResetRequest): Promise<void> => {
        try {
            const res = await ajax.post('auth/password-reset/', data)
            return Promise.resolve(res.data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    return {
        authLogin,
        authLogout,
        authPasswordChange,
        authPasswordReset,
    }
}
