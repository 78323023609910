export enum LoanStatus {
    New = 0,
    PreApproved = 1,
    Approved = 2,
    Denied = 3,
    Processed = 4,
    SentToBankAccount = 5,
    Overdue = 7,
    WriteOff = 8,
    InkasoEOS = 9, // nejde sa prihlasit na ucet pri tomto stave
    Counsel = 10,
    SaleOfReceivable = 11, // nejde sa prihlasit na ucet pri tomto stave
    Police = 12, // nejde sa prihlasit na ucet pri tomto stave
    Storno = 13,
    PaidBack = 14,
    RejectedCounterproposal = 15,
    BeforeMaturity = 16, // nejde sa prihlasit na ucet pri tomto stave
    Insolvency = 17, // nejde sa prihlasit na ucet pri tomto stave
    BeforeComplaint = 18,
    DeniedByRobot = 19,
    Trial = 20,
    Arbitration = 21,
    Execution = 22,
    BeforeCollection = 23,
    BeforeInsolvency = 24, // nejde sa prihlasit na ucet pri tomto stave
    FinishedInsolvency = 25,
    InkasoMaxifine = 26, // nejde sa prihlasit na ucet pri tomto stave
}

export enum LoanStatusColors {
    Green = 'status_green',
    Red = 'status_red',
    Orange = 'status_orange',
    Blue = 'status_blue',
    Gray = 'status_gray',
    White = 'status_white',
}

export const getLoanStatusColor = (status?: number): string => {
    if (!status) return LoanStatusColors.White

    switch (status) {
        case LoanStatus.Approved:
            return LoanStatusColors.Green
        case LoanStatus.SentToBankAccount:
            return LoanStatusColors.Blue
        case LoanStatus.PaidBack:
            return LoanStatusColors.Green

        case LoanStatus.Denied:
            return LoanStatusColors.Red
        case LoanStatus.WriteOff:
            return LoanStatusColors.Red
        case LoanStatus.InkasoEOS:
            return LoanStatusColors.Orange
        case LoanStatus.Counsel:
            return LoanStatusColors.Red
        case LoanStatus.SaleOfReceivable:
            return LoanStatusColors.Red
        case LoanStatus.Storno:
            return LoanStatusColors.Gray
        case LoanStatus.Police:
            return LoanStatusColors.Red
        case LoanStatus.Insolvency:
            return LoanStatusColors.Red
        case LoanStatus.DeniedByRobot:
            return LoanStatusColors.Red
        case LoanStatus.InkasoMaxifine:
            return LoanStatusColors.Orange

        case LoanStatus.PreApproved:
            return LoanStatusColors.Orange
        case LoanStatus.Processed:
            return LoanStatusColors.Orange
        case LoanStatus.Overdue:
            return LoanStatusColors.Orange
        case LoanStatus.RejectedCounterproposal:
            return LoanStatusColors.Orange
        case LoanStatus.BeforeMaturity:
            return LoanStatusColors.Orange
        case LoanStatus.BeforeInsolvency:
            return LoanStatusColors.Orange
        case LoanStatus.BeforeComplaint:
            return LoanStatusColors.Orange
        case LoanStatus.BeforeCollection:
            return LoanStatusColors.Red

        default:
            return LoanStatusColors.White
    }
}
