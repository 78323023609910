import React, { FC } from 'react'
import { PaperClipIcon } from '@heroicons/react/24/solid'
import { EContractDocumentType } from 'api/contracts/types'
import { ButtonPurple } from '@vistacredit/lib-ui'
import { useNavigate } from 'react-router'
import { routerCatalog } from 'routes/hooks/useSecureRouting'
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline'
import { IContractsList } from 'api/services/customer/types'

interface IProfilePersonalDocumentsProps {
    contracts?: IContractsList
}

const ProfilePersonalDocuments: FC<IProfilePersonalDocumentsProps> = ({ contracts }) => {
    const navigate = useNavigate()

    return (
        <div className='bg-white px-4 mt-6 py-5 border border-borderGray rounded-lg sm:px-6'>
            <h2 id='timeline-title' className='text-lg font-medium text-gray-900'>
                Osobní dokumenty
            </h2>

            {/* Activity Feed */}
            <div className='mt-6 flow-root'>
                <dl className='grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2'>
                    <div className='sm:col-span-2'>
                        <dd className='mt-1 text-sm text-gray-900'>
                            <ul
                                role='list'
                                className='divide-y divide-gray-200 rounded-md border border-gray-200'>
                                {contracts?.results
                                    .filter(
                                        (attachment) =>
                                            attachment.type !==
                                                EContractDocumentType.LoanContract &&
                                            attachment.type !== EContractDocumentType.Postponement,
                                    )
                                    .filter((attachment, index) => index < 4)
                                    .map((attachment) => (
                                        <li
                                            key={attachment.id}
                                            className='flex items-center justify-between py-3 pl-3 pr-4 text-sm'>
                                            <div className='flex w-0 flex-1 items-center'>
                                                <PaperClipIcon
                                                    className='h-5 w-5 flex-shrink-0 text-gray-400'
                                                    aria-hidden='true'
                                                />
                                                <span className='ml-2 w-0 flex-1 truncate'>
                                                    {attachment.type_text}
                                                </span>
                                            </div>
                                            <div className='ml-4 flex-shrink-0'>
                                                <a
                                                    rel='noreferrer'
                                                    href={attachment.url}
                                                    target='_blank'
                                                    className='flex justify-end text-lightPurple hover:text-lightBlue'>
                                                    <DocumentArrowDownIcon className='w-5 h-5 mr-1' />
                                                    <span>PDF</span>
                                                </a>
                                            </div>
                                        </li>
                                    ))}
                            </ul>
                        </dd>
                    </div>
                </dl>
            </div>
            <div className='justify-stretch mt-6 flex flex-col'>
                <ButtonPurple onClick={() => navigate(routerCatalog.documents)}>
                    Zobrazit všechny dokumenty
                </ButtonPurple>
            </div>
        </div>
    )
}

export default ProfilePersonalDocuments
