import { FC, useEffect, useState } from 'react'
import { PageWrapLayout } from 'layout/components'
import HeaderTabs from 'pages/newloan/components/HeaderTabs'
import LoanCalcTable from 'pages/newloan/components/LoanCalcTable'
import Calculation from 'pages/newloan/components/Calculation'
import { ButtonCommon } from '@vistacredit/lib-ui'
import LoanCalcSkeleton from 'pages/newloan/components/LoanCalcSkeleton'
import { routerCatalog } from 'routes/hooks/useSecureRouting'
import { useNavigate } from 'react-router'
import VerificationModal from 'pages/newloan/components/VerificationModal'
import { useDispatch } from 'react-redux'
import { setFlagWasSet, setNotification } from 'store/stateReducer'
import { useGetPriceListQuery } from 'api/services/utils/pricesLists/servicePriceLists'
import { useCreateLoanMutation, useGetActiveLoanQuery } from 'api/services/loan/serviceLoan'
import {
    useGetCustomerPapersQuery,
    useGetCustomerQuery,
} from 'api/services/customer/serviceCustomer'
import { UserCategory } from 'utils/customerCategory'
import { useAppSelector } from 'store/store'

const NewLoan: FC = () => {
    const [activeTab, setActiveTab] = useState('fix')
    const [showVerificationModal, setShowVerificationModal] = useState(false)
    const [priceRange, setPriceRange] = useState<number | number[]>(4000)
    const [daysRange, setDaysRange] = useState<number | number[]>(activeTab === 'fix' ? 35 : 14)

    const activeLoanQuery = useGetActiveLoanQuery()
    const priceListFixQuery = useGetPriceListQuery('fix')
    const priceListRentQuery = useGetPriceListQuery('rent')
    const customerQuery = useGetCustomerQuery()
    const customerPapersQuery = useGetCustomerPapersQuery()
    const [createLoan] = useCreateLoanMutation()

    const notification = useAppSelector((state) => state.app.notification)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const resetRangeValues = () => {
        setDaysRange(activeTab === 'fix' ? 35 : 14)
        setPriceRange(activeTab == 'fix' ? 4000 : 8000)
    }

    const chooseLoanType = (type: string) => {
        setActiveTab(type)
    }

    const handleCreateLoan = async () => {
        await createLoan({
            type: activeTab === 'fix' ? 0 : 1,
            amount: priceRange,
            period: daysRange,
        })
            .then((res) => {
                dispatch(setFlagWasSet(false))

                if ((res as any)?.error) {
                    setTimeout(() => {
                        dispatch(
                            setNotification({
                                type: 'error',
                                title: 'Něco se nepovedlo! ',
                                message: (res as any)?.error?.data?.non_field_errors?.[0],
                            }),
                        )
                        setShowVerificationModal(false)
                    }, 1500)
                } else {
                    setTimeout(() => {
                        dispatch(
                            setNotification({
                                type: 'success',
                                title: 'Žádost byla přijata!',
                                message: 'Vyčkejte prosím na její rychlé posouzení.',
                            }),
                        )
                        navigate(routerCatalog.dashboard, {})
                    }, 1500)
                }
            })
            .catch((err) => {
                setShowVerificationModal(false)
            })
    }

    const verifyUserCategory = (): boolean => {
        if (priceRange <= 4000) {
            return false
        } else if (priceRange <= 6000) {
            return customerQuery.data?.category === UserCategory.Start
        } else {
            return (
                customerQuery.data?.category === UserCategory.Start ||
                customerQuery.data?.category === UserCategory.Plus
            )
        }
    }

    useEffect(() => {
        resetRangeValues()
    }, [activeTab])

    useEffect(() => {
        if (activeLoanQuery?.data) {
            navigate(routerCatalog.dashboard, { replace: true })
            dispatch(
                setNotification({
                    type: 'error',
                    title: 'Chyba!',
                    message: 'Už máte aktívni úvěr. O nový si můžete zažádat až po jeho splacení.',
                }),
            )
        }
    }, [])

    useEffect(() => {
        if (verifyUserCategory()) {
            if (!notification) {
                dispatch(
                    setNotification({
                        type: 'error',
                        title: 'Chyba!',
                        message: 'Ve Vaší kategorii ještě nemáte nárok na tuto částku.',
                    }),
                )
            }
        }
    }, [priceRange])

    useEffect(() => {
        customerQuery.refetch()
    }, [])

    if (!priceListFixQuery?.data || !priceListRentQuery?.data) {
        return <LoanCalcSkeleton />
    }

    return (
        <PageWrapLayout>
            <HeaderTabs activeTab={activeTab} chooseLoanType={chooseLoanType} />
            <div className='grid grid-cols-1 gap-x-6 gap-y-4 xl:grid-cols-10 bg-white rounded-lg border border-borderGray p-4 sm:p-8'>
                <div className='col-span-1 xl:col-span-6 p-4 sm:p-0'>
                    <Calculation
                        activeTab={activeTab}
                        daysRange={daysRange}
                        priceRange={priceRange}
                        setDaysRange={setDaysRange}
                        setPriceRange={setPriceRange}
                    />
                </div>

                <div className='col-span-1 mt-2 sm:mt-0 xl:col-span-4'>
                    <LoanCalcTable
                        preSet={activeTab === 'fix' ? 35 : 14}
                        priceList={
                            activeTab === 'fix' ? priceListFixQuery?.data : priceListRentQuery?.data
                        }
                        rangeDays={daysRange}
                        rangePrice={priceRange}
                    />
                    <div className='mt-6 uppercase'>
                        {/*{verifyUserCategory() && (*/}
                        {/*    <p className='my-4 text-red-500'>*/}
                        {/*        Ve Vaší kategorii ještě nemáte nárok na tuto částku.*/}
                        {/*    </p>*/}
                        {/*)}*/}
                        <ButtonCommon
                            onClick={() => setShowVerificationModal(true)}
                            disabled={verifyUserCategory()}>
                            <span className='uppercase'>Získat úvěr</span>
                        </ButtonCommon>
                    </div>
                </div>
            </div>
            {showVerificationModal && (
                <VerificationModal
                    setOpen={setShowVerificationModal}
                    open={showVerificationModal}
                    handleCreateLoan={handleCreateLoan}
                    handleCloseModal={() => setShowVerificationModal(false)}
                    customer={customerQuery?.data}
                    customerPapers={customerPapersQuery?.data}
                />
            )}
        </PageWrapLayout>
    )
}

export default NewLoan
