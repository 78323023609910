import React, { FC, useState } from 'react'
import ModalTitle from 'layout/components/modal/shared/ModalTitle'
import PDFPreview from 'pages/dashboard/modals/signContract/components/PDFPreview'
import ModalWrapper from 'layout/components/modal/ModalWrapper'
import { ButtonCommon, ButtonWithLoader } from '@vistacredit/lib-ui'
import { classNames } from 'utils'
import { useContractsApi } from 'api'
import { useNavigate } from 'react-router'
import { routerCatalog } from 'routes/hooks/useSecureRouting'

interface IAmendmentModalProps {
    open?: boolean
    setOpen: (value: boolean) => void
    handleSignAmendment: () => void
    file: string
}

const AmendmentRSModal: FC<IAmendmentModalProps> = ({
    open,
    handleSignAmendment,
    file,
    setOpen,
}) => {
    const [disabled, setDisabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const { signDistanceAmendmentRS } = useContractsApi()
    const navigate = useNavigate()

    const signAmendmentRS = async () => {
        try {
            setLoading(true)
            await signDistanceAmendmentRS()
            setOpen(false)
        } catch (error) {
            console.error(error)
        }
        setLoading(false)
    }

    const handleClose = () => {
        setOpen(false)
        navigate(routerCatalog.logout)
    }

    return (
        <ModalWrapper open={open ?? false} setOpen={() => null} size={'sm:max-w-5xl'}>
            <div className='mb-0'>
                <ModalTitle title='Dodatek č. 01/23 k Vaši Rámcové smlouvě' />
            </div>

            <PDFPreview url={file ?? ''} height={'500px'} />

            <div className='mt-4 w-full'>
                <div
                    className={classNames(
                        'flex items-center w-full gap-x-4 gap-y-4',
                        'mt-6 sm:mt-6 ',
                    )}>
                    <ButtonCommon className='bg-red-600 hover:bg-red-700' onClick={handleClose}>
                        Nesouhlasím
                    </ButtonCommon>
                    <ButtonWithLoader
                        onClick={signAmendmentRS}
                        disabled={disabled}
                        loading={loading}
                        loadingText={'Malý moment...'}>
                        Souhlasím
                    </ButtonWithLoader>
                </div>
            </div>
        </ModalWrapper>
    )
}

export default AmendmentRSModal
