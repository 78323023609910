import { FC } from 'react'
import { ButtonCommon } from '@vistacredit/lib-ui'
import { routerCatalog } from 'routes/hooks/useSecureRouting'
import { useNavigate } from 'react-router'

interface ILoanRequestBanner {
    handleDisableButtonLogic: () => undefined | boolean
}

const LoanRequestBanner: FC<ILoanRequestBanner> = ({ handleDisableButtonLogic }) => {
    const navigate = useNavigate()
    return (
        <div
            className='flex flex-wrap flex-row col-span-12 justify-between items-center sm:max-h-28 sm:col-span-12 lg:col-span-8 xl:col-span-6 bg-white rounded-lg border border-borderGray p-6 sm:not-sr-only sm:p-6'
            style={{}}>
            <p className='flex-grow-1'>Momentálně nemáte žádný aktivní úvěr.</p>
            <div className='flex-auto sm:flex-none mt-2 sx:mt-0 sm:flex-shrink-1'>
                <ButtonCommon
                    disabled={handleDisableButtonLogic()}
                    onClick={() => navigate(routerCatalog.newLoan, {})}>
                    CHCI ÚVĚR
                </ButtonCommon>
            </div>
        </div>
    )
}

export default LoanRequestBanner
