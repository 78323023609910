import { FC, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import { routerCatalog } from 'routes/hooks/useSecureRouting'
import UserAvatar from 'layout/components/navigation/components/UserAvatar'
import { Customer } from 'api/customers/types'
import { PointsListResponse } from 'api/points/types'
import { Logo } from '@vistacredit/lib-ui'

type MobileMenuProps = {
    show: boolean
    onClose: (value: boolean) => void
    onClick: () => void
    customer: Customer
    points: PointsListResponse
}

export const MobileMenu: FC<MobileMenuProps> = ({ show, onClose, onClick, customer, points }) => {
    const navigation = [
        {
            name: 'Úvěr',
            href: routerCatalog.dashboard,
            children: [
                { name: 'Aktívni úvěr', href: routerCatalog.dashboard },
                { name: 'Nový úvěr', href: routerCatalog.newLoan },
                {
                    name: 'Moje úvěry',
                    href: routerCatalog.loans,
                },
            ],
        },
        {
            name: 'Dokumenty',
            href: '',
            children: [
                { name: 'GDPR', href: routerCatalog.gdpr },
                { name: 'Smlouvy', href: routerCatalog.newLoan },
            ],
        },
        { name: 'Bonusy', href: routerCatalog.bonuses, children: [] },
        { name: 'Pomoc', href: routerCatalog.faq, children: [] },
    ]

    const userNavigation = [
        { name: 'Můj účet', href: routerCatalog.profile, children: [] },
        { name: 'Nastavení', href: routerCatalog.settings, children: [] },
        { name: 'Odhlásit se', href: routerCatalog.logout, children: [] },
    ]

    return (
        <Transition.Root show={show} as={Fragment}>
            <Dialog as='div' className='relative z-40 md:hidden' onClose={onClose}>
                <div className='fixed inset-0 z-40'>
                    <Transition.Child
                        as={Fragment}
                        enter='transition ease-out duration-150 sm:ease-in-out sm:duration-300'
                        enterFrom='transform opacity-0 scale-110 sm:translate-x-full sm:scale-100 sm:opacity-100'
                        enterTo='transform opacity-100 scale-100  sm:translate-x-0 sm:scale-100 sm:opacity-100'
                        leave='transition ease-in duration-150 sm:ease-in-out sm:duration-300'
                        leaveFrom='transform opacity-100 scale-100 sm:translate-x-0 sm:scale-100 sm:opacity-100'
                        leaveTo='transform opacity-0 scale-110  sm:translate-x-full sm:scale-100 sm:opacity-100'>
                        <Dialog.Panel
                            className='fixed inset-0 z-40 h-full w-full bg-white sm:inset-y-0 sm:left-auto sm:right-0 sm:w-full sm:max-w-sm sm:shadow-lg'
                            aria-label='Global'>
                            <div className='flex h-16 items-center justify-between px-4 sm:px-6'>
                                <div className='-ml-4'>
                                    <Logo />
                                </div>
                                <button
                                    type='button'
                                    className='-mr-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600'
                                    onClick={onClick}>
                                    <span className='sr-only'>Close main menu</span>
                                    <XMarkIcon className='block h-6 w-6' aria-hidden='true' />
                                </button>
                            </div>

                            <div className='max-w-8xl mx-auto py-3 px-2 sm:px-4'>
                                {navigation.map((item) => (
                                    <Fragment key={item.name}>
                                        <Link
                                            to={item.href}
                                            onClick={onClick}
                                            className='block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-100'>
                                            {item.name}
                                        </Link>
                                        {item.children.map((child) => (
                                            <Link
                                                key={child.name}
                                                to={child.href}
                                                onClick={onClick}
                                                className='block rounded-md py-2 pl-5 pr-3 text-base font-medium text-gray-500 hover:bg-gray-100'>
                                                {child.name}
                                            </Link>
                                        ))}
                                    </Fragment>
                                ))}
                            </div>
                            <div className='border-t border-gray-200 pt-4 pb-3'>
                                <div className='px-4'>
                                    <UserAvatar customer={customer} points={points} />
                                </div>
                                <div className='max-w-8xl mx-auto mt-3 space-y-1 px-2 sm:px-4'>
                                    {userNavigation.map((item) => (
                                        <Link
                                            key={item.name}
                                            to={item.href}
                                            onClick={onClick}
                                            className='block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-50'>
                                            {item.name}
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
