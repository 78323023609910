import React, { useEffect } from 'react'
import { PageWrapLayout } from 'layout/components'
import ProfileHeader from 'pages/profile/components/ProfileHeader'
import ProfilePersonalInfoSection from 'pages/profile/components/ProfilePersonalInfoSection'
import ProfileAddress from 'pages/profile/components/ProfileAddress'
import ProfileFinance from 'pages/profile/components/ProfileFinance'
import ProfilePersonalDocuments from 'pages/profile/components/ProfilePersonalDocuments'
import { EContractsType } from 'api/contracts/types'
import ProfileSkeleton from 'pages/profile/components/ProfileSkeleton'
import {
    useGetCustomerIdExpirationQuery,
    useGetCustomerPapersQuery,
    useGetCustomerQuery,
} from 'api/services/customer/serviceCustomer'
import { useGetContractsListQuery } from 'api/services/customer/serviceContracts'

const Profile = () => {
    const customerQuery = useGetCustomerQuery()
    const customerPapersQuery = useGetCustomerPapersQuery()
    const contractsListQuery = useGetContractsListQuery(EContractsType.Personal)
    const customerIdExpirationQuery = useGetCustomerIdExpirationQuery()

    useEffect(() => {
        (async () => {
            await customerPapersQuery.refetch()
            await customerQuery.refetch()
        })()
    }, [])

    if (!customerPapersQuery?.data || !customerQuery?.data) {
        return <ProfileSkeleton />
    }

    return (
        <PageWrapLayout>
            <main className='py-4'>
                {/* Page header */}
                <ProfileHeader customer={customerQuery?.data} />

                <div className=' mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-0 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3'>
                    <ProfilePersonalInfoSection
                        customer={customerQuery?.data}
                        customerPapers={customerPapersQuery?.data}
                        expiration={customerIdExpirationQuery?.data}
                    />

                    {/* Left column */}

                    <section
                        aria-labelledby='timeline-title'
                        className='lg:col-span-1 lg:col-start-3'>
                        <ProfileAddress customer={customerQuery?.data} />
                        <ProfileFinance customer={customerQuery?.data} />
                        <ProfilePersonalDocuments contracts={contractsListQuery?.data} />
                    </section>
                </div>
            </main>
        </PageWrapLayout>
    )
}

export default Profile
