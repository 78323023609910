import React, { FC } from 'react'
import { NavLinks, UserMenu } from 'layout/components/navigation/components'
import { Customer } from 'api/customers/types'
import { PointsListResponse } from 'api/points/types'

interface INavProps {
    customer: Customer
    points: PointsListResponse
}

export const Nav: FC<INavProps> = ({ customer, points }) => {
    return (
        <div className='hidden md:flex md:min-w-0 md:flex-1 md:items-center md:justify-end'>
            <div className='ml-10 flex font-light flex-shrink-0 items-center space-x-10 pr-4'>
                <NavLinks />
                <UserMenu customer={customer} points={points} />
            </div>
        </div>
    )
}
