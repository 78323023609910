import React, { FC } from 'react'
import Question6_1 from 'assets/images/question6_1.png'
import { Link } from 'react-router-dom'

const QuestionSix: FC = () => {
    return (
        <div className='space-y-4'>
            <p>Naše klienty rozdělujeme do 3 kategorií.</p>
            <p>
                <b>Kategorie Vista START</b> - je to startovací kategorie pro nového klienta. Zde si
                můžete získat naši důvěru a pokud v této kategorii splatíte více jak dva úvěry, může
                Vás administrátor přesunout do vyšší kategorie. <br /> V této kategorii nabízíme
                <b>maximální výši rámce do 4 000 Kč</b> a je možné také využít službu Odložení
                splatnosti, která je detailně popsána{' '}
                <Link
                    className={'hover:text-lightBlue hover:underline'}
                    to='/pomoc/jak-a-kde-zmenim-dobu-splatnosti-meho-aktivniho-uveru'>
                    <b>ZDE</b>
                </Link>
                .
            </p>
            <p>
                <b>Kategorie Vista PLUS</b> - tato kategorie již nabízí{' '}
                <b>úvěrový rámec do 6 000 Kč</b>. Pokud ovšem splatíte úvěr v této kategorii více
                jak 10 dnů po splatnosti, náš systém Vás automaticky přesune do nižší kategorie.{' '}
                <br /> I v této kategorii je možné využít službu Odložení splatnosti, která je
                detailně popsána{' '}
                <Link
                    className={'hover:text-lightBlue hover:underline'}
                    to='/pomoc/jak-a-kde-zmenim-dobu-splatnosti-meho-aktivniho-uveru'>
                    <b>ZDE</b>
                </Link>
                .
            </p>
            <p>
                <b>Algoritmus našeho systému pečlivě sleduje Vaši platební morálku</b> a tím, že Vám
                kategorii změníme na nižší se snažíme Vás zbytečně nezadlužovat. Pokud ovšem všechno
                klape, systém Vás naopak přesune <b>automaticky do vyšší kategorie</b>.
            </p>

            <div>
                <p className='mb-2'>
                    <b>Kategorie Vista Business</b> - zatím naše nejvyšší třída.{' '}
                    <b>Úvěrový rámec budete mít nastavený na 8 000 Kč</b>, ale to není vše. Budete
                    moci čerpat maximum dalších výhod, jako například:
                </p>
                <ul className=' space-y-3 pl-10 list-disc '>
                    <li>
                        <b>můžete aktivovat službu VistaRENT</b> (bonusová služba) - pronájem
                        financí (služba je detailně popsána{' '}
                        <Link
                            className={'hover:text-lightBlue hover:underline'}
                            to='/pomoc/co-je-sluzba-rent'>
                            <b>ZDE</b>
                        </Link>
                        .
                    </li>
                    <li>
                        doposud získané body můžete proměnit v delší splatnost a mnoho dalších výhod
                        (stav získaných bodů a jejich hodnotu zjistíte při kliknutí na tlačítko v
                        levém menu <b>Bonusy</b>.
                    </li>
                </ul>
            </div>

            <img src={Question6_1} className='w-fit lg:max-w-xl my-3' alt='question3 image' />

            <p>
                Hodnotu získaných bodů je možné uplatnit na služby Visty, např.{' '}
                <b>využít službu Odložení splatnosti</b>, která je detailně popsána{' '}
                <Link
                    className={'hover:text-lightBlue hover:underline'}
                    to='/pomoc/jak-a-kde-zmenim-dobu-splatnosti-meho-aktivniho-uveru'>
                    <b>ZDE</b>
                </Link>
                .
            </p>
            <p>
                Kompletní přehled jsme přehledně popsali na samostatné prezentaci{' '}
                <Link
                    className={'hover:text-lightBlue hover:underline'}
                    to='/pomoc/mam-schvalenou-zadost-jak-a-kde-mam-podepsat-smlouvy'>
                    <b>ZDE</b>
                </Link>
                .
            </p>
            <p>
                Můžete nám také poslat dotaz přes <b>Kontaktní formulář</b> výběrem volby :{' '}
                <b>Vlastní dotaz, námět, prosba, pochvala, kritika</b>.
            </p>

            {/*<img src={Question1_2} className='max-w-xl my-3' alt='question3 image' />*/}
        </div>
    )
}

export default QuestionSix
