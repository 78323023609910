import { FC, ReactNode } from 'react'

type TBadgeProps = {
    type?: string
    text: string | ReactNode
    fontStyle?: 'uppercase' | 'normal' | 'capitalize'
}

export const BadgePapers: FC<TBadgeProps> = ({ type, text, fontStyle = 'normal' }) => {
    const applyClassesByType = (): string => {
        switch (type) {
            case 'status_green':
                // return 'bg-status-green text-white'
                return 'bg-status-greenLight text-textGreen'
            case 'status-greenLight':
                return 'bg-status-greenLight text-textGreen'
            case 'status_red':
                return 'bg-status-red text-white'
            case 'status-redLight':
                return 'bg-status-redLight text-status-red'
            case 'status_orange':
                return 'bg-status-orange text-white'
            case 'status_blue':
                return 'bg-status-blue text-white'
            case 'status_gray':
                return 'bg-status-gray text-white'
            case 'status_orangeLight':
                return 'bg-status-orangeLight text-status-orangeTextLight'

            default:
                return 'bg-status-blue'
        }
    }

    return (
        <span
            className={`inline-block text-xs font-light ${fontStyle} px-4 py-2 rounded
                ${applyClassesByType()}`}>
            {text}
        </span>
    )
}
