export enum UserCategory {
    Start = 0,
    Plus = 1,
    Business = 2,
}

export enum UserCategoryString {
    Start = 'Start',
    Plus = 'Plus',
    Business = 'Business',
}

export const customerCategory = (customerCategory: number): string => {
    switch (customerCategory) {
        case UserCategory.Start:
            return UserCategoryString.Start
        case UserCategory.Plus:
            return UserCategoryString.Plus
        case UserCategory.Business:
            return UserCategoryString.Business
        default:
            return ''
    }
}
