import { FC } from 'react'
import LimitedPattern from 'assets/pattern/limitedPatter.svg'
import { MinusCircleIcon } from '@heroicons/react/24/outline'
import { ButtonCommon } from '@vistacredit/lib-ui'
import { routerCatalog } from 'routes/hooks/useSecureRouting'
import { useNavigate } from 'react-router'

const UploadPapersBanner: FC = () => {
    const navigate = useNavigate()
    return (
        <div className='flex flex-wrap overflow-hidden sm:flex-nowrap relative justify-between items-center sm:max-h-28 sm:col-span-12 lg:col-span-8 xl:col-span-6 rounded-lg border border-borderGray p-6 sm:p-6 bg-gradient-to-r from-lightBlue to-darkPurple mt-4'>
            <img src={LimitedPattern} className='absolute left-0 bottom-0 z-0' alt='' />
            <div className='flex flex-wrap flex-auto flex-col justify-center items-center sm:justify-between sm:flex-nowrap sm:flex-row relative z-10'>
                <div className='flex flex-col justify-center items-center sm:justify-start sm:flex-row'>
                    <MinusCircleIcon className='h-10 w-10 text-white relative mb-2 sm:mb-0 sm:mr-2' />
                    <p className='text-center sm:text-left text-white font-light break-all mb-2 sm:mb-0'>
                        Prosím vložte požadované dokumenty! <br className='not-sr-only' /> Potom
                        můžeme posoudit Vaši žádost!
                    </p>
                </div>
                <div className='flex-auto mt-2 sx:mt-0 flex-grow-1 sm:flex-grow-0 sm:flex-shrink-1'>
                    <ButtonCommon
                        className='bg-lightPurple hover:bg-transparent hover:border-white'
                        onClick={() => navigate(routerCatalog.profile)}>
                        Vložit dokumenty
                    </ButtonCommon>
                </div>
            </div>
        </div>
    )
}

export default UploadPapersBanner
