import { customerCategory } from 'utils/customerCategory'
import { sumPoints } from 'utils/points'
import { Customer } from 'api/customers/types'
import { FC } from 'react'
import { PointsListResponse } from 'api/points/types'
import { getCustomerInitials } from 'utils'
import { Avatar } from 'pages/profile/components/Avatar'

interface IUserAvatarProps {
    customer: Customer
    points: PointsListResponse
}

const UserAvatar: FC<IUserAvatarProps> = ({ customer, points }) => {
    return (
        <div className='flex'>
            <div className='flex-none w-14'>
                <Avatar initials={getCustomerInitials(customer)} customer={customer} />
            </div>
            <div className='flex-initial w-38 text-left'>
                <p>{`${customer?.first_name} ${customer?.last_name}`}</p>
                <p>{`Vista ${customerCategory(customer?.category)} - ${sumPoints(points)} bodů`}</p>
            </div>
        </div>
    )
}

export default UserAvatar
