import { serviceApi } from 'api/serviceApi'
import {
    ILoginRequest,
    ILoginResponse,
    IPasswordChange,
    IPasswordReset,
} from 'api/services/auth/types'

const authApi = serviceApi.injectEndpoints({
    endpoints: (build) => ({
        login: build.mutation<ILoginResponse, ILoginRequest>({
            query: (payload) => {
                return {
                    url: 'auth/login/',
                    method: 'POST',
                    body: payload,
                }
            },
        }),
        logout: build.mutation({
            query: () => {
                return {
                    url: 'auth/logout/',
                    method: 'POST',
                }
            },
        }),
        resetPassword: build.mutation<void, IPasswordReset>({
            query: (payload) => {
                return {
                    url: 'auth/password-reset/',
                    method: 'POST',
                    body: payload,
                }
            },
        }),
        changePassword: build.mutation<void, IPasswordChange>({
            query: (payload) => {
                return {
                    url: 'auth/password-change/',
                    method: 'POST',
                    body: payload,
                }
            },
        }),
    }),
    overrideExisting: false,
})

export const {
    useLoginMutation,
    useLogoutMutation,
    useResetPasswordMutation,
    useChangePasswordMutation,
} = authApi
