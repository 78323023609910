import React, { FC } from 'react'
import { formatCurrency } from 'utils'
import Table from 'pages/loans/components/Table'
import TableHeader from 'pages/loans/components/TableHeader'
import { TLoanDetail } from 'api/services/loan/types'

interface ITopayTabProps {
    loan?: TLoanDetail
    cells: string[]
}

const TopayTab: FC<ITopayTabProps> = ({ loan, cells }) => {
    if (!loan) return null

    return (
        <div className='mb-8'>
            <h1 className='text-lg uppercase text-center text-gray-600 font-medium font-Quicksand text-gray-900 mb-4'>
                {/*Položky, které ještě musím splatit */}
                Celkem ke splacení:{' '}
                <span className='capitalize text-lightPurple'>
                    {formatCurrency(
                        loan.to_pay[0].reduce((partialSum, item) => partialSum + item.to_pay, 0),
                        'CZK',
                    )}
                </span>
            </h1>
            <Table noStyle>
                <TableHeader cells={cells} className={'border-none rounded-none shadow-none'} />
                <tbody className='divide-y divide-gray-200 bg-white'>
                    {loan.to_pay[0].map((item) => (
                        <tr key={item.item}>
                            <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                                {loan.contract_number}
                            </td>
                            <td className='whitespace-nowrap flex justify-center px-3 py-4 text-sm text-center text-gray-500'>
                                {item.item}
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-right text-gray-500'>
                                {formatCurrency(item.to_pay, 'CZK')}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

export default TopayTab
