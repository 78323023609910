import React, { FC } from 'react'
import Question10_1 from 'assets/images/question10_1.png'

const QuestionTen: FC = () => {
    return (
        <div className='space-y-4'>
            <p>
                Ihned po schválení Vaší žádosti o úvěr budou vygenerovány Smlouvy, abychom
                nezdržovali proces odeslání financí.
            </p>
            <p>
                <b>Nový klient</b> vždy podepisuje:
            </p>

            <ul className=' space-y-3 pl-10 list-disc '>
                <li>Rámcová smlouva (pouze při první žádosti o úvěr)</li>
                <li>Dohoda o řešení sporů (pouze při první žádosti o úvěr)</li>
                <li>Smlouva o úvěru - vždy s novou žádostí</li>
            </ul>

            <p>
                <b>Stálý klient</b> u opakované žádosti podepisuje:
            </p>
            <ul className=' space-y-3 pl-10 list-disc '>
                <li>Smlouva o úvěru</li>
            </ul>
            <p>
                <b>K podpisu všech smluv Vás online pošleme do systému našeho partnera</b>, spol.
                signi.com, kde proběhne elektronický podpis tak, aby smlouvy byly platně podepsané
                dle eIDAS EU..
            </p>
            <p>
                <b>Pokud nechcete podepsat smlouvy ihned</b>, ale dáte si čas na jejich
                prostudování, můžete a dokonce to doporučujeme.
            </p>
            <p>
                Pro tuto situaci budete mít v klientském účtu umístěný tento banner, který Vás
                kdykoliv k podpisu smluv přesměruje.
            </p>
            <img src={Question10_1} className='w-fit lg:max-w-xl my-3' alt='question3 image' />
            <p>
                Můžete nám také poslat dotaz přes <b>Kontaktní formulář</b> výběrem volby :{' '}
                <b>Vlastní dotaz, námět, prosba, pochvala, kritika</b>.
            </p>

            {/*<img src={Question1_2} className='max-w-xl my-3' alt='question3 image' />*/}
        </div>
    )
}

export default QuestionTen
