import { FC, useState } from 'react'
import { MenuButton, MobileMenu } from 'layout/components/navigation/components'
import { HomeIcon, PlusCircleIcon } from '@heroicons/react/24/outline'
import NavItem from 'layout/components/mobileNav/components/NavItem'
import { Customer } from 'api/customers/types'
import { PointsListResponse } from 'api/points/types'
import { routerCatalog } from 'routes/hooks/useSecureRouting'

interface IMobileNavProps {
    customer: Customer
    points: PointsListResponse
}

const MobileNav: FC<IMobileNavProps> = ({ customer, points }) => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    const navItems = [
        {
            Icon: HomeIcon,
            title: 'Domů',
            href: routerCatalog.dashboard,
        },
        {
            Icon: PlusCircleIcon,
            title: 'Nový úvěr',
            href: routerCatalog.newLoan,
        },
    ]

    return (
        <div
            className='bg-white mb-0 shadow-2xl shadow-top rounded-tl-xl rounded-tr-lg border-t border-borderGray relative flex h-24 flex-shrink-0 items-center md:sr-only '
            style={{ boxShadow: '2px 2px 6px 4px rgb(0,0,0, .1)' }}>
            <div className='px-6 flex justify-between flex-1'>
                {navItems.map((item, index) => (
                    <NavItem key={index} Icon={item.Icon} title={item.title} href={item.href} />
                ))}
                <MenuButton onClick={() => setMobileMenuOpen(true)} />
            </div>

            <MobileMenu
                onClose={setMobileMenuOpen}
                show={mobileMenuOpen}
                onClick={() => setMobileMenuOpen(false)}
                customer={customer}
                points={points}
            />
        </div>
    )
}

export default MobileNav
