import { FC } from 'react'
import QRCode from 'react-qr-code'

// code example: "SPD*1.0ACC:CZ2803000000000276713618*AM:1220*CC:CZK*RF:123456789*X-VS:2200621*X-SS:*X-KS:*PT:IP*MSG:Testovaci zprava"
export interface SEPAPaymentData {
    ACC: string //iban
    AM: string //amount
    CC: string //currency
    RF: string //reference
    'X-VS': string //variable symbol
    'X-SS'?: string //specific symblo
    'X-KS'?: string //konstant symbol
    PT?: string //instant payment => value IP for instant
    MSG?: string //message
}

interface IQRcodeProps {
    paymentData: SEPAPaymentData
}

const QRcode: FC<IQRcodeProps> = ({ paymentData }) => {
    const generateSepaQrCode = () => {
        const prefix = 'SPD*1.0*'

        return (
            prefix +
            Object.keys(paymentData)
                .map((item) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    return item + ':' + paymentData[item]
                })
                .join('*')
        )
    }

    const payment = generateSepaQrCode()

    return (
        <div className='QRcode'>
            <h3 className='text-center mb-3'>QR Platba</h3>
            <QRCode className={'qr d-block mx-auto'} value={payment} />
            <div className='text-gray text-center mt-4'>
                {/*<p className={'mb-0'}>číslo účtu</p>*/}
                <p className={'mb-0'}>
                    č.ú. <b>21 00 80 62 38/2010</b>
                </p>
                <div className={'d-flex justify-content-center mt-2'}>
                    <p className={'mb-0 mr-3'}>
                        Částka:{' '}
                        <span className='font-normal text-lightPurple'>{paymentData.AM} Kč</span>
                    </p>
                    <p className={'mb-0 mt-2'}>
                        VS: <b>{paymentData['X-VS']}</b>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default QRcode
