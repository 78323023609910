import { FC } from 'react'
import { ArrowPathIcon, CheckBadgeIcon, MinusCircleIcon } from '@heroicons/react/24/solid'
import { Customer } from 'api/customers/types'
import { getIdentityStatus } from 'utils/getIdentityStatus'

type TAvatarProps = {
    initials: string
    size?: 'small' | 'medium'
    customer: Customer
}

export const Avatar: FC<TAvatarProps> = ({ initials, size = 'small', customer }) => {
    return (
        <div
            className={`inline-flex relative justify-center items-center bg-gradient-to-r from-lightPurple to-darkPurple rounded-full ${
                size === 'small' && 'w-9 h-9'
            } ${size === 'medium' && 'w-16 h-16'}`}>
            <span className='font-medium text-white dark:text-white-300'>{initials}</span>

            {getIdentityStatus(customer).status === 'pending' && (
                <ArrowPathIcon
                    className={`absolute p-0.5 bottom-0 right-0 block ${
                        size === 'small' && 'w-4 h-4'
                    } ${
                        size === 'medium' && 'w-6 h-6'
                    } rounded-full text-white bg-status-orangeLight fill-status-orangeTextLight ring-2 ring-status-orangeTextLight`}
                    aria-hidden='true'
                />
            )}

            {getIdentityStatus(customer).status === 'error' && (
                <MinusCircleIcon
                    className={`absolute p-0.5 bottom-0 right-0 block ${
                        size === 'small' && 'w-4 h-4'
                    } ${
                        size === 'medium' && 'w-6 h-6'
                    } rounded-full text-white bg-white fill-red-500 ring-2 ring-red-500`}
                    aria-hidden='true'
                />
            )}

            {getIdentityStatus(customer).status === 'success' && (
                <CheckBadgeIcon
                    className={`absolute bottom-0 right-0 block ${size === 'small' && 'w-4 h-4'} ${
                        size === 'medium' && 'w-6 h-6'
                    } rounded-full text-white bg-white fill-green-500 ring-2 ring-green-500`}
                    aria-hidden='true'
                />
            )}
        </div>
    )
}
