import React, { FC } from 'react'
import Question5_1 from 'assets/images/question5_1.png'
import { Link } from 'react-router-dom'

const QuestionFive: FC = () => {
    return (
        <div className='space-y-4'>
            <p>
                Nejedná se u nás o žádnou novinku i když je{' '}
                <b>VistaCredit.cz jediná v ČR, kdo tuto službu nabízí</b>. Nechceme se chlubit, ale
                snažíme se, aby čerpané finance byly pro naše klienty přínosem, a proto Vám
                vysvětlíme v čem tato <b>nadstandardní služba</b> spočívá.
            </p>
            <p>
                Aby jste tuto službu mohli aktivovat, <b>musíte být v kategorii VistaBusiness</b>.
                Aktivaci provedete kliknutím na tento banner, který bude zobrazen v klientském účtu,
                <b>pokud na službu máte nárok</b>.
            </p>

            <img src={Question5_1} className='w-fit lg:max-w-xl my-3' alt='question3 image' />
            <p>* cena aktivace této služby je 1 000 Kč a je platná 1 rok.</p>

            <div>
                <h2 className='mb-2 text-base font-semibold'>Co získáte?</h2>
                <ul className=' space-y-3 pl-10 list-disc '>
                    <li>
                        budete moci opakovaně čerpat jakoukoliv výši úvěru na 14 dnů zdarma !
                        Nebudeme Vám účtován žádný poplatek za poskytnutí.
                    </li>
                    <li>
                        Při splacení Vás požádáme o drobný manipulační poplatek ve výši 150 Kč.
                        (poplatek je rovný výši nákladů třetích stran ve spojitosti s uhrazením
                        úvěru, bankovní služby apd.)
                    </li>
                    <li>
                        Aktivaci služby je možné po roce opět prodloužit.{' '}
                        <b>I tato služba podporuje službu Odložení splatnosti</b>, která je detailně
                        popsána{' '}
                        <Link to={'/pomoc/jak-a-kde-zmenim-dobu-splatnosti-meho-aktivniho-uveru'}>
                            {' '}
                            <b>ZDE</b>
                        </Link>
                        .
                    </li>
                    <li>
                        Splatnost RENT úvěrů si tak můžete po 14 dnech splatnosti prodloužit třeba o
                        dalších 35 dnů (služba prodloužení splatnosti je zpoplatněna dle ceníku
                        uvedeného na kalkulačce při výběru této služby)
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default QuestionFive
