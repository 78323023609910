import useCalculation from 'hooks/useCalculation'
import { PriceList } from 'api/priceLists/types'
import { FC } from 'react'
import { formatCurrency } from 'utils'
import { getPaymentDate } from 'utils/getPaymentDate'

interface ILoanCalcTableProps {
    rangePrice: number | number[]
    rangeDays: number | number[]
    priceList?: PriceList[]
    preSet: number
}

const LoanCalcTable: FC<ILoanCalcTableProps> = ({ rangeDays, rangePrice, priceList, preSet }) => {
    if (!priceList) {
        return null
    }

    const { calc_rmpsn, resetPrices, amount, loanPrices, deferralPrice, totalPrice } =
        useCalculation(rangePrice, rangeDays, priceList, preSet)

    return (
        <div className='p-6 sm:p-10 text-lightGray font-light shadow-lg rounded-lg'>
            <div className='flex justify-between border-b border-borderGray py-4'>
                <h2 className='text-xl font-normal font-Quicksand text-lightBlue '>
                    Zvolili jste variantu:
                </h2>
            </div>
            <div className='flex justify-between border-b border-borderGray py-4'>
                <span>Výše úvěru:</span>
                <span>{formatCurrency(+rangePrice, 'CZK')}</span>
            </div>
            <div className='flex justify-between border-b border-borderGray py-4'>
                <span>Doba splatnosti:</span>
                <span>{rangeDays} dní</span>
            </div>
            <div className='flex flex-col border-b border-borderGray gap-y-2 py-4'>
                {preSet !== 14 && (
                    <div className='flex justify-between'>
                        <span>Poplatek za poskytnutí úvěru:</span>
                        <span>
                            {loanPrices + deferralPrice === 0
                                ? '0 Kč'
                                : formatCurrency(loanPrices + deferralPrice, 'CZK')}
                        </span>
                    </div>
                )}
                {preSet === 14 && (
                    <>
                        <div className='flex justify-between'>
                            <span>Cena za využívání financí:</span>
                            <span>
                                {loanPrices + deferralPrice === 0
                                    ? '0 Kč'
                                    : formatCurrency(loanPrices + deferralPrice, 'CZK')}
                            </span>
                        </div>
                        <div className='flex justify-between text-sm'>
                            <span>Poplatek za poskytnutí úvěru:</span>
                            <span>0 Kč</span>
                        </div>
                    </>
                )}
                {preSet === 14 && (
                    <div className='flex justify-between text-sm'>
                        <span>Náklad na splacení úvěru:</span>
                        <span>{formatCurrency(150, 'CZK')}</span>
                    </div>
                )}
            </div>
            <div className='flex justify-between border-b border-borderGray py-4 font-normal'>
                <span>
                    Celkem zaplatíte: <br />
                    <span className='font-light text-sm'>
                        za {rangeDays} dní (do {getPaymentDate(+rangeDays)})
                    </span>
                </span>
                <span>{formatCurrency(totalPrice, 'CZK')}</span>
            </div>
            <div className='text-sm flex justify-between border-b border-borderGray py-4'>
                <span>rpsn: (roční úroková sazba):</span>
                <span>{calc_rmpsn('rpsn')}%</span>
            </div>
            <div className='text-sm flex justify-between border-b border-borderGray py-4'>
                <span>mpsn: (měsíční úroková sazba):</span>
                <span>{calc_rmpsn('mpsn')}%</span>
            </div>
        </div>
    )
}

export default LoanCalcTable
