import { store } from 'store/store'
import { CreateAxiosInstance } from './api'

export const ajax = CreateAxiosInstance(store)
export { usePreRenderLoading } from './usePreRenderLoading'
export { useApiCall } from './useApiCall'

export { useAuthApi } from './auth/useAuthApi'
export { useContractsApi } from './contracts/useContractsApi'
export { useCustomersApi } from './customers/useCustomersApi'
