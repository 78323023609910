import { FC, useState } from 'react'
import { ButtonCommon, ButtonWithLoader } from '@vistacredit/lib-ui'
import { classNames } from 'utils'

interface IPaymentModalFooterProps {
    onClose: () => void
    onClick: () => void
    disabled?: boolean
    title: string
    loading?: boolean
    loadingText?: string
    showCancelBtn?: boolean
}

const ModalFooter: FC<IPaymentModalFooterProps> = ({
    onClick,
    title,
    onClose,
    disabled,
    loading,
    loadingText,
    showCancelBtn,
}) => {
    const [loadingBtn, setLoadingBtn] = useState(false)

    const handleOnClick = async () => {
        try {
            loading && setLoadingBtn(true)
            await onClick()
        } catch (err) {
            console.error(err)
        }
        setLoadingBtn(false)
    }

    return (
        <div
            className={classNames(
                !showCancelBtn
                    ? 'grid content-center items-center gap-y-2 sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3'
                    : 'flex items-center w-full',
                'mt-6 sm:mt-6 ',
            )}>
            {!showCancelBtn && (
                <ButtonCommon className='bg-red-600 hover:bg-red-700' onClick={onClose}>
                    Zrušit
                </ButtonCommon>
            )}
            <ButtonWithLoader
                onClick={handleOnClick}
                disabled={disabled}
                loading={loadingBtn}
                loadingText={loadingText}>
                {title}
            </ButtonWithLoader>
        </div>
    )
}

export default ModalFooter
