import { FC } from 'react'
import { ButtonPurple, ButtonWhite } from '@vistacredit/lib-ui'
import { formatCurrency } from 'utils'
import { getDate } from 'utils/date'
import DashedArrow from './assets/bluArrowDashed.svg'
import { IDeferralOrder } from 'api/services/loan/types'

interface IDeferralCardProps {
    deferral?: IDeferralOrder
    stornoDeferral: () => void
    payDeferral: () => void
}

const DeferralCard: FC<IDeferralCardProps> = ({ deferral, stornoDeferral, payDeferral }) => {
    return (
        <>
            <img src={DashedArrow} className='absolute left-2 -top-4 z-0 ' alt='' />
            <div className='sr-only sm:col-start-2 sm:col-end-11 lg:col-end-9 xl:col-end-6  bg-white rounded-lg border border-borderGray p-6 sm:not-sr-only sm:p-6'>
                <div className=''>
                    <div className='flex justify-between items-center'>
                        <h1 className='text-xl font-medium font-Quicksand text-gray-900'>
                            Odložení splatnosti úvěru
                        </h1>
                        <div className={'ml-4'}>
                            <p className='truncate text-right text-sm font-light text-gray-500 mb-1'>
                                {`ID: ${deferral?.order_id}`}
                            </p>
                        </div>
                    </div>

                    <div className='flex flex-wrap justify-between sm:items-center mt-4'>
                        <div className='sm:flex-1 w-25'>
                            <p className='truncate text-sm font-light text-gray-500 mb-1'>
                                Délka odložení
                            </p>
                            <p className='text-base mb-0 font-normal text-gray-900'>{`${deferral?.days} dní`}</p>
                        </div>
                        <div className='sm:flex-1 w-25'>
                            <p className='truncate text-sm font-light text-gray-500 mb-1'>
                                Cena za odložení
                            </p>
                            <p className='text-base mb-0 font-normal text-gray-900'>{`${formatCurrency(
                                deferral?.deferral_amount,
                                'CZK',
                            )}`}</p>
                        </div>
                        <div className='sm:flex-1 text-right  w-25'>
                            <p className='truncate text-sm font-light text-gray-500 mb-1'>Celkem</p>
                            <p className='text-base mb-0 font-normal text-gray-900'>{`${formatCurrency(
                                deferral?.deferral_amount,
                                'CZK',
                            )}`}</p>
                        </div>
                    </div>

                    <div className='flex flex-wrap justify-between sm:items-center mt-4'>
                        <div className='sm:flex-1 w-25'>
                            <p className='truncate text-sm font-light text-gray-500 mb-1'>
                                Datum žádosti o úvěr
                            </p>
                            <p className='text-base mb-0 font-normal text-gray-900'>{`${getDate(
                                deferral?.created,
                            )}`}</p>
                        </div>

                        <div className='sm:flex-1 w-25 mt-2 sm:mt-0'>
                            <p className='truncate text-sm font-light text-gray-500 mb-1'>
                                Splatnost úvěru po odložení
                            </p>
                            <p className='text-base mb-0 font-normal text-gray-900'>{`${getDate(
                                deferral?.new_due_date,
                            )}`}</p>
                        </div>
                        <div className='sm:flex-1 w-25 mt-2 sm:mt-0'>
                            <p className='truncate text-sm text-right font-light text-gray-500 mb-1'>
                                Poplatek uhraďte do
                            </p>
                            <p className='text-base mb-0 sm:text-right font-normal text-gray-900'>{`${getDate(
                                deferral?.pay_due_date,
                            )}`}</p>
                        </div>
                    </div>

                    <div className='flex flex-1 flex-col gap-y-4 justify-center mt-6'>
                        <ButtonWhite
                            className='bg-white border border-lightBlue text-lightBlue hover:bg-lightBlue hover:text-white'
                            onClick={stornoDeferral}>
                            Stornovat žádost o odložení
                        </ButtonWhite>
                        <ButtonPurple onClick={payDeferral}>
                            Zaplatit poplatek a odložit splatnost
                        </ButtonPurple>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeferralCard
