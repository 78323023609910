import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useAppSelector } from 'store/store'
import { useGetActiveLoanQuery } from 'api/services/loan/serviceLoan'
import { useDispatch } from 'react-redux'
import { setNotification } from 'store/stateReducer'

export const routerCatalog = {
    root: '',
    loading: '/loading',
    login: '/prihlaseni',
    dashboard: '/aktivni-uver',
    dashboardRent: '/aktivni-uver/rent',
    newLoan: '/novy-uver',
    profile: '/profil',
    settings: '/nastaveni',
    loans: '/moje-uvery',
    bonuses: '/bonusy',
    contact: '/kontakt',
    gdpr: '/gdpr',
    faq: '/pomoc',
    faqDetai: '/pomoc/:id',
    pricing: 'programy',
    documents: '/dokumenty',
    payment: '/platba/:paymentType',
    logout: '/odhlaseni',
    forgotPassw: '/obnova-hesla',
    err404: '/chyba/404',
    err500: '/chyba/500',
}

export const useSecureRouting = () => {
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const token = useAppSelector((state) => state.app.token)
    const activeLoanQuery = useGetActiveLoanQuery()

    useEffect(() => {
        if (token && pathname === routerCatalog.loading) {
            navigate(routerCatalog.root, { replace: true })
        }

        if (token && pathname === routerCatalog.login) {
            navigate(routerCatalog.root, { replace: true })
        }

        if (activeLoanQuery?.data && pathname === routerCatalog.newLoan) {
            navigate(routerCatalog.dashboard, { replace: true })
            dispatch(
                setNotification({
                    type: 'error',
                    title: 'Chyba!',
                    message: 'Už máte aktívni úvěr. O nový si můžete zažádat až po splacení.',
                }),
            )
        }

        // if (
        //     !token &&
        //     (pathname !== routerCatalog.login ||
        //         pathname !== routerCatalog.loading ||
        //         pathname !== routerCatalog.logout)
        // ) {
        //     navigate(routerCatalog.login, { replace: true })
        // }
    }, [token, pathname])
}
