import { ajax } from 'api/index'
import {
    Customer,
    CustomerExpirationResponse,
    CustomerPapers,
    CustomerPatchRequest,
} from 'api/customers/types'

interface ICustomersApi {
    customer: () => Promise<Customer>
    customerPatch: (id: number, data: CustomerPatchRequest) => Promise<Customer>
    customerPatchMe: (data: any) => Promise<Customer>
    customerPut: (id: number, data: CustomerPatchRequest) => Promise<Customer>
    customerPapers: () => Promise<CustomerPapers[]>
    customerPapersPost: (data: unknown) => Promise<unknown>
    customerIdExpiration: () => Promise<CustomerExpirationResponse>
    sendEmail: (data: unknown) => Promise<unknown>
}

export const useCustomersApi = (): ICustomersApi => {
    const customer = async (): Promise<Customer> => {
        try {
            const customerRes = await ajax.get('customers/me/')
            return Promise.resolve(customerRes.data)
        } catch (error) {
            return Promise.reject(error)
        }
    }
    const customerPatchMe = async (data: any): Promise<Customer> => {
        try {
            const customerRes = await ajax.patch('customers/me/', data)
            return Promise.resolve(customerRes.data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const customerPatch = async (id: number, data: CustomerPatchRequest): Promise<Customer> => {
        try {
            const res = await ajax.patch(`customers/${id}/`, data)
            return Promise.resolve(res.data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const customerPut = async (id: number, data: CustomerPatchRequest): Promise<Customer> => {
        try {
            const res = await ajax.put(`customers/${id}/`, data)
            return Promise.resolve(res.data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const customerIdExpiration = async (): Promise<CustomerExpirationResponse> => {
        try {
            const res = await ajax.get('customer-id-expiration/')
            return Promise.resolve(res.data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const customerPapers = async (): Promise<CustomerPapers[]> => {
        try {
            const res = await ajax.get('customer-papers/')
            return Promise.resolve(res.data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const customerPapersPost = async (formData: any): Promise<unknown> => {
        try {
            const res = await ajax.post('customer-papers/', formData)
            return Promise.resolve(res.data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const sendEmail = async (data: any): Promise<unknown> => {
        try {
            const res = await ajax.post('support-email/', { message: data })
            return Promise.resolve(res.data)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    return {
        customer,
        customerPatch,
        customerPatchMe,
        customerPut,
        sendEmail,
        customerPapers,
        customerPapersPost,
        customerIdExpiration,
    }
}
