import React, { FC } from 'react'
import { TButtonProps } from './types'

export const ButtonCommon: FC<TButtonProps> = ({
    children,
    className,
    type = 'button',
    onClick,
    disabled,
}) => {
    return (
        <button
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            type={type}
            onClick={onClick}
            disabled={disabled}
            className={`inline-flex
                items-center justify-center w-full rounded-md border border-transparent
                 px-6 py-3 text-sm font-light text-white hover:shadow-md hover:shadow-borderShadow/10 duration-300 ${
                     className ?? 'bg-lightPurple hover:bg-darkPurple'
                 } ${disabled && 'opacity-60'}`}>
            {children}
        </button>
    )
}
