import React, { FC } from 'react'

const QuestionTwo: FC = () => {
    return (
        <div className='space-y-4'>
            <p>
                Služba VistaCredit.cz{' '}
                <b>
                    nemá certifikaci ČNB pro poskytování spotřebitelských úvěrů, a proto je ani
                    neposkytuje!
                </b>{' '}
                Společnost VistaCredit, s.r.o. nikdy neposkytovala spotřebitelské úvěry, ale pouze
                účelové podnikatelské úvěry pro drobné podnikatele (OSVČ){' '}
                <span className='text-lightBlue'>
                    podle § 2395 občanského zákona č. 89/2012 Sb..
                </span>
            </p>
            <p>
                <b>Žadatel je při Ověření identity současně povinen vyplnit Dotazník</b> a sdělit,
                výši svých měsíčních nákladů a příjmů z podnikání, účel poskytnutí úvěru z
                předloženého seznamů definovaných účelů, ovšem{' '}
                <b>účel poskytnutí není potřeba z jeho strany dokládat!</b> K získání úvěru{' '}
                <b>není potřeba dokládat daňové přiznání ani jiné dokumenty z vedení podnikání</b>.
            </p>
            <p>
                Služba VistaCredit.cz je určena především začínajícím podnikatelským subjektům se
                startem nového podnikatelského záměru, případně k rozvoji aktuálního podnikání,
                splacení závazků z podnikání a jiné náklady související s podnikatelskou činností.
            </p>
            <p>
                Postup jak odeslat první žádost je prezentována na našem{' '}
                <a
                    rel={'noreferrer'}
                    className={'hover:text-lightBlue hover:underline'}
                    href='https://www.youtube.com/channel/UCFpYODdPoVAAHrJ7zGWdFyw'
                    target={'_blank'}>
                    <b>YouTUBE</b>
                </a>{' '}
                kanálu.
            </p>

            <p>
                Můžete nám také poslat dotaz přes <b>Kontaktní formulář</b> výběrem volby :{' '}
                <b>Vlastní dotaz, námět, prosba, pochvala, kritika</b>.
            </p>
            {/*<img src={Question1_2} className='max-w-xl my-3' alt='question1 image' />*/}
        </div>
    )
}

export default QuestionTwo
