import { FC } from 'react'
import { formatCurrency } from 'utils'
import { TLoanDetail } from 'api/services/loan/types'

interface IMainStatsProps {
    loan: TLoanDetail
}

const MainStats: FC<IMainStatsProps> = ({ loan }) => {
    return (
        <div className='mx-auto max-w-4xl sm:my-6 font-Quicksand'>
            <dl className='rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3'>
                <div className='flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r'>
                    <dt className='order-2 mt-2 text-base sm:text-lg font-medium leading-6 text-gray-500'>
                        Jistina
                    </dt>
                    <dd className='order-1 text-2xl sm:text-5xl font-normal tracking-tight text-lightPurple'>
                        {formatCurrency(loan.amount, 'CZK')}
                    </dd>
                </div>
                <div className='flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r'>
                    <dt className='order-2 mt-2 text-base sm:text-lg font-medium leading-6 text-gray-500'>
                        Doba poskytnutí
                    </dt>
                    <dd className='order-1 text-2xl sm:text-5xl font-normal tracking-tight text-gray-400'>
                        {loan.period} dnů
                    </dd>
                </div>
                <div className='flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l'>
                    <dt className='order-2 mt-2 text-base sm:text-lg font-medium leading-6 text-gray-500'>
                        Celková získána odměna
                    </dt>
                    <dd className='order-1 text-2xl sm:text-5xl font-normal tracking-tight text-green-500'>
                        {loan.points} bodů
                    </dd>
                </div>
            </dl>
        </div>
    )
}

export default MainStats
