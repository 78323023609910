import {
    CreditCardIcon,
    CurrencyDollarIcon,
    EnvelopeIcon,
    QrCodeIcon,
} from '@heroicons/react/24/outline'

export const renderIcon = (icon: string) => {
    switch (icon) {
        case 'CreditCardIcon':
            return <CreditCardIcon className='h-6 w-6 sm:h-8 sm:w-8' />
        case 'QrCodeIcon':
            return <QrCodeIcon className='h-6 w-6 sm:h-8 sm:w-8' />
        case 'EnvelopeIcon':
            return <EnvelopeIcon className='h-6 w-6 sm:h-8 sm:w-8' />
        case 'CurrencyDolarIcon':
            return <CurrencyDollarIcon className='h-6 w-6 sm:h-8 sm:w-8' />
        default:
            return null
    }
}
