import { FC, Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { BadgeBox } from '@vistacredit/lib-ui'
import {
    ArrowLeftOnRectangleIcon,
    ChevronDownIcon,
    CogIcon,
    UserIcon,
} from '@heroicons/react/24/solid'
import { classNames } from 'utils'
import { Link } from 'react-router-dom'
import { Customer } from 'api/customers/types'
import { PointsListResponse } from 'api/points/types'
import { routerCatalog } from 'routes/hooks/useSecureRouting'
import UserAvatar from 'layout/components/navigation/components/UserAvatar'

interface IUserMenuProps {
    customer: Customer
    points: PointsListResponse
}

export const UserMenu: FC<IUserMenuProps> = ({ customer, points }) => {
    return (
        <div className='flex items-center space-x-8'>
            <Menu as='div' className='relative inline-block text-left'>
                <Menu.Button className='flex rounded-full bg-white text-sm'>
                    <span className='sr-only'>Open user menu</span>
                    <BadgeBox
                        className={
                            'relative pl-1 py-1.5 hover:shadow-sm hover:shadow-borderShadow/20 hover:duration-300'
                        }>
                        <UserAvatar customer={customer} points={points} />
                        <ChevronDownIcon
                            className='-mr-1 mt-3 ml-2 h-5 w-5 text-lightGray'
                            aria-hidden='true'
                        />
                    </BadgeBox>
                </Menu.Button>

                <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'>
                    <Menu.Items className='absolute right-0 z-10 mt-2 w-60 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-20'>
                        <div className='py-1'>
                            <Menu.Item>
                                {({ active }) => (
                                    <Link
                                        to={routerCatalog.profile}
                                        className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'group flex items-center px-4 py-2 text-sm',
                                        )}>
                                        <UserIcon
                                            className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                                            aria-hidden='true'
                                        />
                                        Můj účet
                                    </Link>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <Link
                                        to={routerCatalog.settings}
                                        className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'group flex items-center px-4 py-2 text-sm',
                                        )}>
                                        <CogIcon
                                            className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                                            aria-hidden='true'
                                        />
                                        Nastavení
                                    </Link>
                                )}
                            </Menu.Item>
                        </div>
                        <div className='py-1'>
                            <Menu.Item>
                                {({ active }) => (
                                    <Link
                                        to={routerCatalog.logout}
                                        className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'group flex items-center px-4 py-2 text-sm',
                                        )}>
                                        <ArrowLeftOnRectangleIcon
                                            className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                                            aria-hidden='true'
                                        />
                                        Odhlásit
                                    </Link>
                                )}
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    )
}
