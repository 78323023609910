import { CalendarIcon } from '@heroicons/react/20/solid'
import { FC } from 'react'
import { Loan } from 'api/loans/types'

import { BanknotesIcon } from '@heroicons/react/24/outline'
import { formatCurrency } from 'utils'
import { getDate } from 'utils/date'

interface ITableMobileProps {
    data: Loan[]
    openDetail: (id: number) => void
}

const TableMobile: FC<ITableMobileProps> = ({ data, openDetail }) => {
    return (
        <div className='overflow-hidden bg-white shadow sm:rounded-md'>
            <ul role='list' className='divide-y divide-gray-200'>
                {data.map((item) => (
                    <li key={item.id} onClick={() => openDetail(item.id)}>
                        <div className='block hover:bg-gray-50'>
                            <div className='px-4 py-4 sm:px-6'>
                                <div className='flex items-center justify-between'>
                                    <p className='truncate text-sm font-medium text-indigo-600 cursor-pointer'>
                                        ID: {item.contract_number} - zobrazit detail
                                    </p>
                                    <div className='ml-2 flex flex-shrink-0'>
                                        <p
                                            className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${
                                                item.type === 0
                                                    ? 'bg-lightPurple text-white'
                                                    : 'bg-lightBlue text-white'
                                            }`}>
                                            {item.type_text}
                                        </p>
                                    </div>
                                </div>
                                <div className='mt-2 sm:flex sm:justify-between'>
                                    <div className='sm:flex'>
                                        <p className='flex items-center text-sm text-gray-500'>
                                            <BanknotesIcon
                                                className='mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400'
                                                aria-hidden='true'
                                            />
                                            {formatCurrency(item.amount, 'CZK')}
                                        </p>
                                        <p className='mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6'>
                                            <CalendarIcon
                                                className='mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400'
                                                aria-hidden='true'
                                            />
                                            <span>
                                                Datum žádosti{' '}
                                                <time dateTime={item.due}>
                                                    {getDate(item.created)}
                                                </time>
                                            </span>
                                        </p>
                                    </div>
                                    <div className='mt-2 flex items-center text-sm text-gray-500 sm:mt-0'>
                                        <CalendarIcon
                                            className='mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400'
                                            aria-hidden='true'
                                        />
                                        <p>
                                            Splatnost úvěru{' '}
                                            <time dateTime={item.due}>{getDate(item.due)}</time>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default TableMobile
