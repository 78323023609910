import { serviceApi } from 'api/serviceApi'
import { IContractsList } from 'api/services/customer/types'
import { EContractsType } from 'api/contracts/types'

const contractsApi = serviceApi.injectEndpoints({
    endpoints: (build) => ({
        getContractsList: build.query<IContractsList, EContractsType>({
            query: (type) => `contracts/?contract_type=${type}`,
        }),
        getContractsToSign: build.query<IContractsList, void>({
            query: () => `contracts/?to_sign=1`,
            providesTags: ['ContractsToSign'],
        }),
        signContract: build.mutation<any, { contract_type: number; code: string }>({
            query: (type) => {
                return {
                    url: `sign-contract/`,
                    method: 'POST',
                    body: type,
                }
            },
        }),
        sendSMScode: build.mutation<unknown, number>({
            query: (payload) => {
                return {
                    url: `send-sign-sms/`,
                    method: 'POST',
                    body: payload,
                }
            },
        }),
        signDistanceAmendment: build.mutation<unknown, void>({
            query: () => {
                return {
                    url: `sign-distance-amendment/`,
                    method: 'POST',
                }
            },
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetContractsListQuery,
    useSignContractMutation,
    useGetContractsToSignQuery,
    useLazyGetContractsToSignQuery,
    useSignDistanceAmendmentMutation,
    useSendSMScodeMutation,
} = contractsApi
