import React, { FC } from 'react'
import Question11_1 from 'assets/images/question11_1.png'
import Question11_2 from 'assets/images/question11_2.png'
import Question11_3 from 'assets/images/question11_3.png'

const QuestionEleven: FC = () => {
    return (
        <div className='space-y-4'>
            <p>
                Na evidenci a ukládání dokumentů si zakládáme.{' '}
                <b>Všechno co podepíšete si evidujeme a uložíme, ale také zpřístupníme i Vám</b>.
            </p>
            <p>
                V menu Osobní účet | Okno Osobní dokumenty <b>najdete Rámcovou smlouvu</b>, všechny
                souhlasy, které jste nám udělil(a) a další systémové dokumenty.
            </p>

            <img src={Question11_1} className='w-fit lg:max-w-xl my-3' alt='question3 image' />

            <p>
                * stisknutím tlačítka ZOBRAZIT VŠECHNY DOKUMENTY, pak zobrazíte všechny ostatní
                uložené dokumenty
            </p>

            <p>
                Jednotlivé <b>Smlouvy o úvěru</b>, jsou vždy k náhledu a stažení u jednotlivého
                úvěru. Pokud kliknete na tlačítko Detail úvěru přímo v karte Aktivního úvěru,
                zobrazíte Detail daného úvěru a tím také:
            </p>
            <ul className=' space-y-3 pl-10 list-disc '>
                <li>všeobecné informace o aktuálním úvěru</li>
                <li>informace ke splacení úvěru</li>
                <li>veškeré úhrady k danému úvěru</li>
                <li>smlouvy k úvěru</li>
            </ul>

            <img src={Question11_2} className='w-fit lg:max-w-xl my-3' alt='question3 image' />

            <p>
                Všechny dokumenty jsou zašifrovány na našich serverech v rámci požadavků EU (GDPR),
                takže se nemusíte bát, že nám nějaká data uniknou.
            </p>

            <p>
                <b>
                    Součástí našich služeb je také vystavení Faktury za Vaše úhrady, kterou si jako
                    podnikatel můžete uplatnit do nákladů
                </b>
                . I tyto dokumenty uchováváme a jsou k dispozici v detailu jednotlivých plateb úvěru
                v účtu klienta.
            </p>

            <img src={Question11_3} className='w-fit lg:max-w-xl my-3' alt='question3 image' />

            <p>
                Můžete nám také poslat dotaz přes <b>Kontaktní formulář</b> výběrem volby :{' '}
                <b>Vlastní dotaz, námět, prosba, pochvala, kritika</b>.
            </p>

            {/*<img src={Question1_2} className='max-w-xl my-3' alt='question3 image' />*/}
        </div>
    )
}

export default QuestionEleven
